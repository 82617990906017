import { zodResolver } from "@hookform/resolvers/zod";
import { Suspense, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAllAccounts } from "services/account/useAllAccounts";
import { WorkspaceEngineStatus } from "services/engines/engine.types";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { AuthType } from "services/fileObjects/getFilesByPath";
import { useFiles } from "services/fileObjects/useFiles";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { ButtonTemplate } from "components/Button";
import CreateRoleGuide from "components/IngestionWizard/Steps/CreateRoleGuide/CreateRoleGuide";
import { Modal } from "components/Modal/Modal";
import {
  InlineNotification,
  NotificationType,
} from "components/Notification/InlineNotification";
import { TextInput } from "components/TextInput/TextInput";
import Toggle from "components/Toggle";
import { Step } from "components/Wizard/Step/Step";

import { AuthSelector } from "../AuthSelector/AuthSelector";
import { IngestionStep, SelectAWSStep, WizardDataType } from "../types";
import { Fields, validationSchema } from "./schema";
import { useEnsureEngineRunning } from "./useEnsureEngineRunning";

import styles from "./styles.module.scss";

const DOCS_URL =
  "https://docs.firebolt.io/godocs/Guides/loading-data/creating-access-keys-aws.html";

const getPlaygroundUrl = (region: string) => {
  return `s3://firebolt-sample-datasets-public-${region}/gaming/playground/parquet/playstats/`;
};

const Subtitle = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t("wizard.select_aws.subtitle")}
      <a
        className={styles.link}
        href={DOCS_URL}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={-1}
      >
        {t("wizard.select_aws.link")}
      </a>
    </div>
  );
};

type Props = {
  initialData: SelectAWSStep;
  onClose: () => void;
  onSubmit: (data: Fields) => void;
  onPrevStep: () => void;
  activeStepIndex: number;
  wizardData: WizardDataType;
};

const EnginePreload = (props: { wizardData: WizardDataType }) => {
  const { wizardData } = props;
  useEnsureEngineRunning(wizardData);
  return null;
};

export const SelectAWS = (props: Props) => {
  const {
    onClose,
    onSubmit,
    onPrevStep,
    initialData,
    activeStepIndex,
    wizardData,
  } = props;
  const { t } = useTranslation();
  const { getAccount } = useCurrentAccount();
  const accounts = useAllAccounts();

  const notificationBlockRef = useRef<HTMLDivElement | null>(null);

  const [createRoleGuideOpen, setCreateRoleGuideOpen] = useState(false);
  const [testConnectionLoading, setTestConnectionLoading] = useState(false);
  const [testConnectionError, setTestConnectionError] = useState<string | null>(
    null
  );

  const form = useForm<Fields>({
    resolver: zodResolver(validationSchema),
    defaultValues: initialData,
  });

  const { data: engines = [] } = useWorkspaceEngines({
    includeSystemEngine: true,
  });

  const currentAccount = getAccount();
  const account = accounts.find(
    a => a.accountName === currentAccount.accountName
  );

  const accountTrustPolicyRole = account?.trustPolicyRole;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = form;

  const authType = watch("authType");
  // reset testConnectionError when authType changes
  useEffect(() => {
    setTestConnectionError(null);
  }, [authType]);

  const [, bucketProtocol, bucketName] =
    watch("storageUrl").match(/(.+):\/\/(.+?)(\/.+)?$/) || [];
  const engineData = wizardData[IngestionStep.selectEngine];
  const engine = engines.find(
    e => e.engineName === engineData?.engine?.engineName
  );

  const { loadFiles } = useFiles({
    bucketName,
    bucketProtocol,
    auth: {
      authType: watch("authType"),
      key: watch("key"),
      secret: watch("secret"),
      iamRole: watch("iamRole"),
    },
    engine,
  });

  const onExampleBucketToggle = (checked: boolean) => {
    if (checked) {
      const currentAccount = getAccount();
      const url = getPlaygroundUrl(currentAccount.region);
      setValue("playgroundBucket", true);
      setValue("authType", AuthType.NONE);
      setValue("storageUrl", url);

      // trigger validation
      form.trigger();
    } else {
      setValue("playgroundBucket", false);
      setValue("storageUrl", "");
      setValue("authType", AuthType.SECRET);
    }
  };

  const onFormSubmit = async (data: Fields) => {
    try {
      setTestConnectionLoading(true);
      await loadFiles("");

      setTestConnectionLoading(false);
      onSubmit(data);
    } catch (e: any) {
      setTestConnectionLoading(false);
      setTestConnectionError(e.message);

      setTimeout(() => {
        notificationBlockRef.current?.scrollIntoView({
          block: "start",
        });
      }, 0);
    }
  };

  const playgroundBucket = watch("playgroundBucket");
  const body = (
    <div className={styles.rows}>
      <div className={styles.row}>
        <span className={styles.sample}>{t("wizard.select_aws.sample")}</span>
        <Toggle
          size="sm"
          dataTestId="sampe-bucket"
          checked={playgroundBucket}
          disabled={testConnectionLoading}
          onChange={onExampleBucketToggle}
          classes={{ wrapper: styles.toggleWrapper }}
        />
      </div>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          placeholder={t("wizard.select_aws.storage_url_placeholder")}
          label={t("wizard.select_aws.storage_url")}
          testId="storage-url-field"
          {...register("storageUrl")}
          error={!!errors?.storageUrl}
          helperText={errors?.storageUrl?.message}
          disabled={playgroundBucket || testConnectionLoading}
          required
        />
      </div>
      {!playgroundBucket && (
        <AuthSelector
          disabled={testConnectionLoading}
          form={form}
          playgroundBucket={playgroundBucket}
        />
      )}
      {testConnectionError && !testConnectionLoading && (
        <div
          className={styles.notificationBlock}
          ref={notificationBlockRef}
        >
          <InlineNotification
            type={NotificationType.Error}
            title={t("wizard.select_aws.error")}
            body={testConnectionError}
            primaryAction={
              watch("authType") === AuthType.IAM
                ? {
                    value: t(
                      "wizard.select_aws.iam_setup_guidance_box.error_button"
                    ),
                    type: ButtonTemplate.Primary,
                    buttonClassName: styles.errorButton,
                    onAction: () => {
                      setCreateRoleGuideOpen(true);
                    },
                  }
                : undefined
            }
          />
        </div>
      )}

      {watch("authType") === AuthType.IAM && !testConnectionError && (
        <div
          className={styles.notificationBlock}
          ref={notificationBlockRef}
        >
          <InlineNotification
            type={NotificationType.Info}
            title={t("wizard.select_aws.iam_setup_guidance_box.title")}
            body={t("wizard.select_aws.iam_setup_guidance_box.description")}
            primaryAction={{
              value: t("wizard.select_aws.iam_setup_guidance_box.button"),
              type: ButtonTemplate.Secondary,
              onAction: () => {
                setCreateRoleGuideOpen(true);
              },
            }}
          />
        </div>
      )}

      <Suspense fallback={null}>
        <EnginePreload wizardData={wizardData} />
      </Suspense>
      {createRoleGuideOpen && (
        <Modal
          title={t("wizard.select_aws.iam_setup_guidance.modal_title")}
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();

            setCreateRoleGuideOpen(false);
          }}
          primaryButton={t(
            "wizard.select_aws.iam_setup_guidance.modal_done_button"
          )}
          isLoading={false}
          hideCancel={true}
          classes={{
            footer: styles.modalFooter,
          }}
          onClose={() => {
            setCreateRoleGuideOpen(false);
          }}
        >
          <CreateRoleGuide trustPolicy={accountTrustPolicyRole || ""} />
        </Modal>
      )}
    </div>
  );

  const engineStarting = engine?.status === WorkspaceEngineStatus.STARTING;

  const getSubmitButtonState = () => {
    if (testConnectionLoading) {
      if (engineStarting) {
        return {
          isLoading: false,
          disabled: true,
          mainActionTitle: t("engine.status.starting"),
        };
      } 
        return {
          disabled: true,
          mainActionTitle: t("wizard.select_aws.connecting"),
        };
      
    }

    return {
      isLoading: false,
      disabled: false,
      mainActionTitle: undefined,
    };
  };

  const { isLoading, disabled, mainActionTitle } = getSubmitButtonState();

  return (
    <Step
      title={t("wizard.select_aws.title")}
      subtitle={<Subtitle />}
      body={body}
      onClose={onClose}
      isLoading={isLoading}
      disabledSubmit={disabled}
      mainActionTitle={mainActionTitle}
      onSubmit={handleSubmit(onFormSubmit)}
      activeStepIndex={activeStepIndex}
      onPrevStep={onPrevStep}
    />
  );
};
