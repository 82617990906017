import { useQuery } from "@tanstack/react-query";
import { QUERY_OUTPUT } from "types/outputFormat";
import z from "zod";

import { useEnableReadCsvNewParams } from "featureFlags/hooks/useEnableReadCsvNewParams";
import { authService } from "services/auth";
import { WorkspaceEngine } from "services/engines/engine.types";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { AuthType } from "services/fileObjects/getFilesByPath";
import { ReactQueryKeysAccount } from "services/queryKeys";

import { FIREBOLT_UI_USER_ENGINE_QUERY_LABEL } from "pages/DevelopWorkspace/services/constants";
import executeQueryStatement from "pages/DevelopWorkspace/services/helpers/executeQueryStatement";
import { DEFAULT_ENGINE_MONITORING_TIME_WINDOW } from "pages/DevelopWorkspace/workspace.constants";
import {
  DocumentOutputTab,
  QueryStatement,
  QueryStatementStatus,
  ScriptType,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import {
  DataFile,
  IngestionStep,
  SelectAWSStep,
  SupportedTypes,
  WizardDataType,
} from "../types";
import { FormatingFields, csvSchema } from "./schema";

const getFilePreview = async ({
  query,
  engine,
}: {
  query: string;
  engine: WorkspaceEngine | undefined;
}) => {
  if (!engine) {
    return {
      data: [],
      meta: [],
      error: "wizard.format_data.error.no_engine",
    };
  }

  const abortController = new AbortController();

  const fetchOptions = {
    abortController,
    headers: {},
  };

  const queryStatement: QueryStatement = {
    content: query,
    status: QueryStatementStatus.pending,
    id: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
    responseStatusCode: null,
    error: null,
    statistics: null,
    sourceDocLineNumber: 0,
  };

  const document: WorkspaceDocument = {
    id: "preview",
    script: {
      type: ScriptType.local,
      id: "preview",
    },
    selection: [0, 0],
    createdAt: Date.now(),
    execution: null,
    layout: {
      editorHeightPx: 0,
      activeOutputTab: DocumentOutputTab.Empty,
    },
    context: {
      database: { name: "" },
      engine: { name: engine.engineName },
      settings: {
        query_label: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
        output_format: QUERY_OUTPUT.JSON,
      },
    },
    engineMonitoring: {
      timeWindow: DEFAULT_ENGINE_MONITORING_TIME_WINDOW,
      utilChart: {
        highlightedKeys: [],
      },
      queriesChart: {
        highlightedKeys: [],
      },
    },
  };

  try {
    const response = await executeQueryStatement(
      queryStatement,
      document,
      engine,
      fetchOptions,
      authService
    );
    const { responseBody } = response;
    return responseBody;
  } catch (error: any) {
    return {
      meta: [],
      data: [],
      error: error.message as string,
    };
  }
};

const generateCsvQuery = ({
  format,
  file,
  awsData,
  enableReadCsvNewParams,
}: {
  format: FormatingFields;
  file: DataFile | undefined;
  awsData: SelectAWSStep;
  enableReadCsvNewParams: boolean | undefined;
}) => {
  const { formatSettings } = format;
  const {
    autoDetectHeader,
    escapeCharacter,
    quoteCharacter,
    fieldDelimiter,
    newlineCharacter,
    nullCharacter,
    skipBlankLines,
  } = formatSettings as z.infer<typeof csvSchema>["formatSettings"];

  const credentials = (() => {
    if (awsData.authType === AuthType.NONE) {
      return "";
    }

    return awsData.authType === AuthType.SECRET
      ? `access_key_id => '${awsData.key}', secret_access_key => '${awsData.secret}', `
      : `role_arn => '${awsData.iamRole}', `;
  })();

  const compression = file?.compression
    ? `compression => '${file.compression}',`
    : "";

  const params = enableReadCsvNewParams
    ? `${credentials}
${compression}
header => ${autoDetectHeader},
delimiter => '${fieldDelimiter}',
quote => '${quoteCharacter}',
null_string => '${nullCharacter}',
escape => '${escapeCharacter}',
skip_blank_lines => ${skipBlankLines},
empty_field_as_null => true
`
    : `${credentials}
${compression}
header => ${autoDetectHeader},
newline_character => e'${newlineCharacter}',
field_delimiter => '${fieldDelimiter}',
quote_character => e'${
        quoteCharacter === "DOUBLE_QUOTE"
          ? '"'
          : "\\'" /* eslint-disable-line */
      }',
null_character => '${nullCharacter}',
escape_character => '${escapeCharacter}',
skip_blank_lines => ${skipBlankLines},
empty_field_as_null => true,
header_can_define_index => true,
allow_single_quote => true`;

  const query = `
select * from read_csv(
url => '${file?.url}',${params}
) limit 100;
`;
  return query;
};

const generateParquetQuery = ({
  file,
  awsData,
}: {
  awsData: SelectAWSStep;
  file: DataFile | undefined;
}) => {
  const credentials = (() => {
    if (awsData.authType === AuthType.NONE) {
      return "";
    }

    return awsData.authType === AuthType.SECRET
      ? `, access_key_id => '${awsData.key}', secret_access_key => '${awsData.secret}'`
      : `, role_arn => '${awsData.iamRole}'`;
  })();

  const query = `
  SELECT * FROM read_parquet(
    url => '${file?.url}'
    ${credentials}
  )
  LIMIT 100;
`;
  return query;
};

const generateQueryByType = {
  csv: generateCsvQuery,
  tsv: generateCsvQuery,
  parquet: generateParquetQuery,
};

export const usePreviewData = ({
  wizardData,
  format,
}: {
  wizardData: WizardDataType;
  format: FormatingFields;
}) => {
  const { data: engines = [] } = useWorkspaceEngines({
    includeSystemEngine: true,
  });
  const { extension } = format;
  const enableReadCsvNewParams = useEnableReadCsvNewParams();

  const filesData = wizardData[IngestionStep.selectData];
  const awsData = wizardData[IngestionStep.selectAWS];

  const { files } = filesData ?? {};
  const file = files?.[0];

  const engineData = wizardData[IngestionStep.selectEngine] as {
    engine: {
      engineName: string;
    };
  };

  const generateQuery = generateQueryByType[extension as SupportedTypes];

  const query = generateQuery({
    format,
    file,
    awsData,
    enableReadCsvNewParams,
  });

  const engine = engines.find(
    engine => engine.engineName === engineData.engine.engineName
  );

  const { data, error, isLoading } = useQuery({
    queryKey: [ReactQueryKeysAccount.filePreview, query],
    queryFn: () => getFilePreview({ query, engine }),
    retry: false,
    gcTime: 0,
  });
  return {
    data: data || {
      data: [],
      meta: [],
    },
    error,
    isLoading,
  };
};
