import * as Sentry from "@sentry/react";

import {
  QueryStatement,
  QueryStatementStatus,
} from "pages/DevelopWorkspace/workspace.types";

const validStatusTransitions: Record<
  QueryStatementStatus,
  QueryStatementStatus[]
> = {
  [QueryStatementStatus.success]: [],
  [QueryStatementStatus.error]: [],
  [QueryStatementStatus.cancelled]: [],
  [QueryStatementStatus.pending]: [
    QueryStatementStatus.running,
    QueryStatementStatus.suspended,
    QueryStatementStatus.error,
    QueryStatementStatus.cancelled,
    QueryStatementStatus.pending,
  ],
  [QueryStatementStatus.running]: [
    QueryStatementStatus.suspended,
    QueryStatementStatus.error,
    QueryStatementStatus.cancelled,
    QueryStatementStatus.success,
    QueryStatementStatus.unknown,
    QueryStatementStatus.running,
  ],
  [QueryStatementStatus.suspended]: [
    QueryStatementStatus.running,
    QueryStatementStatus.error,
    QueryStatementStatus.cancelled,
    QueryStatementStatus.success,
    QueryStatementStatus.unknown,
    QueryStatementStatus.suspended,
  ],
  [QueryStatementStatus.unknown]: [
    QueryStatementStatus.running,
    QueryStatementStatus.suspended,
    QueryStatementStatus.error,
    QueryStatementStatus.cancelled,
    QueryStatementStatus.success,
    QueryStatementStatus.unknown,
    QueryStatementStatus.restoring,
  ],
  [QueryStatementStatus.restoring]: [
    QueryStatementStatus.running,
    QueryStatementStatus.suspended,
    QueryStatementStatus.error,
    QueryStatementStatus.cancelled,
    QueryStatementStatus.success,
    QueryStatementStatus.unknown,
    QueryStatementStatus.restoring,
  ],
};

const isQueryStatementChangesValid = (
  prevQueryStatement: QueryStatement,
  nextQueryStatement: Partial<QueryStatement>
) => {
  const prevStatus = prevQueryStatement.status;
  const nextStatus = nextQueryStatement.status;

  if (!nextStatus) {
    // no status change
    return true;
  }

  if (!validStatusTransitions[prevStatus].includes(nextStatus)) {
    Sentry.captureException(
      new Error(`Invalid status transition from ${prevStatus} to ${nextStatus}`)
    );
    return false;
  }

  // progress update
  if (nextQueryStatement.statistics && !nextQueryStatement.status) {
    // updates have statistics, but no status

    if (prevStatus === QueryStatementStatus.running) {
      // allow update statistics only for running statements
      return true;
    }

    return false;
  }

  return true;
};

export { isQueryStatementChangesValid };
