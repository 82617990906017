import classNames from "classnames";

import styles from "./TextChip.module.scss";

export enum TextChipVariants {
  primary = "primary",
  secondary = "secondary",
}

type TextChipProps = {
  text: string;
  icon?: React.ReactNode;
  variant?: TextChipVariants;
  testId: string;
};

export const TextChip = (props: TextChipProps) => {
  const { text, icon, variant = TextChipVariants.primary, testId } = props;
  return (
    <div
      className={classNames(styles.textChip, styles[variant])}
      data-testid={testId}
    >
      <div className={styles.text}>{text}</div>
      {!!icon && icon}
    </div>
  );
};
