import classNames from "classnames";
import React, { useContext, useState } from "react";

import { ColumnFiltersCell } from "./ColumnFiltersCell";
import { HeaderCell } from "./HeaderCell";
import { TableContext, WidthContext } from "./context";
import { ColumnType } from "./types";

import styles from "./styles.module.scss";

const COLUMN_MIN_WIDTH = 20;

type ResizingColumn = {
  id: string;
  dragOffset: number;
};

type ColumnWidths = Record<string, number>;

type HeaderProps = {
  columns: ColumnType[];
  columnWidths: ColumnWidths;
  finishResize: (widths: ColumnWidths) => void;
  order?: "asc" | "desc";
  orderBy?: string | null;
  disableResize?: boolean;
  handleSort: (fieldId: string) => void;
  toggleOrder: () => void;
  sortable?: boolean;
  disableColumnFilters?: boolean;
};

export const Header: React.FC<HeaderProps> = props => {
  const {
    columns,
    columnWidths,
    finishResize,
    order,
    orderBy,
    disableResize,
    toggleOrder,
    handleSort,
    sortable,
    disableColumnFilters,
  } = props;
  const { leadingWidth } = useContext(WidthContext);
  const { floatingColumn, visibleColumns } = useContext(TableContext);

  const [resizingColumn, setResizingColumn] = useState<ResizingColumn | null>(
    null
  );
  const lastColumnWidth = `calc(100% - ${leadingWidth}px)`;

  const onResizeFinish = () => {
    if (resizingColumn) {
      const { id, dragOffset } = resizingColumn;
      const currentWidth = columnWidths[id];
      const width = Math.max(currentWidth + dragOffset, COLUMN_MIN_WIDTH);
      const newWidths = { ...columnWidths };
      newWidths[id] = width;
      finishResize(newWidths);
      setResizingColumn(null);
    }
  };

  const onResizeStart = (column: ColumnType) => () => {
    setResizingColumn({ ...column, dragOffset: 0 });
  };

  const onResize = (dragOffset: number) => {
    setResizingColumn(column => {
      if (column) {
        return { ...column, dragOffset };
      }
      return column;
    });
  };

  return (
    <div
      className={classNames(styles.header, {
        [styles.isDragging]: resizingColumn,
      })}
    >
      <div className={styles.header__child}>
        {columns.map(column => {
          const { id, fillAvailableWidth } = column;
          const width = columnWidths[id];
          const isDragging = id === resizingColumn?.id;
          let dragOffset = 0;

          if (isDragging) {
            dragOffset = resizingColumn?.dragOffset ?? 0;
          }

          const style = {
            minWidth: width,
            width: fillAvailableWidth ? lastColumnWidth : width,
          };

          if (id === floatingColumn && !disableColumnFilters) {
            return (
              <ColumnFiltersCell
                style={style}
                key="filters"
              />
            );
          }

          if (!visibleColumns.includes(id) && id !== floatingColumn) {
            return null;
          }

          return (
            <HeaderCell
              key={`${column.name}-${column.id}`}
              column={column}
              style={style}
              isDragging={isDragging}
              fillAvailableWidth={fillAvailableWidth}
              onResizeFinish={onResizeFinish}
              onResizeStart={onResizeStart(column)}
              onResize={onResize}
              dragOffset={dragOffset}
              order={order}
              orderBy={orderBy}
              disableResize={disableResize || column.disableResize}
              toggleOrder={toggleOrder}
              handleSort={handleSort}
              sortable={sortable}
            />
          );
        })}
      </div>
    </div>
  );
};
