import classNames from "classnames";
import _orderBy from "lodash/orderBy";
import React, { useMemo, useRef, useState } from "react";

import { Header } from "./Header";
import { TableContext, WidthContext } from "./context";
import { ColumnType } from "./types";
import { useColumnFilters } from "./useColumnFilters";
import { useSort } from "./useSort";
import { getIdentity } from "./utils";

import styles from "./styles.module.scss";

type Row = unknown;
type ColumnWidths = Record<string, number>;

type TableProps = {
  rowRenderer: ({
    index,
    row,
  }: {
    index: number;
    row: Row;
  }) => React.ReactNode | null;
  rows: Row[];
  columns: ColumnType[];
  className?: string;
  disableResize?: boolean;
  minWidth?: number;
  loader?: React.ReactNode | null;
  emptyState?: React.ReactNode | null;
  dataTestId?: string;
  floatingColumn?: string;
  sortable?: boolean;
};

export const Table: React.FC<TableProps> = React.memo(props => {
  const {
    rowRenderer,
    rows,
    columns,
    className,
    disableResize,
    minWidth,
    loader,
    emptyState,
    dataTestId,
    floatingColumn,
    sortable,
  } = props;

  const wrapper = useRef<HTMLDivElement>(null);
  const { order, orderBy, toggleOrder, handleSort } = useSort();
  const [columnWidths, setColumnWidths] = useState<ColumnWidths>(() => {
    return columns.reduce<Record<string, number>>((acc, { id, width }) => {
      acc[id] = width;
      return acc;
    }, {});
  });

  const {
    filterColumns,
    handleColumnClick,
    toggleShowAllColumns,
    visibleColumns,
  } = useColumnFilters(columns);

  const fullWidthColumnIndex = columns.findIndex(
    column => column.fillAvailableWidth
  );

  const columnIndex =
    fullWidthColumnIndex === -1 ? columns.length - 1 : fullWidthColumnIndex;

  const leadingWidth = useMemo(
    () =>
      columns.reduce((acc, { id }, index) => {
        if (columnIndex === index) {
          return acc;
        }
        const width = columnWidths[id];
        return acc + width;
      }, 0),

    [columns, columnWidths, columnIndex]
  );

  const finishResize = (newWidths: ColumnWidths) => {
    setColumnWidths(newWidths);
  };

  const rowElements = useMemo(() => {
    const identity = getIdentity(columns, orderBy);
    const sorted = _orderBy(rows, [identity], [order]);
    return sorted.map((row, index) => rowRenderer({ row, index }));
  }, [rows, rowRenderer, order, orderBy, columns]);

  const tableContextValue = useMemo(() => {
    return {
      floatingColumn,
      visibleColumns,
      filterColumns,
      handleColumnClick,
      toggleShowAllColumns,
    };
  }, [
    floatingColumn,
    visibleColumns,
    filterColumns,
    handleColumnClick,
    toggleShowAllColumns,
  ]);

  const widthContextValue = useMemo(() => {
    return {
      leadingWidth,
      columnWidths,
    };
  }, [leadingWidth, columnWidths]);

  return (
    <TableContext.Provider value={tableContextValue}>
      <WidthContext.Provider value={widthContextValue}>
        <div
          className={classNames(styles.wrapper, className)}
          data-testid={dataTestId}
          ref={wrapper}
        >
          <div
            className={styles.table}
            style={{ minWidth: minWidth ? `${minWidth}px` : undefined }}
          >
            <Header
              columns={columns}
              columnWidths={columnWidths}
              finishResize={finishResize}
              disableResize={disableResize}
              order={order}
              orderBy={orderBy}
              toggleOrder={toggleOrder}
              handleSort={handleSort}
              sortable={sortable}
            />
            {rowElements}
            {loader}
          </div>
          {emptyState}
        </div>
      </WidthContext.Provider>
    </TableContext.Provider>
  );
});
