import cn from "classnames";
import React from "react";

import styles from "./RadioButton.module.scss";

interface Props {
  selected?: boolean;
  disabled?: boolean;
  hovered?: boolean;
  testId?: string;
  onClick?: () => any;
}

const RadioButton = (props: Props) => {
  const {
    selected = false,
    disabled = false,
    hovered,
    testId,
    onClick,
  } = props;
  return (
    <div
      className={cn(styles.root, {
        [styles.disabled]: disabled,
        [styles.selected]: selected,
        [styles.hovered]: hovered,
      })}
      onClick={onClick}
    >
      <div
        className={styles.outer}
        data-testid={testId}
      >
        <div className={styles.inner} />
      </div>
    </div>
  );
};

export default RadioButton;
