import cn from "classnames";

import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
} from "services/engines/engine.types";

import EngineIcon from "assets/icons/EngineSelectorIcon.svg?react";

import styles from "../ContextSelector.module.scss";

export const EngineIconWrapper = ({
  engine,
}: {
  engine?: WorkspaceEngine | undefined;
}) => {
  return (
    <EngineIcon
      className={cn(styles.engineIconWrapper, {
        [styles.engineRunning]:
          engine?.status === WorkspaceEngineStatus.RUNNING,
        [styles.engineStarting]:
          engine?.status === WorkspaceEngineStatus.STARTING,
      })}
    />
  );
};
