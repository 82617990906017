import cn from "classnames";

import DropdownArrowDownV2 from "assets/icons/DropdownArrowDownV2.svg?react";

import commonStyles from "styles/common.module.scss";
import styles from "../ContextSelector.module.scss";

type Props = {
  onClick: () => void;
  testId: string;
  leftIcon: React.ReactNode;
  label: string;
};

export const ContextButton = (props: Props) => {
  const { onClick, testId, leftIcon, label } = props;
  return (
    <div
      className={cn(styles.button, commonStyles.button)}
      onClick={onClick}
      data-testid={testId}
    >
      <div className={styles.leftIcon}>{leftIcon}</div>
      <div className={styles.text}>{label}</div>
      <div className={styles.rightIcon}>
        <DropdownArrowDownV2 />
      </div>
    </div>
  );
};
