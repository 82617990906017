import { SnackbarContent } from "@mui/material";
import cn from "classnames";
import React, { useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { CloseIcon } from "components/Icons";
import snackbarStyles from "components/SnackBar/styles.module.scss";
import {
  StatusMessageInternal,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import styles from "components/StatusMessageQueue/StatusMessageQueueView/StatusMessageQueueView.module.scss";

interface Props {
  messages: StatusMessageInternal[];
  onMessageClose: (messageId: string) => void;
}

interface SnackBarMessageProps {
  message: string | React.ReactNode;
  testId?: string;
  buttons?: React.ReactNode;
  type: StatusMessageType;
  onClose: () => void;
}

const BASE_HEIGHT = 20;

const SnackBarMessage = (props: SnackBarMessageProps) => {
  const { message, type, onClose, testId, buttons } = props;

  const { t } = useTranslation();
  const [multiline, setMultiline] = React.useState(false);
  const textContentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (textContentRef.current) {
      const height = textContentRef.current.clientHeight;

      setMultiline(height > BASE_HEIGHT);
    }
  }, [message]);

  return (
    <SnackbarContent
      classes={{
        root: cn(snackbarStyles.snackBarContent, {
          [snackbarStyles.errorSnackBar]: type === "error",
          [snackbarStyles.successSnackBar]: type === "success",
          [snackbarStyles.loadingSnackBar]: type === "loading",
          [snackbarStyles.multiLine]: multiline,
        }),
        message: snackbarStyles.message,
      }}
      data-testid={testId}
      message={
        <div className={snackbarStyles.messageContainer}>
          <div className={snackbarStyles.messageWrap}>
            <span
              className={snackbarStyles.messageText}
              ref={textContentRef}
            >
              {typeof message === "string" ? t(message as any) : message}
            </span>

            {!multiline && (
              <div className={snackbarStyles.buttons}>{buttons}</div>
            )}

            <div className={snackbarStyles.closeIconContainer}>
              <div
                className={snackbarStyles.closeIcon}
                onClick={() => {
                  onClose();
                }}
                data-testid="close-snackbar"
              >
                <CloseIcon />
              </div>
            </div>
          </div>
          {multiline && <div className={snackbarStyles.buttons}>{buttons}</div>}
        </div>
      }
    />
  );
};

const StatusMessageQueueView = (props: Props) => {
  const { messages, onMessageClose } = props;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      data-testid="status-message-queue"
      className={cn(styles.root, {
        [styles.hidden]: !messages.length,
        [styles.transparentClick]: false,
      })}
    >
      {messages.map(statusMessage => {
        const { message, type, buttons } = statusMessage;
        return (
          <div
            className={styles.snackbarContainer}
            key={JSON.stringify(message) + type}
            data-testid={`status-message-${type}`}
          >
            <SnackBarMessage
              message={message}
              type={type}
              buttons={buttons}
              testId={statusMessage?.options?.testId}
              onClose={() => onMessageClose(statusMessage.options.id)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default StatusMessageQueueView;
