import { ParentSize } from "@visx/responsive";
import React from "react";
import { useTranslation } from "react-i18next";

import { MeteringRecord } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/EngineMonitoring";
import {
  TimeGrouping,
  UTIL_METRICS_COLORS,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/constants";
import {
  AggregationFunctions,
  createSeries,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/helpers/createSeries";
import {
  getTooltipTimeFormater,
  getTooltipTimeLabel,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/helpers/getTooltipTimeFormater";
import { UtilMetrics } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/types";

import Chart, {
  ChartType,
  MainValueAxisPosition,
} from "components/reports/charts/Chart/Chart";

import { getRanges } from "./helpers/getRanges";
import { useDataKeys } from "./hooks/useDataKeys";

interface UtilChartProps {
  result: MeteringRecord[];
  timeWindow: string | null;
  timeGrouping: TimeGrouping;
  clusters: string[];
  highlightedKeys: UtilMetrics[];
  onKeyToggle: (key: UtilMetrics) => void;
}

const dataKeyValueFormatter = (
  _: UtilMetrics,
  value: number
): {
  value: string;
  unit: string;
  unitPosition: "before" | "after";
} => {
  return {
    value: value.toFixed(0),
    unit: " %",
    unitPosition: "after",
  };
};

const getAverage = (values: number[]) =>
  Math.round(values.reduce((acc, val) => acc + val, 0) / values.length);

const AGGREGATION_FUNCTIONS: AggregationFunctions<UtilMetrics> = {
  [UtilMetrics.CPU]: getAverage,
  [UtilMetrics.Memory]: getAverage,
  [UtilMetrics.Disk]: getAverage,
  [UtilMetrics.Cache]: getAverage,
} as unknown as AggregationFunctions<UtilMetrics>;

const UtilChart = (props: UtilChartProps) => {
  const {
    result,
    timeWindow,
    timeGrouping,
    clusters,
    highlightedKeys,
    onKeyToggle,
  } = props;
  const { t } = useTranslation();

  const [selectedDataKeys, setSelectedDataKeys] = React.useState<UtilMetrics[]>(
    Object.values(UtilMetrics)
  );

  const { keyNames } = useDataKeys({
    t,
    setSelectedDataKeys,
  });

  const series = createSeries<UtilMetrics>(
    result,
    selectedDataKeys,
    AGGREGATION_FUNCTIONS,
    clusters
  );

  const { dateRange, valuesRange } = getRanges({ series, timeWindow });

  return (
    <ParentSize>
      {parent => {
        return (
          <Chart<UtilMetrics>
            chartType={ChartType.Line}
            dateRange={dateRange}
            valuesUnitFormatter={v => `${v.toFixed(0)}%`}
            valuesRange={valuesRange}
            series={series}
            width={parent.width}
            height={parent.height}
            selectedDataKeys={selectedDataKeys}
            highlightedDataKeys={highlightedKeys}
            toggleDataKey={onKeyToggle}
            dataKeys={UtilMetrics}
            dataKeyNames={keyNames}
            dataKeyColors={UTIL_METRICS_COLORS}
            tooltipTimeFormater={getTooltipTimeFormater(timeGrouping)}
            tooltipTimeLabel={getTooltipTimeLabel(timeGrouping, t)}
            dataKeyValueFormatter={dataKeyValueFormatter}
            mainValueAxisPosition={MainValueAxisPosition.Left}
          />
        );
      }}
    </ParentSize>
  );
};

export default UtilChart;
