import { Popover } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useMenu } from "components/ActionMenu/useMenu";
import Toggle from "components/Toggle";

import { ColumnFilterIcon } from "./ColumnFilterIcon";

import styles from "./ColumnFilters.module.scss";

type Props = {
  columns: {
    name: string;
    id: string;
  }[];
  visibleColumns: string[];
  handleColumnClick: (column: string) => void;
  toggleShowAllColumns: () => void;
};

export const TableColumnFilters = (props: Props) => {
  const { columns, visibleColumns, handleColumnClick, toggleShowAllColumns } =
    props;
  const { t } = useTranslation();
  const { menuElement, openMenu, closeMenu, iconElement } = useMenu();

  return (
    <>
      <ColumnFilterIcon
        active={!!menuElement}
        ref={iconElement}
        inverse
        onClick={openMenu}
        testId="columns-filter-toggle"
      />
      {!!menuElement && (
        <Popover
          open={!!menuElement}
          anchorEl={menuElement}
          onClose={closeMenu}
          onClick={event => {
            event.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={styles.popup}>
            <div className={styles.items}>
              <div className={classNames(styles.item, styles.item__all)}>
                <div className={styles.item__name}>{t("columns.show_all")}</div>
                <Toggle
                  checked={visibleColumns.length === columns.length}
                  onChange={toggleShowAllColumns}
                  dataTestId="show-all-columns-toggle"
                  size="sm"
                  classes={{ wrapper: styles.toggle }}
                />
              </div>
              <div className={styles.list}>
                <div className={styles.list__inner}>
                  {columns.map(column => {
                    return (
                      <div
                        className={styles.item}
                        key={column.id}
                      >
                        <div className={styles.item__name}>{column.name}</div>
                        <Toggle
                          checked={visibleColumns.includes(column.id)}
                          onChange={() => handleColumnClick(column.id)}
                          size="sm"
                          dataTestId={`filter-column-${column.id}`}
                          classes={{ wrapper: styles.toggle }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};
