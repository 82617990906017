import classNames from "classnames";
import React, { useContext } from "react";

import { TableContext, WidthContext } from "./context";

import styles from "./styles.module.scss";

type CellProps = {
  id: string;
  noBorder?: boolean;
  nested?: boolean;
  fillAvailableWidth?: boolean;
  className?: string;
  children?: React.ReactNode;
  dataTestId?: string;
};

export const Cell: React.FC<CellProps> = props => {
  const {
    children,
    id,
    dataTestId,
    nested,
    noBorder,
    className,
    fillAvailableWidth,
  } = props;
  const { leadingWidth, columnWidths } = useContext(WidthContext);
  const { floatingColumn, visibleColumns } = useContext(TableContext);
  const width = columnWidths[id];
  const lastColumnWidth = `calc(100% - ${leadingWidth}px)`;
  const style = {
    minWidth: width,
    width: fillAvailableWidth ? lastColumnWidth : width,
  };

  if (id === floatingColumn) {
    return (
      <div
        style={style}
        className={classNames(styles.cell, styles.cell__floating, className, {
          [styles.noBorder]: noBorder,
        })}
        data-role="cell"
        data-testid={dataTestId || id}
      >
        {children}
      </div>
    );
  }

  if (!visibleColumns.includes(id)) {
    return null;
  }

  return (
    <div
      style={style}
      className={classNames(styles.cell, className, {
        [styles.noBorder]: noBorder,
        [styles.cell__floating]: id === floatingColumn,
        [styles.nested]: nested,
      })}
      data-role="cell"
      data-testid={dataTestId || id}
    >
      <div className={styles.text}>{children}</div>
    </div>
  );
};
