import { useEffect, useRef } from "react";

import { Database } from "services/databases/useDatabasesNames";
import { isSystemDatabase } from "services/databases/utils";
import { WorkspaceEngine } from "services/engines/engine.types";
import { SqlRbacAction } from "services/rbac/action";
import { useCurrentUser } from "services/users/useCurrentUser";

import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import {
  ExecutionContext,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import { useAccessManager } from "components/App/accessManager";

import { NONE } from "../DocumentContextSelector/types";

export const useAutoSetContext = ({
  engines,
  databases,
  document,
}: {
  engines: WorkspaceEngine[];
  databases: Database[];
  document: WorkspaceDocument;
}) => {
  const { isAllowedTo } = useAccessManager();
  const currentUser = useCurrentUser();
  const {
    actions: { changeDocumentContext },
  } = useDocuments();

  const dependencies = useRef({
    id: document.id,
    engines,
    databases,
    engineContext: document.context.engine,
    databaseContext: document.context.database,
    changeDocumentContext,
    isAllowedTo,
    currentUser,
  });

  useEffect(() => {
    dependencies.current = {
      id: document.id,
      engines,
      databases,
      engineContext: document.context.engine,
      databaseContext: document.context.database,
      changeDocumentContext,
      isAllowedTo,
      currentUser,
    };
  });

  useEffect(() => {
    const {
      id,
      engines,
      databases,
      engineContext,
      databaseContext,
      changeDocumentContext,
      isAllowedTo,
      currentUser,
    } = dependencies.current;
    const withoutSystem = engines.filter(
      engine => engine.engineName !== "system"
    );
    const update: Partial<ExecutionContext> = {};
    const getEngineContext = (): Partial<ExecutionContext> => {
      if (currentUser?.defaultEngine) {
        const engine = engines.find(
          engine => engine.engineName === currentUser.defaultEngine
        );
        if (
          engine &&
          isAllowedTo("engine", engine.engineName, SqlRbacAction.OPERATE, {
            owner: engine.engineOwner,
          })
        ) {
          return { engine: { name: engine.engineName } };
        }
      }
      if (withoutSystem.length === 1) {
        const engine = withoutSystem[0];
        if (
          isAllowedTo("engine", engine.engineName, SqlRbacAction.OPERATE, {
            owner: engine.engineOwner,
          })
        ) {
          return {
            engine: { name: engine.engineName },
          };
        }
      }
      return {};
    };

    const getDatabaseContext = (): Partial<ExecutionContext> => {
      const userDatabases = databases.filter(
        database => !isSystemDatabase(database.catalogName)
      );
      if (currentUser?.defaultDatabase) {
        const database = userDatabases.find(
          database => database.catalogName === currentUser.defaultDatabase
        );
        if (
          database &&
          isAllowedTo("database", database.catalogName, SqlRbacAction.USAGE)
        ) {
          return { database: { name: database.catalogName } };
        }
      }
      if (userDatabases.length === 1) {
        const database = userDatabases[0];
        if (
          isAllowedTo("database", database.catalogName, SqlRbacAction.USAGE)
        ) {
          return { database: { name: database.catalogName } };
        }
      }
      return {};
    };

    if (
      !engineContext?.name ||
      (engineContext?.name === "system" && !engineContext?.isUserSelected)
    ) {
      Object.assign(update, getEngineContext());
    }

    if (
      !databaseContext?.name ||
      (databaseContext?.name === NONE && !databaseContext?.isUserSelected)
    ) {
      Object.assign(update, getDatabaseContext());
    }

    Object.keys(update).length && changeDocumentContext(id, update);
  }, []);
};
