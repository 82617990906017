import { Popover } from "@mui/material";
import classNames from "classnames";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { Search } from "components/LeftSidebar/Search";

import styles from "../ContextSelector.module.scss";

type Props = {
  open: boolean;
  anchorRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  showSearch: boolean;
  setSearchValue: (value: string) => void;
  searchValue: string;
  searchTestId: string;
  notFoundText: string;
  items: JSX.Element[];
  noSearchResults: boolean;
  classes?: {
    paper?: string;
  };
  noItemsFallback?: React.ReactNode;
};

export const ContextPopover = (props: Props) => {
  const {
    open,
    anchorRef,
    onClose,
    showSearch,
    setSearchValue,
    searchValue,
    searchTestId,
    noSearchResults,
    notFoundText,
    items,
    classes,
    noItemsFallback,
  } = props;

  const itemsComponent =
    !items.length && !!noItemsFallback ? (
      noItemsFallback
    ) : (
      <ContextMenu>
        <ContextMenuItemsGroup
          isGroup={true}
          maxHeight={400}
        >
          {items}
        </ContextMenuItemsGroup>
      </ContextMenu>
    );

  return (
    <Popover
      open={open}
      anchorEl={anchorRef?.current}
      classes={{ paper: classNames(styles.paper, classes?.paper) }}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div>
        {showSearch && (
          <Search
            testId={searchTestId}
            onChange={setSearchValue}
            value={searchValue}
            autoFocus={true}
            size="small"
          />
        )}
      </div>
      <div className={styles.selectorItems}>
        {noSearchResults && searchValue ? (
          <div className={styles.notFound}>{notFoundText}</div>
        ) : (
          itemsComponent
        )}
      </div>
    </Popover>
  );
};
