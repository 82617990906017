import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";

import { SYSTEM_DATABASE_NAMES } from "./utils";

export type Database = {
  catalogName: string;
  description: string;
  catalogOwner: string;
  created: string;
  lastAltered: string;
};

export const NO_SYSTEM_DATABASES_QUERY_KEY = "NO_SYSTEM_DATABASES";

export const useDatabasesNames = (options?: {
  isFirstUser?: boolean;
  showSystemDatabases?: boolean;
}): Database[] => {
  const [dataLength, setDataLength] = useState(0);
  const isFirstUser = options?.isFirstUser;

  const queryKey: string[] = [ReactQueryKeysAccount.databasesNames];

  let where = "";

  if (!options?.showSystemDatabases) {
    where = `WHERE catalog_name NOT IN (${SYSTEM_DATABASE_NAMES.map(name => `'${name}'`).join(", ")})`;
    queryKey.push(NO_SYSTEM_DATABASES_QUERY_KEY);
  }

  const { data } = useSuspenseQuery<Database[], Error>({
    queryKey,
    queryFn: async () => {
      const result = await systemEngineEnvironment.execute<Database>(
        `SELECT catalog_name, description, catalog_owner, created, last_altered FROM information_schema.catalogs ${where} order by LOWER(catalog_name)`
      );

      const [response] = result;
      return response.rows;
    },
    refetchInterval: isFirstUser && !dataLength ? 2000 : false,
  });

  useEffect(() => {
    setDataLength(data.length);
  }, [data.length]);

  return data || [];
};
