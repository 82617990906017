import { useEffect, useState } from "react";

import { View } from "services/databases/getViews";
import { useDatabasesNames } from "services/databases/useDatabasesNames";
import { Table } from "services/databases/useTableNames";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { SystemRole } from "services/rbac/constants";
import { useRbacRoles } from "services/rbac/useRbacRoles";
import { useUsers } from "services/users/useUsers";

export const hasOwnershipRecords = (
  data: ReturnType<typeof useOwnershipRecords>
) => {
  return !!(
    data.listedDatabases.length ||
    data?.engines?.data?.length ||
    data.userRoles.length ||
    data.ownedUsers.length ||
    data.databasesTables.objects.length ||
    data.databaseViews.objects.length
  );
};

const useOwnershipRecords = ({ userName }: { userName?: string }) => {
  const databases = useDatabasesNames(); // databases to fetch tables and views

  // TODO remove the mock loading state
  // It's used to simulate the loading state of the tables and views
  const [tablesAndViewsLoading, setTablesAndViewsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTablesAndViewsLoading(false);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  const listedDatabases = userName
    ? databases.filter(database => database.catalogOwner === userName)
    : databases; // databases to show in the list

  const engines = useWorkspaceEngines({
    byOwnerUsername: userName,
  });
  const roles = useRbacRoles({
    byOwnerUsername: userName,
  });

  const ownedUsers = useUsers({
    byOwnerUsername: userName,
  });

  const userRoles = roles.filter(
    role => !Object.values(SystemRole).includes(role.roleName as SystemRole)
  );

  // TODO uncomment when the backend is ready
  /* const databasesTables = useQueries({
    queries: databases.map(database => ({
      queryKey: [
        ReactQueryKeysAccount.database_tables,
        database.catalogName,
        `OWNER-${userName}`,
      ],
      queryFn: async () => {
        return {
          databaseName: database.catalogName,
          tables: await getDatabaseTables(database.catalogName, {
            byOwnerUsername: userName,
          }),
        };
      },
    })),
    combine: results => {
      const tables: {
        isLoading: boolean;
        objects: Array<{
          databaseName: string;
          table: Table;
        }>;
      } = {
        isLoading: false,
        objects: [],
      };

      results.forEach(result => {
        result.data?.tables.forEach(table => {
          tables.objects.push({
            databaseName: result.data?.databaseName,
            table,
          });
        });
      });

      results.some(result => result.isLoading) && (tables.isLoading = true);

      return tables;
    },
  });

  const databaseViews = useQueries({
    queries: databases.map(database => ({
      queryKey: [
        ReactQueryKeysAccount.database_views,
        database.catalogName,
        `OWNER-${userName}`,
      ],
      queryFn: async () => {
        return {
          databaseName: database.catalogName,
          views: await getViews(database.catalogName, {
            byOwnerUsername: userName,
          }),
        };
      },
    })),
    combine: results => {
      const views: {
        isLoading: boolean;
        objects: Array<{
          databaseName: string;
          view: View;
        }>;
      } = {
        isLoading: false,
        objects: [],
      };

      results.forEach(result => {
        result.data?.views.forEach(view => {
          views.objects.push({
            databaseName: result.data?.databaseName,
            view,
          });
        });
      });

      results.some(result => result.isLoading) && (views.isLoading = true);

      return views;
    },
  });
*/
  return {
    listedDatabases,
    engines,
    userRoles,
    ownedUsers,
    databasesTables: {
      isLoading: tablesAndViewsLoading,
      objects: [] as Array<{
        databaseName: string;
        table: Table;
      }>,
    },
    databaseViews: {
      isLoading: tablesAndViewsLoading,
      objects: [] as Array<{
        databaseName: string;
        view: View;
      }>,
    },
  };
};

export default useOwnershipRecords;
