import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";
import { SqlRbacAction } from "services/rbac/action";

import { useAccessManager } from "components/App/accessManager";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";

import { ContextSelector } from "../ContextSelector";
import { EngineIconWrapper } from "../components/EngineIconWrapper";
import { EngineItemContent } from "../components/EngineItemContent/EngineItemContent";
import { EngineItemInfo } from "../components/EngineItemInfo/EngineItemInfo";
import {
  EngineOperateTooltipTitle,
  SystemEngineTooltipTitle,
} from "../components/tooltipTitles";

import styles from "./EngineContextSelector.module.scss";

type Props = {
  active: boolean;
  isDisabled: boolean;
  tooltipError?: React.ReactNode | string;
  selectedEngineName: string;
  onSelectEngine: (_engineName: string) => void;
  includeSystemEngine?: boolean;
};

export const EngineContextSelector = (props: Props) => {
  const {
    active,
    isDisabled,
    tooltipError,
    selectedEngineName,
    onSelectEngine,
    includeSystemEngine = false,
  } = props;
  const { t } = useTranslation();
  const { isAllowedTo } = useAccessManager();
  const { data: engines = [] } = useWorkspaceEngines({ includeSystemEngine });
  const searchEngineRef = useRef<HTMLDivElement>(null);
  const [engineSelectorOpen, setEngineSelectorOpen] = useState(false);
  const [searchEngine, setSearchEngine] = useState("");

  const selectedEngine = engines.find(
    engine => engine.engineName === selectedEngineName
  );

  const sortedEngines = [...engines].sort((a, b) => {
    if (a.engineName === "system") return -1;
    if (b.engineName === "system") return 1;

    return a.engineName.localeCompare(b.engineName);
  });

  const foundEngines = sortedEngines.filter(engine => {
    return engine.engineName.toLowerCase().includes(searchEngine.toLowerCase());
  });
  return (
    <ContextSelector
      active={active}
      isDisabled={isDisabled}
      selectorRef={searchEngineRef}
      tooltipError={tooltipError}
      onClick={() => {
        if (isDisabled) return;
        setSearchEngine("");
        setEngineSelectorOpen(true);
      }}
      testId="engine-context-selector"
      leftIcon={<EngineIconWrapper engine={selectedEngine} />}
      label={selectedEngineName}
      selectorIsOpen={engineSelectorOpen}
      onClose={() => {
        setEngineSelectorOpen(false);
      }}
      showSearch={engines.length > 5}
      setSearchValue={setSearchEngine}
      searchValue={searchEngine}
      searchTestId="search-engine-context"
      notFoundText={t("workspace.context_selector.no_matching_engine")}
      noSearchResults={foundEngines.length === 0}
      popoverClasses={{
        paper: styles.popoverPaper,
      }}
      items={foundEngines.map(engine => {
        const isSystem = engine.engineName === "system";

        const canOperateEngine = isAllowedTo(
          "engine",
          engine.engineName,
          SqlRbacAction.OPERATE,
          { owner: engine.engineOwner }
        );

        return (
          <ContextMenuItem
            key={engine.engineName}
            checked={engine.engineName === selectedEngineName}
            testId={`context-engine-item-${engine.engineName}`}
            onClick={() => {
              onSelectEngine(engine.engineName);
              setEngineSelectorOpen(false);
            }}
            checkedIconPlaceholder={true}
            text={engine.engineName}
            classes={{
              textClassName: styles.itemText,
              contentClassName:
                isSystem || !canOperateEngine ? styles.itemContent : "",
              checkIconWrapper: styles.itemCheckIconWrapper,
            }}
            contentVisibleOnlyOnHover
            content={
              isSystem || !canOperateEngine ? (
                <EngineItemInfo
                  title={
                    isSystem ? (
                      <SystemEngineTooltipTitle />
                    ) : (
                      <EngineOperateTooltipTitle />
                    )
                  }
                />
              ) : (
                <EngineItemContent engine={engine} />
              )
            }
          />
        );
      })}
    />
  );
};
