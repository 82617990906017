import cn from "classnames";

import Tooltip from "components/Tooltip";

import { ContextButton } from "./components/ContextButton";
import { ContextPopover } from "./components/ContextPopover";

import styles from "./ContextSelector.module.scss";

type Props = {
  active: boolean;
  isDisabled: boolean;
  selectorRef: React.RefObject<HTMLDivElement>;
  tooltipError?: React.ReactNode | string;
  onClick: () => void;
  testId: string;
  leftIcon: React.ReactNode;
  label: string;
  selectorIsOpen: boolean;
  onClose: () => void;
  showSearch: boolean;
  setSearchValue: (value: string) => void;
  searchValue: string;
  searchTestId: string;
  notFoundText: string;
  items: JSX.Element[];
  noSearchResults: boolean;
  popoverClasses?: {
    paper?: string;
  };
  noItemsFallback?: React.ReactNode;
};

export const ContextSelector = (props: Props) => {
  const {
    active,
    isDisabled,
    selectorRef,
    tooltipError,
    onClick,
    testId,
    leftIcon,
    label,
    selectorIsOpen,
    onClose,
    showSearch,
    setSearchValue,
    searchValue,
    searchTestId,
    noSearchResults,
    notFoundText,
    items,
    popoverClasses,
    noItemsFallback,
  } = props;
  return (
    <div
      className={cn(styles.selector, {
        [styles.active]: active,
        [styles.disabled]: isDisabled,
        [styles.error]: !!tooltipError,
      })}
      ref={selectorRef}
    >
      <Tooltip
        classes={{ tooltip: styles.tooltip }}
        title={tooltipError ?? null}
      >
        <ContextButton
          onClick={onClick}
          testId={testId}
          leftIcon={leftIcon}
          label={label}
        />
      </Tooltip>

      {selectorIsOpen && (
        <ContextPopover
          classes={popoverClasses}
          noItemsFallback={noItemsFallback}
          open={selectorIsOpen}
          anchorRef={selectorRef}
          onClose={onClose}
          showSearch={showSearch}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          searchTestId={searchTestId}
          notFoundText={notFoundText}
          items={items}
          noSearchResults={noSearchResults}
        />
      )}
    </div>
  );
};
