import React from "react";

type WidthContextType = {
  leadingWidth: number;
  columnWidths: Record<string, number>;
};

type TableContextType = {
  floatingColumn?: string;
  visibleColumns: string[];
  filterColumns: { id: string; name: string }[];
  toggleShowAllColumns: () => void;
  handleColumnClick: (column: string) => void;
};

export const WidthContext = React.createContext<WidthContextType>({
  leadingWidth: 0,
  columnWidths: {},
});

export const TableContext = React.createContext<TableContextType>({
  visibleColumns: [],
  filterColumns: [],
  toggleShowAllColumns: () => {},
  handleColumnClick: () => {},
});
