import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

import { WorkspaceEngine } from "services/engines/engine.types";
import { systemEngineEnvironment } from "services/environment/systemEngine";

import { SYSTEM_ENGINE } from "pages/DevelopWorkspace/workspace.constants";

import { useCurrentAccount } from "components/Account/useCurrentAccount";

import { ReactQueryKeysAccount } from "../queryKeys";

export const useWorkspaceEngines = (options?: {
  includeSystemEngine?: boolean;
  isFirstUser?: boolean;
  byOwnerUsername?: string;
}) => {
  const [dataLength, setDataLength] = useState(0);
  const includeSystemEngine = options?.includeSystemEngine;
  const isFirstUser = options?.isFirstUser;
  const { getAccount } = useCurrentAccount();

  const queryKey: string[] = [ReactQueryKeysAccount.workspaceEngines];

  if (isFirstUser) {
    queryKey.push("FIRST_USER");
  }

  if (includeSystemEngine) {
    queryKey.push("INCLUDE_SYSTEM_ENGINE");
  }

  let where = "";
  if (options?.byOwnerUsername) {
    where = `WHERE engine_owner = '${options.byOwnerUsername}'`;
    queryKey.push(`BY_OWNER-${options.byOwnerUsername}`);
  }

  const { data, isLoading, error } = useSuspenseQuery<WorkspaceEngine[], Error>(
    {
      queryKey,
      queryFn: async () => {
        const result = await systemEngineEnvironment.execute<WorkspaceEngine>(
          `select * from information_schema.engines ${where} order by lower(engine_name);`
        );
        const [response] = result;
        return response.rows.map(r => ({
          ...r,
          nodes: +r.nodes,
          clusters: +r.clusters,
        }));
      },
      refetchInterval: isFirstUser && !dataLength ? 2000 : false,
    }
  );

  useEffect(() => {
    setDataLength(data.length);
  }, [data.length]);

  const engines = useMemo(() => {
    const engines = data ? [...data] : undefined;

    if (includeSystemEngine && engines) {
      const account = getAccount();

      if (!account) {
        throw new Error("Account is not defined");
      }

      const systemEngineUrl =
        systemEngineEnvironment.getApiEndpoint().replace(/^https?:\/\//, "") +
        "/query";

      engines.unshift({
        ...SYSTEM_ENGINE,
        url: systemEngineUrl,
      });
    }

    return engines;
  }, [data, includeSystemEngine, getAccount]);

  return { data: engines, isLoading, error };
};
