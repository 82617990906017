// eslint-disable-next-line no-restricted-imports -- it's the only place we use react-helmet-async
import { Helmet } from "react-helmet-async";

interface HelmetWrapperProps {
  title?: string;
  children?: React.ReactNode;
}

const HelmetWrapper: React.FC<HelmetWrapperProps> = ({ title, children }) => {
  return (
    <Helmet
      titleTemplate="%s - Firebolt"
      defaultTitle="Firebolt"
    >
      {title && <title>{title}</title>}
      {children}
    </Helmet>
  );
};

export default HelmetWrapper;
