import { TFunction } from "i18next";

import { getBackendErrorMessage, getContactSupportURL } from "./helpers";

export const createSupportTicket = ({
  body,
  onSuccess,
  onError,
  t,
}: {
  body: string;
  onSuccess: () => void;
  onError: (error?: string) => void;
  t: TFunction;
}) => {
  const contactSupportUrl = getContactSupportURL();
  return fetch(contactSupportUrl, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body,
  })
    .then(response => {
      if (response?.status !== 201) {
        console.error("Failed to submit the support ticket", response);
      }
      return response.json();
    })
    .then(data => {
      if (data?.success) {
        onSuccess();
      } else {
        const errorMessage = getBackendErrorMessage(data, t);
        onError(errorMessage);
        console.error("Failed to submit the support ticket", data);
      }
    })
    .catch(error => {
      onError();
      console.error("Failed to submit the support ticket", error);
    });
};
