import { Dialog, IconButton } from "@mui/material";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import Button, { ButtonTemplate } from "components/Button/Button";
import { CrossIcon } from "components/Icons";

import styles from "./styles.module.scss";

type Props = {
  primaryButton: string;
  primaryButtonTemplate?: ButtonTemplate;
  onClose: () => void;
  onSubmit: (event: React.SyntheticEvent) => void;
  isLoading: boolean;
  disabledSubmit?: boolean;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  footer?: React.ReactNode;
  borderedFooter?: boolean;
  hideCancel?: boolean;
  classes?: {
    root?: string;
    header?: string;
    title?: string;
    body?: string;
    footer?: string;
  };
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
};

export const Modal = (props: React.PropsWithChildren<Props>) => {
  const {
    onClose,
    title,
    subtitle,
    children,
    footer,
    primaryButton,
    primaryButtonTemplate = ButtonTemplate.Primary,
    isLoading,
    onSubmit,
    disabledSubmit = false,
    classes = {},
    hideCancel,
    borderedFooter,
    maxWidth = "sm",
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose}
      scroll="body"
      open
    >
      <form
        className={styles.wrapper}
        onSubmit={e => {
          e.preventDefault();
          onSubmit(e);
        }}
      >
        <div className={cn(styles.header, classes.header)}>
          <div className={styles.header__inner}>
            <div className={cn(styles.header__title, classes.title)}>
              {title}
            </div>
            <div className={styles.header__subtitle}>{subtitle}</div>
          </div>
          <IconButton
            onClick={onClose}
            edge="start"
            size="small"
            className={styles.close}
          >
            <CrossIcon data-testid="close-modal-cross" />
          </IconButton>
        </div>
        <div className={cn(styles.body, classes.body)}>{children}</div>
        {footer}
        <div
          className={cn(
            styles.footer,
            {
              [styles.borderedFooter]: borderedFooter,
            },
            classes.footer
          )}
        >
          <Button
            text={t(primaryButton)}
            isLoading={isLoading}
            disabled={disabledSubmit || isLoading}
            className={styles.submitBtn}
            template={primaryButtonTemplate}
            dataTestid="submit-modal-btn"
            onClick={onSubmit}
            id="submit"
            type="submit"
          />
          {!hideCancel && (
            <Button
              text={t("workspace.create_engine.cancel")}
              template={ButtonTemplate.Ghost}
              onClick={onClose}
              dataTestid="close-modal-btn"
              disabled={isLoading}
            />
          )}
        </div>
      </form>
    </Dialog>
  );
};
