import cn from "classnames";

import {
  WorkspaceEngine,
  WorkspaceEngineType,
} from "services/engines/engine.types";

import { RecordNode } from "components/LeftSidebar/RecordNode";
import { EngineIconWrapper } from "components/composite/ContextSelector/components/EngineIconWrapper";
import { EngineStatusToggle } from "components/composite/EngineStatusToggle/EngineStatusToggle";

import { EngineMenu, EngineMenuActions } from "./EngineMenu";

import styles from "./styles.module.scss";

type Props = {
  engine: WorkspaceEngine;
  actions: EngineMenuActions;
};

const typeMap = {
  [WorkspaceEngineType.XS]: "XSmall",
  [WorkspaceEngineType.S]: "Small",
  [WorkspaceEngineType.M]: "Medium",
  [WorkspaceEngineType.L]: "Large",
  [WorkspaceEngineType.XL]: "Xlarge",
  [WorkspaceEngineType.None]: "",
};

export const ExpandedEngineRow = (props: Props) => {
  const { engine, actions } = props;
  const type = typeMap[engine.type] || engine.type;

  return (
    <RecordNode className={styles.expanded}>
      <div className={styles.expanded__inner}>
        <div className={styles.column__engines}>
          <span className={styles.column__icon}>
            <EngineIconWrapper engine={engine} />
          </span>
          <span className={styles.column__name}>{engine.engineName}</span>
        </div>
        <div className={styles.columns}>
          <div className={styles.column__status}>
            <EngineStatusToggle engine={engine} />
          </div>
          <div className={styles.column}>{type}</div>
          <div className={cn(styles.column, styles.column__nodes)}>
            {engine.nodes}
          </div>
          <div className={cn(styles.column, styles.column__clusters)}>
            {engine.clusters}
          </div>
        </div>
        <EngineMenu
          engine={engine}
          actions={actions}
        />
      </div>
    </RecordNode>
  );
};
