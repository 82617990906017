import { useCallback, useMemo, useState } from "react";

import { ColumnType } from "./types";

export const useColumnFilters = (columns: ColumnType[]) => {
  const filterColumns = useMemo(
    () => columns.filter(column => !column.disableColumnFilter),
    [columns]
  );

  const initiallyVisibleColumns = columns
    .filter(column => column.initiallyVisible)
    .map(column => column.id);

  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    initiallyVisibleColumns
  );

  const handleColumnClick = useCallback(
    (column: string) => {
      setVisibleColumns(prev => {
        if (prev.includes(column)) {
          return prev.filter(col => col !== column);
        }
        return [...prev, column];
      });
    },
    [setVisibleColumns]
  );

  const toggleShowAllColumns = useCallback(() => {
    setVisibleColumns(prev => {
      if (prev.length === filterColumns.length) {
        return initiallyVisibleColumns;
      }
      return filterColumns.map(column => column.id);
    });
  }, [setVisibleColumns, initiallyVisibleColumns, filterColumns]);

  return {
    filterColumns,
    visibleColumns,
    handleColumnClick,
    toggleShowAllColumns,
  };
};
