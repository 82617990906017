import * as Sentry from "@sentry/react";

export const attachTestIdBreadcrumb = (
  breadcrumb: Sentry.Breadcrumb,
  hint?: Sentry.BreadcrumbHint
) => {
  const testId = hint?.event?.target?.dataset?.testid;
  const info = !testId
    ? {}
    : {
        data: { testId },
      };

  return { ...breadcrumb, ...info };
};
