import classNames from "classnames";

import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
} from "services/engines/engine.types";

import { DocumentExecutionError } from "pages/DevelopWorkspace/workspace.types";

import { chipMap } from "./Chips";

import styles from "./styles.module.scss";

export type EngineStatusToggleProps = {
  engine: WorkspaceEngine;
  inactive?: boolean;
  inline?: boolean;
  inMenu?: boolean;
  size?: "small" | "regular";
  error?: DocumentExecutionError | null;
};

const FallbackStatus = () => null;

export const EngineStatusToggle = (props: EngineStatusToggleProps) => {
  const { error, engine, inline, inactive, inMenu, size = "regular" } = props;
  const ChipComponent =
    chipMap[engine.status as WorkspaceEngineStatus] || FallbackStatus;

  return (
    <div className={classNames(styles.toggle, { [styles.error]: !!error })}>
      <ChipComponent
        engine={engine}
        inactive={inactive}
        inMenu={inMenu}
        inline={inline}
        size={size}
      />
    </div>
  );
};
