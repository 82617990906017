import {
  Divider,
  List,
  Tooltip,
  TooltipClasses,
  TooltipProps,
} from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

import TooltipItem, { TooltipItemProps } from "./TooltipItem/TooltipItem";
import { TooltipAutoOpenInterface } from "./types";

import styles from "./styles.module.scss";

interface RenderProps {
  isOpen?: boolean;
}

type TooltipItemType = TooltipItemProps;
type ListItemType = TooltipItemType[];

interface PropsTooltip extends TooltipProps {
  lists?: ListItemType[];
  classes?: Partial<TooltipClasses>;
  autoOpen?: TooltipAutoOpenInterface;
  render?: (renderProps: RenderProps) => React.ReactElement;
  title: React.ReactNode;
  tooltipTitleClassName?: string;
  testId?: string;
}

const renderTooltipContent = (lists: ListItemType[]) => {
  return (
    <List
      component="nav"
      classes={{ root: styles.list }}
    >
      {lists.flatMap((item, index) => {
        if (index > 0) {
          return [
            <Divider
              key={`divider-${index}`} // eslint-disable-line react/no-array-index-key -- is ok here
              classes={{ root: styles.divider }}
            />,
            item.flatMap((tooltipItem, index) => {
              return (
                <TooltipItem
                  key={`${tooltipItem.name}|${tooltipItem.value}`}
                  noMargin={!index}
                  name={tooltipItem.name}
                  value={tooltipItem.value}
                />
              );
            }),
          ];
        }
        return [
          item.flatMap((tooltipItem, index) => {
            return (
              <TooltipItem
                key={`${tooltipItem.name}|${tooltipItem.value}`}
                noMargin={!index}
                name={tooltipItem.name}
                value={tooltipItem.value}
              />
            );
          }),
        ];
      })}
    </List>
  );
};

const CustomTooltip = React.forwardRef((props: PropsTooltip, ref) => {
  const {
    lists,
    title,
    classes = {},
    autoOpen,
    render,
    children,
    tooltipTitleClassName,
    testId,
    ...restProps
  } = props;

  const [open, setOpen] = useState(!!autoOpen);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (autoOpen && open) {
      timer = setTimeout(() => {
        setOpen(false);
      }, autoOpen.hideDelay);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [autoOpen, open]);

  useEffect(() => {
    if (autoOpen?.forceClose) {
      setOpen(false);
    }
  }, [autoOpen?.forceClose]);

  if (!title && !lists?.length) {
    return <>{children}</>;
  }

  const tooltipProps = {
    componentsProps: {
      popper: {
        modifiers: [
          restProps.placement === "top-start"
            ? {
                name: "offset",
                options: {
                  offset: [0, 5],
                },
              }
            : null,
          {
            name: "preventOverflow",
            options: {
              enabled: true,
              boundary: document.body,
            },
          },
        ].filter(m => m !== null),
      },
    },
    ...restProps,
  };

  if (open) {
    tooltipProps.open = open;
  }

  return (
    <Tooltip
      ref={ref}
      arrow={false}
      key={`${open}`}
      enterDelay={400}
      leaveDelay={200}
      enterNextDelay={200}
      classes={{
        popper: classNames(styles.popper, classes.popper),
        tooltip: classNames(styles.tooltip, classes.tooltip),
        tooltipPlacementLeft: styles.tooltipPlacementLeft,
        tooltipPlacementRight: styles.tooltipPlacementRight,
        tooltipPlacementTop: classNames(
          styles.tooltipPlacementTop,
          classes.tooltipPlacementTop
        ),
        tooltipPlacementBottom: styles.tooltipPlacementBottom,
        arrow: styles.arrow,
      }}
      title={
        lists ? (
          renderTooltipContent(lists)
        ) : (
          <span
            data-testid={testId}
            className={classNames(styles.tooltipTitle, tooltipTitleClassName)}
          >
            {title}
          </span>
        )
      }
      {...tooltipProps}
    >
      {render ? render({ isOpen: open }) : children}
    </Tooltip>
  );
});

export default CustomTooltip;
