export const statusCodes = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const SUPPORT_EMAIL = "support@firebolt.io";

export const FIREBOLT_DOCS_URL = "https://docs.firebolt.io/godocs";

export const FIREBOLT_RELEASE_NOTES_URL_NEW =
  "https://docs.firebolt.io/Reference/release-notes/release-notes.html";

export const FIREBOLT_STATUS_PAGE_URL = "https://status.firebolt.io/";

export const FIREBOLT_DOC_SHORTCUTS_URL =
  "https://docs.firebolt.io/Guides/query-data/using-the-develop-workspace.html#keyboard-shortcuts-for-the-develop-space";

export const SYSTEM_ENGINE_DOCS_URL = `${FIREBOLT_DOCS_URL}/Guides/operate-engines/system-engine.html`;
