import { Popover } from "@mui/material";
import cn from "classnames";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { isMac } from "services/browser";

import MenuIconHoriz from "assets/icons/MenuIconHoriz.svg?react";
import TabCloseIcon from "assets/icons/TabCloseIcon.svg?react";

import {
  QUERY_TEMPLATES,
  SCRIPT_ACTIONS,
} from "pages/DevelopWorkspace/Editor/Document/DocumentContent/constants/script-templates";
import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import { SHORTCUTS } from "pages/DevelopWorkspace/services/shortcuts/constants";
import { getShortcutHint } from "pages/DevelopWorkspace/services/shortcuts/helpers/getShortcutHint";
import { ShortcutAction } from "pages/DevelopWorkspace/services/shortcuts/types";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import NestedContextMenuItem from "components/ContextMenu/NestedContextMenuItem";

import HintTooltip from "../components/HintTooltip";

import styles from "./Tab.module.scss";

export enum TabStatus {
  Success = "success",
  Error = "error",
  Running = "running",
}

interface TabProps {
  title?: string;
  content?: string;
  isActive: boolean;
  onSelect: () => void;
  onClose: (closeAllOther: boolean) => void;
  onRenameSubmit: (newTitle: string) => void;
  isLoadingScript: boolean;
  isUnsaved: boolean;
  status?: TabStatus;
}

const Tab = (props: TabProps) => {
  const {
    title,
    content,
    isActive,
    onSelect,
    onClose,
    onRenameSubmit,
    isLoadingScript,
    isUnsaved,
    status,
  } = props;

  const { formatText, insertTextAtCursorPosition } = useActiveEditorView();

  const { t } = useTranslation();
  const popoverRef = useRef<HTMLDivElement>(null);
  const anchorEl = useRef<HTMLDivElement | null>(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newTitle, setNewTitle] = useState(title || "");

  const startRenaming = () => {
    if (isLoadingScript) {
      return;
    }

    setIsRenaming(true);

    if (title) {
      setNewTitle(title);
    }
  };

  // TODO new title validation
  const handleRenameSubmit = () => {
    setIsRenaming(false);
    if (isLoadingScript) {
      return;
    }
    if (newTitle === title) {
      return;
    }

    onRenameSubmit(newTitle);
  };

  const handleTemplateClick =
    (type: SCRIPT_ACTIONS, onContentClose: () => void) => () => {
      onContentClose();
      setMenuOpen(false);

      if (type === SCRIPT_ACTIONS.IMPORT_FILE) {
        return;
      }

      const snippet = QUERY_TEMPLATES[type];
      insertTextAtCursorPosition(snippet);
    };

  const renderRenamingBody = () => {
    return (
      <div className={styles.renamingModeBody}>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleRenameSubmit();
          }}
        >
          <input
            type="text"
            // eslint-disable-next-line jsx-a11y/no-autofocus -- autofocus is ok here
            autoFocus
            onFocus={e => {
              e.target.select();
            }}
            className={styles.input}
            value={newTitle}
            onChange={e => setNewTitle(e.target.value)}
            data-testid="document-rename-input"
            onBlur={() => {
              // TODO check for double renaming as form has been submitted on enter
              handleRenameSubmit();
            }}
          />
        </form>
      </div>
    );
  };

  const renderBody = () => {
    const bodyContent = (
      <div
        className={styles.body}
        onDoubleClick={() => {
          startRenaming();
        }}
      >
        {isUnsaved && <div className={styles.unsavedIcon} />}

        <div className={styles.title}>{title}</div>

        <div className={styles.gradientBoundaryRight} />

        <div className={styles.iconWrapper}>
          {!isLoadingScript && (
            <div
              className={styles.menuIcon}
              onClick={e => {
                e.stopPropagation();
                setMenuOpen(true);
              }}
              data-testid="doc-tab-menu-icon"
            >
              <MenuIconHoriz />
            </div>
          )}

          <div
            className={styles.closeIcon}
            title={
              isMac
                ? t("script_tab.close_tab_mac")
                : t("script_tab.close_tab_win")
            }
            onClick={e => {
              e.stopPropagation();
              const closeAllOther = e.getModifierState("Alt");
              onClose(closeAllOther);
            }}
            data-testid={`doc-tab-close-${title}`}
          >
            <TabCloseIcon />
          </div>
        </div>
      </div>
    );

    if (isActive) {
      return bodyContent;
    }

    return (
      <HintTooltip
        title={title || ""}
        content={content?.substring(0, 500) || ""}
      >
        {bodyContent}
      </HintTooltip>
    );
  };

  return (
    <div
      className={cn(styles.root, {
        [styles.active]: isActive,
        [styles.menuOpened]: menuOpen,
      })}
      onClick={onSelect}
      ref={anchorEl}
    >
      {isRenaming ? renderRenamingBody() : renderBody()}

      <div
        className={cn(styles.statusLine, {
          [styles.success]: status === TabStatus.Success,
          [styles.error]: status === TabStatus.Error,
          [styles.running]: status === TabStatus.Running,
        })}
      />

      {menuOpen && (
        <Popover
          open={menuOpen}
          anchorEl={anchorEl.current}
          onClose={() => {
            setMenuOpen(false);
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div ref={popoverRef}>
            <ContextMenu>
              {isActive && (
                <ContextMenuItem
                  key="format"
                  text={t("script_tab.menu.format_script")}
                  testId="format-script-item"
                  onClick={e => {
                    e.stopPropagation();
                    setMenuOpen(false);

                    setTimeout(() => {
                      formatText();
                    }, 0);
                  }}
                  secondaryText={getShortcutHint(
                    SHORTCUTS[ShortcutAction.ScriptFormat]
                  ).join(" + ")}
                />
              )}

              <ContextMenuItem
                key="rename"
                text={t("script_tab.menu.rename_script")}
                testId="rename-script-item"
                onClick={e => {
                  e.stopPropagation();
                  setMenuOpen(false);

                  setTimeout(() => {
                    startRenaming();
                  }, 0);
                }}
              />

              <ContextMenuDivider key="divider-1" />
              <NestedContextMenuItem
                key="templates"
                text={t("script_tab.menu.insert_sql_template")}
                testId="insert-template-item"
                anchorElement={popoverRef}
                renderContent={({ onContentClose }) => {
                  return (
                    <ContextMenu>
                      <ContextMenuItem
                        key="query_history"
                        text={t("script_tab.menu.templates.query_history")}
                        testId="create-query-history"
                        onClick={handleTemplateClick(
                          SCRIPT_ACTIONS.QUERY_HISTORY,
                          onContentClose
                        )}
                      />
                      <ContextMenuItem
                        key="import_data"
                        text={t("script_tab.menu.templates.import_data")}
                        testId="import-data"
                        onClick={handleTemplateClick(
                          SCRIPT_ACTIONS.IMPORT,
                          onContentClose
                        )}
                      />
                      <ContextMenuItem
                        key="fact"
                        text={t("script_tab.menu.templates.new_fact_table")}
                        testId="new-fact-table"
                        onClick={handleTemplateClick(
                          SCRIPT_ACTIONS.NEW_FACT_TABLE,
                          onContentClose
                        )}
                      />
                    </ContextMenu>
                  );
                }}
              />
              <ContextMenuDivider key="divider-2" />

              <ContextMenuItem
                key="delete"
                text={t("script_tab.menu.delete_script")}
                secondaryText={getShortcutHint(
                  SHORTCUTS[ShortcutAction.ScriptsClose]
                ).join(" + ")}
                testId="delete-script-item"
                onClick={e => {
                  e.stopPropagation();
                  setMenuOpen(false);
                  onClose(false);
                }}
              />
            </ContextMenu>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default Tab;
