import { zodResolver } from "@hookform/resolvers/zod";
import _omit from "lodash/omit";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import z from "zod";

import { useEnableEditions } from "featureFlags/hooks/useEnableEditions";
import { useEngineClusterFlag } from "featureFlags/hooks/useEngineClusterFlag";
import { useOrganization } from "hooks/useOrganization";
import { CreateEngineInput } from "services/engines/createWorkspaceEngine";
import { EditEngineInput } from "services/engines/editWorkspaceEngine";

import { Cost } from "components/CreateEngine/Cost";
import {
  CreateEngine,
  Fields,
  getEngineCost,
  initialEngine,
  schema,
} from "components/CreateEngine/CreateEngine";
import { InputDisabledContext } from "components/InputState/InputDisabledContext";
import { Modal } from "components/Modal/Modal";

type Props = {
  onClose: () => void;
  onSubmit: (engine: EditEngineInput | CreateEngineInput) => void;
  isLoading: boolean;
  title: string;
  primaryButton: string;
  engine?: EditEngineInput;
};

export const EngineModal = (props: Props) => {
  const { title, primaryButton, onClose, onSubmit, isLoading } = props;

  const { t } = useTranslation();

  const { data: organization } = useOrganization();
  const enableEditions = useEnableEditions();
  const refinedSchema = schema.extend({
    clusters:
      enableEditions && organization.edition === "standard"
        ? z.number().max(1, t("validation.engine.clusters_standard")).default(1)
        : z.number().max(10).default(1),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    watch,
  } = useForm<Fields>({
    resolver: zodResolver(refinedSchema),
    defaultValues: props.engine ?? initialEngine,
  });

  const onFormSubmit = (data: Fields) => {
    if (isLoading) {
      return;
    }
    const filteredData = enableEditions ? data : _omit(data, "family");
    onSubmit(filteredData);
  };

  const showEngineClusterFlag = useEngineClusterFlag();
  const clusters = showEngineClusterFlag ? Number(watch("clusters")) : 1;

  const familyType = watch("family");
  const engineCost = getEngineCost(familyType);

  const cost = clusters * Number(watch("nodes")) * engineCost[watch("type")];

  return (
    <InputDisabledContext.Provider value={isLoading}>
      <Modal
        title={title}
        onSubmit={handleSubmit(onFormSubmit)}
        onClose={onClose}
        isLoading={isLoading}
        primaryButton={t(primaryButton)}
        disabledSubmit={!isDirty || isLoading}
        footer={<Cost cost={cost} />}
      >
        <CreateEngine
          engine={props.engine}
          register={register}
          errors={errors}
          control={control}
          watch={watch}
        />
      </Modal>
    </InputDisabledContext.Provider>
  );
};
