import { TFunction } from "i18next";
import { useMemo } from "react";

import { UTIL_METRICS } from "../constants";
import { UtilMetrics } from "../types";

export const useDataKeys = ({
  t,
  setSelectedDataKeys,
}: {
  t: TFunction<"translation", undefined>;
  setSelectedDataKeys: React.Dispatch<React.SetStateAction<UtilMetrics[]>>;
}) => {
  const keyNames = useMemo(() => {
    return Object.keys(UtilMetrics).reduce(
      (acc, key) => {
        acc[key as UtilMetrics] = t(UTIL_METRICS[key as UtilMetrics]);

        return acc;
      },
      {} as Record<UtilMetrics, string>
    );
  }, [t]);

  const handleToggleDataKey = (metric: UtilMetrics) => {
    setSelectedDataKeys(prev => {
      if (prev.includes(metric)) {
        const res = prev.filter(m => m !== metric);

        return res;
      }

      return [...prev, metric];
    });
  };

  return { keyNames, handleToggleDataKey };
};
