import _orderBy from "lodash/orderBy";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Role } from "services/rbac/types";
import { useUsers } from "services/users/useUsers";
import { User } from "services/users/user.types";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { Search } from "components/LeftSidebar/Search";
import { Step } from "components/Wizard/Step/Step";

import { RolesDataType } from "../types";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  onPrevStep: () => void;
  onFinished: (rolesData: RolesDataType, selectedUsers: string[]) => void;
  activeStepIndex: number;
  rolesData: RolesDataType;
  isLoading?: boolean;
  role?: Role;
};

export const AssignStep = (props: Props) => {
  const {
    onClose,
    onFinished,
    activeStepIndex,
    onPrevStep,
    rolesData,
    isLoading,
    role,
  } = props;
  const { t } = useTranslation();

  const users = useUsers();

  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    role ? role.grantees : []
  );

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const filteredUsers = _orderBy(
    users.filter(({ userName }) =>
      userName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    ),
    ({ userName }) => userName.toLocaleLowerCase()
  );

  const handleSelectUser = (user: User) => {
    const items = selectedUsers.includes(user.userName)
      ? selectedUsers.filter(u => u !== user.userName)
      : [...selectedUsers, user.userName];
    setSelectedUsers(items);
  };

  const body = (
    <div className={styles.wrapper}>
      <Search
        value={search}
        onChange={handleChangeSearch}
        placeholder={t("roles_wizard.assign.search_placeholder")}
        testId="roles-wizard-search-users"
        className={styles.search}
      />

      <ContextMenu>
        <ContextMenuItemsGroup
          isGroup
          maxHeight={300}
        >
          {filteredUsers.length ? (
            filteredUsers.map(user => {
              return (
                <ContextMenuItem
                  key={user.userName}
                  value={user.userName}
                  text={user.userName}
                  direction="left"
                  checked={selectedUsers.includes(user.userName)}
                  onClick={() => {
                    handleSelectUser(user);
                  }}
                  disabled={isLoading}
                  testId={`roles-wizard-grantee-${user.userName}`}
                />
              );
            })
          ) : (
            <div className={styles.noResults}>
              <Trans
                i18nKey={
                  search ? "select.no_items_found_for" : "select.no_items_found"
                }
                values={{ search }}
                components={{ bold: <strong /> }}
              />
            </div>
          )}
        </ContextMenuItemsGroup>
      </ContextMenu>
    </div>
  );

  const onFormSubmit = async () => {
    if (isLoading) {
      return;
    }

    onFinished(rolesData, selectedUsers);
  };

  return (
    <Step
      title={t("roles_wizard.assign.title")}
      subtitle={
        role
          ? t("roles_wizard.assign.subtitle_edit")
          : t("roles_wizard.assign.subtitle")
      }
      mainActionTitle={
        role ? t("roles_wizard.assign.save") : t("roles_wizard.assign.create")
      }
      body={body}
      onClose={onClose}
      onSubmit={onFormSubmit}
      isLoading={isLoading}
      activeStepIndex={activeStepIndex}
      onPrevStep={onPrevStep}
      disabledSubmit={isLoading}
      titleTestId="roles-wizard-assign-user-title"
    />
  );
};
