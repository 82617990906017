import { View } from "services/databases/getViews";
import { Database } from "services/databases/useDatabasesNames";
import { Table } from "services/databases/useTableNames";
import { WorkspaceEngine } from "services/engines/engine.types";
import { Role } from "services/rbac/types";
import { User } from "services/users/user.types";

import {
  OwnershipObjectType,
  OwnershipRecord,
} from "components/AccountOwnership/types";

export const DEFAULT_ACCOUNT_OWNERSHIP_FILTERS = {
  nameContains: "",
  objectTypes: [],
  owners: [],
};

export const MAP_TO_OWNERSHIP_RECORD = {
  [OwnershipObjectType.database]: (database: Database): OwnershipRecord => ({
    key: [database.catalogName, OwnershipObjectType.database].join(","),
    type: OwnershipObjectType.database,
    name: database.catalogName,
    owner: database.catalogOwner,
    created: database.created,
    lastModified: database.lastAltered,
  }),
  [OwnershipObjectType.table]: (
    table: Table,
    databaseName: string
  ): OwnershipRecord => ({
    key: [table.tableName, databaseName, OwnershipObjectType.table].join(","),
    type: OwnershipObjectType.table,
    parentName: databaseName,
    name: table.tableName,
    owner: table.tableOwner,
    created: table.created,
    lastModified: table.lastAltered,
  }),
  [OwnershipObjectType.engine]: (engine: WorkspaceEngine): OwnershipRecord => {
    return {
      key: [engine.engineName, OwnershipObjectType.engine].join(","),
      type: OwnershipObjectType.engine,
      name: engine.engineName,
      owner: engine.engineOwner || "",
      created: engine.created,
      lastModified: engine.lastAltered,
    };
  },
  [OwnershipObjectType.view]: (
    view: View,
    databaseName: string
  ): OwnershipRecord => {
    return {
      key: [view.tableName, databaseName, OwnershipObjectType.view].join(","),
      type: OwnershipObjectType.view,
      parentName: databaseName,
      name: view.tableName,
      owner: view.viewOwner,
      created: view.created,
      lastModified: view.lastAltered,
    };
  },
  [OwnershipObjectType.role]: (role: Role): OwnershipRecord => {
    return {
      key: [role.roleName, OwnershipObjectType.role].join(","),
      type: OwnershipObjectType.role,
      name: role.roleName,
      owner: role.roleOwner,
      created: role.created,
      lastModified: "",
    };
  },
  [OwnershipObjectType.user]: (user: User): OwnershipRecord => {
    return {
      key: [user.userName, OwnershipObjectType.user].join(","),
      type: OwnershipObjectType.user,
      name: user.userName,
      owner: user.userOwner,
      created: user.created,
      lastModified: user.lastAltered,
    };
  },
};

export const NO_OWNER_LABEL = "NULL";
export const DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE = "_DELETE_";
