import classNames from "classnames";
import { useTranslation } from "react-i18next";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import {
  IngestionStep,
  WizardDataType,
} from "components/IngestionWizard/types";
import OutlinedSelect from "components/OutlinedSelect";
import Toggle from "components/Toggle";
import Tooltip from "components/Tooltip";

import { TypeMismatchTooltip } from "./TypeMismatchTooltip";
import { ExistingTableMapData, NULL_TYPE, NullColumn } from "./types";

import styles from "../styles.module.scss";

type RowProps = {
  row: ExistingTableMapData;
  testId: string;
  visibleColumns: string[];
  index: number;
  wizardData: WizardDataType;
  indexes: number[];
  onChangeSource: (index: number, sourceName: string) => void;
  errors: Record<string, boolean>;
};

const shouldShowColumn = (columnName: string, columns: string[]) => {
  return !columns.length || columns.includes(columnName);
};

export const Row = ({
  row,
  onChangeSource,
  visibleColumns,
  testId,
  wizardData,
  index,
  indexes,
  errors,
}: RowProps) => {
  const { t } = useTranslation();
  const previewData = wizardData[IngestionStep.formatData]?.previewData!;

  return (
    <div className={styles.row}>
      {shouldShowColumn("name", visibleColumns) && (
        <div
          className={classNames(styles.column, styles.name, styles.disabled)}
        >
          <span>{row.name}</span>
        </div>
      )}
      {shouldShowColumn("type", visibleColumns) && (
        <div
          className={classNames(styles.column, styles.type, styles.disabled)}
        >
          <span>{row.type}</span>
        </div>
      )}
      {shouldShowColumn("nullable", visibleColumns) && (
        <div
          className={classNames(
            styles.column,
            styles.nullable,
            styles.disabled
          )}
        >
          <Toggle
            checked={row.isNullable}
            onChange={() => {}}
            size="sm"
            classes={{ wrapper: styles.toggleWrapper }}
            dataTestId={`${testId}-toggle-nullable`}
            disabled
          />
        </div>
      )}
      {shouldShowColumn("primaryIndex", visibleColumns) && (
        <div
          className={classNames(
            styles.column,
            styles.nullable,
            styles.disabled
          )}
        >
          <Toggle
            checked={indexes.includes(index)}
            onChange={() => {}}
            disabled
            size="sm"
            classes={{ wrapper: styles.toggleWrapper }}
            dataTestId={`${testId}-toggle-primary-index`}
          />

          {indexes.length > 1 && indexes.indexOf(index) !== -1 && (
            <Tooltip
              arrow={false}
              enterDelay={500}
              enterNextDelay={500}
              placement="top"
              title={t("wizard.map_data.primary_index_tooltip")}
            >
              <span className={styles.order}>{indexes.indexOf(index) + 1}</span>
            </Tooltip>
          )}
        </div>
      )}

      {shouldShowColumn("source_name", visibleColumns) && (
        <div
          className={classNames(styles.column, styles.source_name, {
            [styles.column__error]: errors[row.name],
          })}
        >
          <OutlinedSelect
            testId={`${testId}-select-source-name`}
            onSelect={([value]) => onChangeSource(index, value)}
            alertTooltip={errors[row.name] ? "No matching column found" : ""}
            renderValue={([value]) => {
              if (value === NULL_TYPE) {
                return <div className={styles.column__null}>[null]</div>;
              }
              return value;
            }}
            controlledValue={row.source_name ? [row.source_name] : []}
            className={styles.sourceNameSelect}
            noBorder
          >
            {[
              {
                name: NULL_TYPE,
                text: "[null]",
                className: styles.column__null,
              },
              ...previewData.meta,
            ].map(column => {
              return (
                <ContextMenuItem
                  value={column.name}
                  key={column.name}
                  text={(column as NullColumn).text || column.name}
                  checked={row.source_name === column.name}
                  classes={{ textClassName: (column as NullColumn).className }}
                />
              );
            })}
          </OutlinedSelect>
        </div>
      )}
      {shouldShowColumn("source_type", visibleColumns) && (
        <div
          className={classNames(styles.column, styles.source_type, {
            [styles.column__error]: errors[`${row.name}_${row.source_type}`],
          })}
        >
          <span>{row.source_type?.split(" null")[0]}</span>
          {errors[`${row.name}_${row.source_type}`] && <TypeMismatchTooltip />}
        </div>
      )}
      {shouldShowColumn("dataPreview", visibleColumns) && (
        <div className={classNames(styles.column, styles.dataPreview)}>
          {row.dataPreview}
        </div>
      )}
    </div>
  );
};
