import { systemEngineEnvironment } from "services/environment/systemEngine";
import { User } from "services/users/user.types";

export const getUsers = async (
  accountId: string,
  userName?: string,
  options?: {
    byOwnerUsername?: string;
  }
): Promise<User[]> => {
  const namedParameters: {
    userName?: string;
    byOwnerUsername?: string;
  } = {};

  const whereAndClauseParts = [];

  if (userName) {
    namedParameters.userName = userName;
    whereAndClauseParts.push(`user_name = :userName`);
  }

  if (options?.byOwnerUsername) {
    namedParameters.byOwnerUsername = options.byOwnerUsername;
    whereAndClauseParts.push(`user_owner = :byOwnerUsername`);
  }

  const whereClause = whereAndClauseParts.length
    ? ` WHERE ${whereAndClauseParts.join(" AND ")} `
    : "";

  const sql =
    `
SELECT
  u.user_name,
  u.login_name,
  u.service_account_name,
  u.default_database,
  u.default_engine,
  u.created,
  u.user_owner,
  u.last_altered,
  u.last_altered_by,
  ARRAY_DISTINCT(filter(x -> x <> '', ARRAY_AGG(ar.role_name))) as roles
FROM
  information_schema.users u
LEFT JOIN
  information_schema.applicable_roles ar
ON
  u.user_name = ar.grantee
` +
    whereClause +
    ` GROUP BY ALL ORDER BY u.user_name ASC`;

  systemEngineEnvironment.setAccountContext(accountId);
  const result = await systemEngineEnvironment.execute<User>(sql, {
    namedParameters,
  });
  const [response] = result;
  return response.rows.map(user => ({
    ...user,
    roles: user.roles || [],
  }));
};
