import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import usePersistedState from "hooks/usePersistedState";
import { authService } from "services/auth";
import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
} from "services/engines/engine.types";
import { ReactQueryKeysAccount } from "services/queryKeys";

import DocumentOutputRefreshIcon from "assets/icons/DocumentOutputRefreshIcon.svg?react";

import ErrorMessage from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ErrorMessage/ErrorMessage";
import { Datagrid } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/Table/Datagrid";
import styles from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/QueryHistory/QueryHistory.module.scss";
import { generateQueryHistorySQL } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/QueryHistory/constants";
import EngineFallbackMessage from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/EngineFallbackMessage/EngineFallbackMessage";
import ExportMenu from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/ExportMenu/ExportMenu";
import NoQueryHistoryData from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/NoQueryHistoryData/NoQueryHistoryData";
import OutputSubPanel from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/OutputSubPanel/OutputSubPanel";
import { FIREBOLT_UI_USER_ENGINE_QUERY_LABEL } from "pages/DevelopWorkspace/services/constants";
import executeQueryStatement from "pages/DevelopWorkspace/services/helpers/executeQueryStatement";
import { SYSTEM_ENGINE } from "pages/DevelopWorkspace/workspace.constants";
import {
  QueryStatement,
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import LoadingOverlap from "components/LoadingOverlap";
import Toggle from "components/Toggle";
import Tooltip from "components/Tooltip/Tooltip";

interface QueryHistoryProps {
  engine: WorkspaceEngine;
  document: WorkspaceDocument;
}

const HIDDEN_COLUMNS_BY_DEFAULT: string[] = [
  "scanned_bytes_cache",
  "scanned_bytes_storage",
  "inserted_rows",
  "inserted_bytes",
  "inserted_bytes_storage",
  "total_ram_consumed",
  "cpu_usage_us",
  "cpu_delay_us",
  "time_in_queue_ms",
  "retries",
];

const QUERY_HISTORY_ALLOWED_ENGINE_STATUSES = [
  WorkspaceEngineStatus.RUNNING,
  WorkspaceEngineStatus.DRAINING,
  WorkspaceEngineStatus.RESIZING,
];

const QueryHistory = (props: QueryHistoryProps) => {
  const { engine, document } = props;

  const { t } = useTranslation();

  const [hideSystemQueries, setHideSystemQueries] = usePersistedState<boolean>(
    "HIDE_QUERY_HISTORY_SYSTEM_QUERIES",
    true
  );

  const showFallback =
    !QUERY_HISTORY_ALLOWED_ENGINE_STATUSES.includes(engine.status) ||
    engine.engineName === SYSTEM_ENGINE.engineName;

  const { data, isFetching, isLoading, error, refetch } = useQuery({
    queryKey: [
      ReactQueryKeysAccount.queryHistory,
      engine.engineName,
      engine.lastStarted,
      hideSystemQueries,
    ],
    queryFn: async () => {
      if (!engine) {
        throw new Error("Engine not found");
      }

      if (!QUERY_HISTORY_ALLOWED_ENGINE_STATUSES.includes(engine.status)) {
        throw new Error("Engine not running");
      }

      const abortController = new AbortController();

      const fetchOptions = {
        abortController,
        headers: {
          "Firebolt-Machine-Query": "1",
        },
      };

      const queryStatement: QueryStatement = {
        content: generateQueryHistorySQL(hideSystemQueries),
        status: QueryStatementStatus.pending,
        id: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
        responseStatusCode: null,
        error: null,
        statistics: null,
        sourceDocLineNumber: 0,
      };

      const doc: WorkspaceDocument = {
        ...document,
        context: {
          ...document.context,
          settings: {
            auto_start_stop_control: "ignore",
            query_label: FIREBOLT_UI_USER_ENGINE_QUERY_LABEL,
          },
        },
      };

      const response = await executeQueryStatement(
        queryStatement,
        doc,
        engine,
        fetchOptions,
        authService
      );

      return response.responseBody;
    },
    refetchOnMount: true,
    retry: false,
    enabled: !showFallback,
  });

  const { result, meta } = useMemo(() => {
    if (!data || !data.data || !data.meta) {
      return {
        result: undefined,
        meta: undefined,
      };
    }

    const result = data.data;
    const meta = data.meta.map((column: any) => {
      return {
        ...column,
        displayName: column.name,
      };
    });

    return {
      result,
      meta,
    };
  }, [data]);

  const renderTable = () => {
    if (error) {
      return null;
    }

    if (!result || !meta) {
      return null;
    }

    if (result && meta && !result.length) {
      return <NoQueryHistoryData />;
    }

    return (
      <Datagrid
        data={result}
        fields={meta}
        hiddenColumnsByDefault={HIDDEN_COLUMNS_BY_DEFAULT}
      />
    );
  };

  const renderError = () => {
    if (error) {
      return <ErrorMessage errorMessage={(error as any).message as string} />;
    }
  };

  if (showFallback) {
    return (
      <EngineFallbackMessage
        engine={engine}
        message={
          <>
            <Trans
              i18nKey="query_history.fallback.not_running"
              components={{
                div: <div />,
                strong: <strong />,
              }}
            />
          </>
        }
        noPermissionMessage={
          <>
            <Trans
              i18nKey="query_history.fallback.no_permission_to_start"
              components={{
                div: <div />,
                strong: <strong />,
              }}
            />
          </>
        }
        messageSystemEngine={
          <>
            <Trans
              i18nKey="query_history.fallback.system_engine"
              components={{
                div: <div />,
                strong: <strong />,
              }}
            />
          </>
        }
      />
    );
  }

  const hideElementsSwitch = (
    <div className={styles.dropdownElement}>
      <span className={styles.text}>
        {t("query_history.hide_system_queries")}
      </span>
      <Toggle
        dataTestId="toggle-qh-system-queries"
        checked={hideSystemQueries}
        onChange={() => setHideSystemQueries(!hideSystemQueries)}
      />
    </div>
  );

  return (
    <>
      <OutputSubPanel
        body={<div />}
        controls={
          <div className={styles.controls}>
            <Tooltip
              enterDelay={300}
              title={t("query_history.refresh")}
              placement="top-start"
            >
              <div
                onClick={() => {
                  if (!isFetching) {
                    refetch();
                  }
                }}
                className={cn(styles.refreshIcon, {
                  [styles.disabled]: isFetching,
                })}
              >
                <DocumentOutputRefreshIcon />
              </div>
            </Tooltip>

            <div id="results-column-filter-icon" />

            {!error && !!result?.length && (
              <>
                <ExportMenu
                  prefixElement={hideElementsSwitch}
                  exportData={{
                    rows: data?.data || [],
                  }}
                />
              </>
            )}
          </div>
        }
      />

      <div
        className={styles.body}
        data-testid="query-history-content"
      >
        {renderError()}

        {renderTable()}
        {isLoading && <LoadingOverlap isLoading={true} />}
      </div>
    </>
  );
};

export default QueryHistory;
