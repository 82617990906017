import { Brand } from "ts-brand";

import {
  QueryStatement,
  QueryStatementStatus,
} from "pages/DevelopWorkspace/workspace.types";

export type AutoSizerRenderProps = {
  width: number | undefined;
  height: number | undefined;
};

export type UserFacingQueryStatementStatus = Brand<
  QueryStatementStatus,
  "UserFacingQueryStatementStatus"
>;

export const getUserFacingQueryStatementStatus = (
  queryStatement: QueryStatement
): UserFacingQueryStatementStatus => {
  if (
    queryStatement.status === QueryStatementStatus.unknown &&
    queryStatement.prevStatus === QueryStatementStatus.running
  ) {
    // if transitioning from running to unknown, show running
    return QueryStatementStatus.running as UserFacingQueryStatementStatus;
  }

  return queryStatement.status as UserFacingQueryStatementStatus;
};
