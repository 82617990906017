import { useSuspenseQuery } from "@tanstack/react-query";

import { ReactQueryKeysAccount } from "services/queryKeys";

import { getDatabaseTables } from "./getDatabaseTables";

export type Table = {
  tableName: string;
  tableType: string;
  tableOwner: string;
  created: string;
  lastAltered: string;
};

export const useTableNames = ({ database }: { database: string }): Table[] => {
  const { data } = useSuspenseQuery<Table[], Error>({
    queryKey: [ReactQueryKeysAccount.tableNames, database],
    queryFn: async () => {
      const tables = await getDatabaseTables(database);
      return tables;
    },
  });
  return data || [];
};
