import classNames from "classnames";

import CollapseIcon from "assets/icons/workspace/Header/CollapseSidebar.svg?react";

import { useLayoutToggles } from "components/LayoutToggles/context";

import ArrowLeft from "./Arrow.svg?react";
import { OrderBy, OrderDirection } from "./types";

import styles from "./styles.module.scss";

type Props = {
  order: {
    orderDirection: OrderDirection;
    orderBy: OrderBy;
  };
  onChangeOrder: (order: { orderBy: OrderBy }) => void;
};

export const ExpandedHeader = (props: Props) => {
  const { order, onChangeOrder } = props;
  const { layout, setLayout } = useLayoutToggles();
  const handleToggleExpand = () => {
    setLayout({
      ...layout,
      engines: {
        ...layout.engines,
        expanded: false,
      },
    });
  };

  const headerItems = [
    {
      title: "Engines",
      className: styles.header__engines,
      orderParams: {
        orderBy: OrderBy.name,
      },
    },
    {
      title: "Status",
      className: styles.header__status,
      orderParams: {
        orderBy: OrderBy.status,
      },
    },
    {
      title: "Type",
      orderParams: {
        orderBy: OrderBy.type,
      },
    },
    {
      title: "Nodes",
      orderParams: {
        orderBy: OrderBy.nodes,
      },
    },
    {
      title: "Clusters",
      orderParams: {
        orderBy: OrderBy.clusters,
      },
    },
  ];

  return (
    <div className={styles.header}>
      {headerItems.map(item => {
        const { title, className, orderParams } = item;
        const isActive = order.orderBy === orderParams.orderBy;
        return (
          <div
            key={title}
            className={classNames(styles.header__title, className)}
            onClick={() => {
              onChangeOrder(orderParams);
            }}
          >
            {title}
            <ArrowLeft
              className={classNames(styles.arrow, {
                [styles.arrow__active]: isActive,
                [styles.arrow__desc]:
                  isActive && order.orderDirection === OrderDirection.DESC,
              })}
            />
          </div>
        );
      })}
      <button
        className={styles.expand}
        onClick={handleToggleExpand}
        data-testid="collapse-engines-explorer-btn"
      >
        <CollapseIcon className={styles.expand__icon} />
      </button>
    </div>
  );
};
