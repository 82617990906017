import { EngineMonitoringTimeWindow } from "../../../Editor/Document/DocumentOutput/EngineMonitoring/constants";
import { ScriptType, WorkspaceDocument } from "../../../workspace.types";
import { DEFAULT_EXECUTION_CONTEXT, DEFAULT_LAYOUT } from "../DocumentsContext";

// this is a dummy document to use and dispose of for executeQueryStatement, like executing a query from the data loader
// only a couple of props matter, like context. The rest are to please TS, and avoid read errors
// it should never be saved locally, hence the name disposable
export const createDisposableDocument = (
  options?: Partial<WorkspaceDocument>
): WorkspaceDocument => {
  return {
    context: JSON.parse(JSON.stringify(DEFAULT_EXECUTION_CONTEXT)),
    id: "",
    script: {
      id: "",
      type: ScriptType.local,
    },
    selection: [0, 0],
    layout: { ...DEFAULT_LAYOUT },
    execution: null,
    engineMonitoring: {
      timeWindow: EngineMonitoringTimeWindow.FiveMinutes,
      utilChart: {
        highlightedKeys: [],
      },
      queriesChart: {
        highlightedKeys: [],
      },
    },
    createdAt: Date.now(),
    ...options,
  };
};
