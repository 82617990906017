import { AxiosError } from "axios";

import { Account, MyAccount } from "services/account/account.types";
import { AccountService } from "services/api/account";
import { systemEngineEnvironment } from "services/environment/systemEngine";

import { NotAMemberError } from "components/ErrorBoundary/NotAMember";

export const getAccounts = async (name?: string): Promise<Account[]> => {
  const sql =
    "select * from information_schema.accounts" +
    (name ? ` where account_name = :name` : "");

  const result = await systemEngineEnvironment.execute<Account>(sql, {
    namedParameters: { name: name?.toLowerCase() },
  });
  const [response] = result;
  return response.rows;
};

export const getMyAccounts = async (): Promise<MyAccount[]> => {
  try {
    const accounts = await AccountService.myAccounts();

    if (accounts.length === 0) {
      throw new NotAMemberError();
    }

    return accounts.map(({ name, ...account }) => ({
      ...account,
      accountName: name,
    }));
  } catch (error: unknown) {
    if (error instanceof AxiosError && error?.response?.status === 404) {
      throw new NotAMemberError();
    }
    throw error;
  }
};
