import * as Sentry from "@sentry/react";
import "flat-map-polyfill";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom/client";

import "i18n/config";
import { authService } from "services/auth";

import App from "./components/App";
import ErrorBoundary, { AppCrash } from "./components/ErrorBoundary";
import { attachTestIdBreadcrumb } from "./utils/sentry-utils";

import "styles/fonts.scss";
import "styles/reset.scss";

try {
  if (
    ["production", "staging"].includes(
      process.env.REACT_APP_NODE_ENV as string
    ) &&
    process.env.REACT_APP_SENTRY_DSN
  ) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [Sentry.replayIntegration()],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      attachStacktrace: true,
      environment: new URL(process.env.REACT_APP_API_ENDPOINT_URL as string)
        .host,
      beforeBreadcrumb(breadcrumb, hint) {
        switch (breadcrumb.category) {
          case "ui.click":
            return attachTestIdBreadcrumb(breadcrumb, hint);
          default:
            return breadcrumb;
        }
      },
      ignoreErrors: [
        "top.GLOBALS",
        "LaunchDarklyFlagFetchError",
        "ChunkLoadError",
        "Loading CSS chunk",
      ],
      denyUrls: [
        /\/(gtm|ga|analytics)\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
      ],
    });
  }
} catch (e: any) {
  console.error("Failed to initialize sentry: ", e?.message);
}

const onFinishRender = () => {
  const skeleton = document.getElementById("initial-skeleton");
  if (skeleton) {
    skeleton.remove();
  }
};

const initApp = async () => {
  let LDProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <>{children}</>
  );

  await authService.initialize();

  if (process.env.REACT_APP_LD_CLIENT_SIDE_ID) {
    LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID as string,
      user: {
        key: process.env.REACT_APP_LD_GLOBAL_KEY as string,
        name: process.env.REACT_APP_LD_GLOBAL_NAME as string,
      },
    });
  }

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <ErrorBoundary onCaught={onFinishRender}>
      <LDProvider>
        <App onFinishRender={onFinishRender} />
      </LDProvider>
    </ErrorBoundary>
  );
};

const wrapAppInErrorBoundary = async () => {
  try {
    await initApp();
  } catch (e: any) {
    console.error("Failed to initialize the app: ", e?.message);
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <AppCrash onFinishRender={onFinishRender} />
    );
  }
};

wrapAppInErrorBoundary();
