import _orderBy from "lodash/orderBy";

import { privateApi } from "../private";

const MY_ACCOUNTS = "/myAccounts";

export type AccountInfo = {
  id: string;
  name: string;
  region: string;
};

type MyAccountsResponse = {
  accounts: AccountInfo[];
};

const myAccounts = async () => {
  const response = await privateApi.get<MyAccountsResponse>(MY_ACCOUNTS);
  const { accounts } = response.data;

  return _orderBy(accounts, ["name"], ["asc"]);
};

export const AccountService = {
  myAccounts,
};
