import { useTranslation } from "react-i18next";

import { WorkspaceEngine } from "services/engines/engine.types";

import { nodeTypeOptions } from "components/CreateEngine/constants";

import styles from "./EngineItemContent.module.scss";

type Props = {
  engine: WorkspaceEngine;
};

export const EngineItemContent = (props: Props) => {
  const { engine } = props;
  const { t } = useTranslation();
  const option = nodeTypeOptions.find(option => option.value === engine.type);
  return (
    <div className={styles.content}>
      <div className={styles.option}>{t(option?.name || "")}</div>
      <div className={styles.divider} />
      <div className={styles.option}>
        {engine.clusters}&nbsp;
        {engine.clusters > 1
          ? t("workspace.context_selector.clusters_plural")
          : t("workspace.context_selector.cluster")}
      </div>
      <div className={styles.divider} />
      <div className={styles.option}>
        {engine.nodes}&nbsp;
        {engine.nodes > 1
          ? t("workspace.context_selector.nodes_plural")
          : t("workspace.context_selector.node")}
      </div>
    </div>
  );
};
