import { HexColor } from "utils/helpers/getColorsFromRange";
import { v4 as uuidv4 } from "uuid";

import {
  IntervalWorkerInputMessage,
  IntervalWorkerOutputMessage,
} from "workers/intervalWorker.worker";
import IntervalWorker from "workers/intervalWorker.worker?worker";

const defs = `
<defs>
  <linearGradient id="paint0_linear" x1="8.83203" y1="3.66699" x2="8.83203" y2="12.667" gradientUnits="userSpaceOnUse">
  <stop stop-color="white"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </linearGradient>
</defs>
`;

const faviconWithDot = (color: string, dot: boolean = false) => {
  const circle = dot
    ? `<circle cx="13.5" cy="13.5" r="2" stroke="${color}"/>`
    : `<circle cx="13.5" cy="13.5" r="2.5" fill="${color}"></circle>`;

  return `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    ${circle}
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 7.33333H0.166667L3.70526 16H8C8.93278 16 9.82827 15.8404 10.6606 15.5469C10.245 14.9713 10 14.2643 10 13.5C10 11.567 11.567 10 13.5 10C14.2643 10 14.9713 10.245 15.5469 10.6606C15.8404 9.82827 16 8.93278 16 8C16 3.58172 12.4183 0 8 0H0L3 7.33333Z" fill="#F72A30"/>
  <path d="M8.33503 5.75957V7.24108C8.33503 7.29224 8.37998 7.33366 8.43493 7.33366H10.5655C10.6204 7.33366 10.6654 7.37508 10.6654 7.42624V9.24108C10.6654 9.29224 10.6204 9.33366 10.5655 9.33366H8.43368C8.37873 9.33366 8.33378 9.37508 8.33378 9.42624V12.5744C8.33378 12.6256 8.28882 12.667 8.23388 12.667H6.43193C6.37699 12.667 6.33203 12.6256 6.33203 12.5744V3.75957C6.33203 3.70841 6.37699 3.66699 6.43193 3.66699H11.2321C11.2871 3.66699 11.332 3.70841 11.332 3.75957V5.57441C11.332 5.62558 11.2871 5.66699 11.2321 5.66699H8.43493C8.37873 5.66699 8.33503 5.70841 8.33503 5.75957Z" fill="white"/>
  <path d="M8.33503 5.75957V7.24108C8.33503 7.29224 8.37998 7.33366 8.43493 7.33366H10.5655C10.6204 7.33366 10.6654 7.37508 10.6654 7.42624V9.24108C10.6654 9.29224 10.6204 9.33366 10.5655 9.33366H8.43368C8.37873 9.33366 8.33378 9.37508 8.33378 9.42624V12.5744C8.33378 12.6256 8.28882 12.667 8.23388 12.667H6.43193C6.37699 12.667 6.33203 12.6256 6.33203 12.5744V3.75957C6.33203 3.70841 6.37699 3.66699 6.43193 3.66699H11.2321C11.2871 3.66699 11.332 3.70841 11.332 3.75957V5.57441C11.332 5.62558 11.2871 5.66699 11.2321 5.66699H8.43493C8.37873 5.66699 8.33503 5.70841 8.33503 5.75957Z" fill="url(#paint0_linear)"/>
  ${defs}
</svg>
`;
};

const defaultFavicon = `
<svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 58L24 58.0315L1 1C1 1 42.3364 1 64 1C98.7939 1 127 29.2061 127 64C127 98.7939 98.7939 127 64 127C57.2863 127 30.1789 127 30.1789 127L3 58Z" fill="#F72A30"/>
<path d="M65.024 43.7364V57.2594C65.024 57.6687 65.3836 58 65.8232 58H84.1879C84.6275 58 84.9871 58.3313 84.9871 58.7406V72.2837C84.9871 72.693 84.6275 73.0244 84.1879 73.0244H65.8132C65.3736 73.0244 65.014 73.3557 65.014 73.765V102.259C65.014 102.669 64.6543 103 64.2148 103H49.7992C49.3596 103 49 102.669 49 102.259V28.7406C49 28.3313 49.3596 28 49.7992 28H88.2008C88.6404 28 89 28.3313 89 28.7406V42.2551C89 42.6644 88.6404 42.9957 88.2008 42.9957H65.8232C65.3736 42.9957 65.024 43.3271 65.024 43.7364Z" fill="white"/>
<path d="M65.024 43.7364V57.2594C65.024 57.6687 65.3836 58 65.8232 58H84.1879C84.6275 58 84.9871 58.3313 84.9871 58.7406V72.2837C84.9871 72.693 84.6275 73.0244 84.1879 73.0244H65.8132C65.3736 73.0244 65.014 73.3557 65.014 73.765V102.259C65.014 102.669 64.6543 103 64.2148 103H49.7992C49.3596 103 49 102.669 49 102.259V28.7406C49 28.3313 49.3596 28 49.7992 28H88.2008C88.6404 28 89 28.3313 89 28.7406V42.2551C89 42.6644 88.6404 42.9957 88.2008 42.9957H65.8232C65.3736 42.9957 65.024 43.3271 65.024 43.7364Z" fill="url(#paint0_linear)"/>
${defs}
</svg>
`;

const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;

const encodeSVG = (data: string) => {
  const escaped = data
    .replace(/"/g, "'")
    .replace(/>\s{1,}</g, "><")
    .replace(/\s{2,}/g, " ")
    .replace(symbols, encodeURIComponent);
  return `data:image/svg+xml;utf8,${escaped}`;
};

export const getFavicon = (color = "") => {
  return encodeSVG(faviconWithDot(color));
};

const getDefaultFavicon = () => {
  return encodeSVG(defaultFavicon);
};

const removeFavicons = () => {
  const elements = document.querySelectorAll('link[rel="icon"]');
  const icons = document.querySelectorAll('link[rel="shortcut icon"]');
  elements.forEach(element => element.parentNode?.removeChild(element));
  icons.forEach(element => element.parentNode?.removeChild(element));
};

const addFavicon = (faviconHref: string) => {
  const head = document.getElementsByTagName("head")[0];
  const link = document.createElement("link");
  link.rel = "icon";
  link.href = faviconHref;
  link.type = "image/svg+xml";
  head.appendChild(link);
};

export const updateFavicon = (favicon: string | null) => {
  if (favicon) {
    removeFavicons();
    addFavicon(favicon);
  } else {
    removeFavicons();
    addFavicon(getDefaultFavicon());
  }
};

type FaviconUpdater =
  | {
      type: "solid";
      color: string;
    }
  | {
      type: "gradient";
      colors: HexColor[];
    };

export const FAVICON_ANIMATION_FRAME_RATE = 16;
const CYCLE_DURATION = 1000;
const FRAME_INTERVAL = CYCLE_DURATION / FAVICON_ANIMATION_FRAME_RATE;

export const faviconUpdater = (() => {
  let worker: Worker | null = null;
  let intervalId: string | null;
  let lastUpdateTime = 0;
  let currentFavicon: string = "";

  const runAnimation = (colors: HexColor[]) => {
    if (!worker) {
      worker = new IntervalWorker();
    }

    intervalId = uuidv4();

    const message: IntervalWorkerInputMessage = {
      type: "startInterval",
      intervalId,
      timeout: FRAME_INTERVAL,
    };
    worker.postMessage(message);

    worker.onmessage = (event: MessageEvent<IntervalWorkerOutputMessage>) => {
      if (event.data.type === "tick" && event.data.intervalId === intervalId) {
        const now = Date.now();
        if (now - lastUpdateTime >= FRAME_INTERVAL) {
          lastUpdateTime = now;
          const index = Math.floor((now / FRAME_INTERVAL) % colors.length);
          const fav = getFavicon(colors[index]);
          updateFavicon(fav);
        }
      }
    };
  };

  const stopAnimation = () => {
    if (worker) {
      worker.postMessage({ type: "stopInterval", intervalId });
    }
    intervalId = null;
  };

  return {
    updateFavicon: (favicon: FaviconUpdater | null) => {
      if (JSON.stringify(favicon) === JSON.stringify(currentFavicon)) {
        // do nothing if the favicon is the same
        return;
      }

      currentFavicon = JSON.stringify(favicon);

      stopAnimation();

      if (favicon === null) {
        updateFavicon(null);
        return;
      }

      if (favicon.type === "solid") {
        updateFavicon(getFavicon(favicon.color));
        return;
      }

      if (favicon.type === "gradient") {
        runAnimation(favicon.colors);
      }
    },
  };
})();
