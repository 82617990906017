import { QueryClient, useQueryClient } from "@tanstack/react-query";

import { FeatureFlag } from "featureFlags/constants";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";
import { SystemDatabase } from "services/databases/utils";
import { systemEngineEnvironment } from "services/environment/systemEngine";

import { ItemDataType } from "components/Tree/types";
import { TREE_NODE_PADDING } from "components/Tree/utils";

const getColumnNodeIndent = (layer: number) => {
  const offset = (layer - 2) * TREE_NODE_PADDING;
  return offset;
};

const fetchAndMapType = async ({
  queryClient,
  queryKey,
  query,
  databaseName,
  mapRow,
}: {
  queryClient: QueryClient;
  queryKey: string[];
  query: string;
  databaseName?: string;
  mapRow: (row: any) => any;
}) => {
  const response = await queryClient.fetchQuery({
    queryKey,
    queryFn: async () => {
      try {
        const data = await systemEngineEnvironment.execute(query, {
          database: databaseName,
        });
        return data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  });

  if (!response) {
    return [];
  }

  const [data] = response;
  if (data.rows.length) {
    return data.rows.map(mapRow);
  }
  return [];
};

export const useChildren = () => {
  const queryClient = useQueryClient();
  const flags = useAllFlags();
  const accountDbInformation = flags[
    FeatureFlag.AccountDbInformationSchemaViews
  ] || { views: [] };
  const { views } = accountDbInformation;
  const getChildren = async (node: ItemDataType) => {
    const { type, name, databaseName } = node;
    switch (type) {
      case "database": {
        if (databaseName === SystemDatabase.ACCOUNT) {
          const children = views.map(view => {
            const { name, columns } = view;
            const children = columns.map(column => {
              return {
                type: "information_schema_column",
                label: column.columnName as string,
                value: `${view.name}_${column.columnName}`,
                databaseName,
                payload: column,
                children: null,
              };
            });
            return {
              type: "information_schema_table",
              label: name,
              name,
              databaseName,
              value: `${node.value}_${view.name}`,
              children,
            };
          });
          return [
            {
              type: "information_schema",
              label: "information_schema",
              value: `${databaseName}_information_schema`,
              databaseName,
              children: [
                {
                  type: "information_schema_views",
                  label: "Views",
                  databaseName,
                  value: `${databaseName}_information_schema_views`,
                  children,
                },
              ],
            },
          ];
        }
        if (databaseName === SystemDatabase.ORG) {
          return [
            {
              type: "information_schema",
              label: "information_schema",
              value: `${databaseName}_information_schema`,
              databaseName,
              children: [
                {
                  type: "information_schema_views",
                  label: "Views",
                  databaseName,
                  value: `${databaseName}_information_schema_views`,
                  children: [],
                },
              ],
            },
          ];
        }
        return fetchAndMapType({
          queryClient,
          queryKey: [`${databaseName}_schemas`],
          query: `select schema_name, catalog_name from information_schema.schemata`,
          databaseName,
          mapRow: schema => {
            if (schema.schemaName === "information_schema") {
              return {
                type: "information_schema",
                label: "information_schema",
                value: `${schema.catalogName}_information_schema`,
                databaseName,
                children: [
                  {
                    type: "information_schema_views",
                    label: "Views",
                    databaseName,
                    value: `${schema.catalogName}_information_schema_views`,
                    children: [],
                  },
                ],
              };
            }
            return {
              label: schema.schemaName,
              value: `${schema.catalogName}_${schema.schemaName}`,
              children: [
                {
                  type: "tables",
                  label: "Tables",
                  value: `${schema.catalogName}_${schema.schemaName}_tables`,
                  databaseName,
                  children: [],
                },
                {
                  type: "external_tables",
                  label: "External tables",
                  databaseName,
                  value: `${schema.catalogName}_${schema.schemaName}_external`,
                  children: [],
                },
                {
                  type: "views",
                  label: "Views",
                  databaseName,
                  value: `${schema.catalogName}_${schema.schemaName}_views`,
                  children: [],
                },
              ],
            };
          },
        });
      }
      case "information_schema_views": {
        return fetchAndMapType({
          queryClient,
          queryKey: [`${databaseName}_information_schema`],
          query: `select table_name from information_schema.tables where table_schema = 'information_schema' and table_catalog = '${databaseName}' order by table_name;`,
          databaseName,
          mapRow: table => {
            const { tableName } = table;
            return {
              type: "information_schema_table",
              label: tableName as string,
              name: tableName,
              databaseName,
              value: `${node.value}_${tableName}`,
              children: [],
            };
          },
        });
      }
      case "information_schema_table": {
        const { name } = node;
        return fetchAndMapType({
          queryClient,
          queryKey: [`information_schema_${name}`],
          query: `select column_name, table_schema, table_name, data_type from information_schema.columns where table_schema = 'information_schema' and table_name = '${name}' and table_catalog = '${databaseName}'`,
          databaseName,
          mapRow: table => {
            const { columnName } = table;
            return {
              type: "information_schema_column",
              label: columnName as string,
              value: `${node.value}_${columnName}`,
              databaseName,
              payload: table,
              children: null,
            };
          },
        });
      }
      case "tables": {
        return fetchAndMapType({
          queryClient,
          queryKey: [`${databaseName}_tables`],
          query: `select tb.table_name, tb.table_type, tb.ddl, array_agg(cl.column_name) as columns
from information_schema.tables tb
join information_schema.columns cl on
tb.table_name = cl.table_name
where tb.table_type IN ('BASE TABLE', 'FACT', 'DIMENSION')
group by tb.table_name, cl.table_name, tb.ddl, tb.table_type
order by tb.table_name
            `,
          databaseName,
          mapRow: table => {
            const { tableName } = table;
            const value = `${node.value}_${tableName}`;
            return {
              type: "table",
              label: tableName as string,
              value,
              databaseName,
              payload: table,
              children: [
                {
                  type: "columns",
                  label: "Columns",
                  value: `${value}_columns`,
                  name: tableName as string,
                  databaseName,
                  children: [],
                },
                {
                  type: "indexes",
                  label: "Indexes",
                  value: `${value}_indexes`,
                  name: tableName as string,
                  databaseName,
                  children: [],
                },
              ],
            };
          },
        });
      }
      case "indexes": {
        return fetchAndMapType({
          queryClient,
          queryKey: [`${name}_indexes`],
          query: `select index_name, index_type, table_name, index_definition from information_schema.indexes where table_name = '${name}' order by index_name`,
          databaseName,
          mapRow: row => {
            const { indexName } = row;
            return {
              type: "index",
              label: indexName as string,
              value: `${node.value}_${indexName}`,
              children: null,
              getTreeNodeIndent: getColumnNodeIndent,
              databaseName,
              payload: row,
            };
          },
        });
      }
      case "columns": {
        return fetchAndMapType({
          queryClient,
          queryKey: [`${name}_columns`],
          query: `select column_name, table_name, table_schema, data_type from information_schema.columns where table_name = '${name}'`,
          databaseName,
          mapRow: column => {
            const { columnName } = column;
            return {
              type: "column",
              label: columnName as string,
              value: `${node.value}_${columnName}`,
              children: null,
              getTreeNodeIndent: getColumnNodeIndent,
              payload: column,
              databaseName,
            };
          },
        });
      }
      case "view": {
        const { databaseName, name } = node;
        return fetchAndMapType({
          queryClient,
          queryKey: [`${databaseName}_${name}_columns`],
          query: `SELECT column_name, table_name, table_schema, data_type FROM information_schema.columns WHERE table_name = '${name}'`,
          databaseName,
          mapRow: table => {
            const { columnName } = table;
            return {
              type: "view_column",
              label: columnName as string,
              value: `${node.value}_${columnName}`,
              children: null,
              payload: table,
              databaseName,
            };
          },
        });
      }
      case "views": {
        return fetchAndMapType({
          queryClient,
          queryKey: [`${name}_views`],
          query:
            "SELECT table_name, view_definition FROM information_schema.views WHERE table_schema = 'public' order by table_name",
          databaseName,
          mapRow: table => {
            const { tableName } = table;
            return {
              type: "view",
              label: tableName as string,
              value: `${node.value}_${tableName}`,
              name: tableName,
              children: [],
              payload: table,
              databaseName,
            };
          },
        });
      }
      case "external_table": {
        const { name } = node;
        return fetchAndMapType({
          queryClient,
          queryKey: [`${name}_columns`],
          query: `SELECT column_name, table_name, table_schema, data_type FROM information_schema.columns WHERE table_name = '${name}'`,
          databaseName,
          mapRow: column => {
            const { columnName } = column;
            return {
              type: "external_table_column",
              label: columnName as string,
              value: `${node.value}_${columnName}`,
              children: null,
              databaseName,
              payload: column,
            };
          },
        });
      }
      case "external_tables": {
        return fetchAndMapType({
          queryClient,
          queryKey: [`${name}_external_tables`],
          query: `select tb.table_name, tb.table_type, tb.ddl, array_agg(cl.column_name) as columns
from information_schema.tables tb
join information_schema.columns cl on
tb.table_name = cl.table_name
where tb.table_type = 'EXTERNAL'
group by tb.table_name, cl.table_name, tb.ddl, tb.table_type
order by tb.table_name
            `,
          databaseName,
          mapRow: (table: { tableName: string }) => {
            const { tableName } = table;
            return {
              type: "external_table",
              label: tableName as string,
              name: tableName,
              value: `${node.value}_${tableName}`,
              children: [],
              databaseName,
              payload: table,
            };
          },
        });
      }
      default: {
        return null;
      }
    }
  };
  return getChildren;
};
