import { useState } from "react";

import { ORDER_ASC, ORDER_DESC } from "./types";

export const useSort = () => {
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [order, setOrder] = useState<"asc" | "desc">(ORDER_ASC);

  const handleSort = (id: string) => {
    if (id !== orderBy && order !== ORDER_ASC) {
      setOrder(ORDER_ASC);
    }
    setOrderBy(id);
  };

  const toggleOrder = () => {
    setOrder(currentOrder =>
      currentOrder === ORDER_DESC ? ORDER_ASC : ORDER_DESC
    );
  };

  return {
    order,
    orderBy,
    handleSort,
    toggleOrder,
  };
};
