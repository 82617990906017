import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { v4 as uuidv4 } from "uuid";

import StatusMessageQueueView from "components/StatusMessageQueue/StatusMessageQueueView/StatusMessageQueueView";

// create context
export const StatusMessageQueueContext = React.createContext<
  StatusMessageQueueContextValue | undefined
>(undefined);

interface Props {
  children: React.ReactNode;
}
export const STATUS_BAR_MAX_MESSAGES_NUMBER = 3;
const DEFAULT_AUTOCLEAR_MS = 5000;

export enum StatusMessagePosition {
  Top = "TOP",
  Bottom = "BOTTOM",
}

export interface StatusMessageOptions {
  insertToPosition?: StatusMessagePosition;
  id: string | null;
  autoRemove: boolean;
  autoClearDelay?: number;
  timestamp?: number;
  testId?: string;
}

export enum StatusMessageType {
  Success = "success",
  Error = "error",
  Loading = "loading",
}

export type StatusMessage = {
  message: string | ReactNode;
  type: StatusMessageType;
  buttons?: ReactNode;
  options?: StatusMessageOptions;
};

export type StatusMessageInternal = StatusMessage & {
  options: StatusMessageOptions & {
    id: string;
    deleteAfterTimestamp: number;
  };
};

export enum StatusMessageAction {
  PUT_MESSAGE = "STATUS_MESSAGE/PUT_MESSAGE",
  REMOVE_MESSAGE = "STATUS_MESSAGE/REMOVE_MESSAGE",
  CLEAR_MESSAGE_BY_TIMEOUT = "STATUS_MESSAGE/CLEAR_MESSAGE_BY_TIMEOUT",
}

const addMessage = (
  messages: StatusMessageInternal[],
  newMessage: StatusMessage
): StatusMessageInternal[] => {
  const message: StatusMessageInternal = {
    ...newMessage,
    options: {
      ...newMessage.options,
      id: newMessage.options?.id || uuidv4(),
      insertToPosition:
        newMessage.options?.insertToPosition || StatusMessagePosition.Bottom,
      autoRemove:
        newMessage.options?.autoRemove !== undefined
          ? newMessage.options?.autoRemove
          : true,
      deleteAfterTimestamp:
        Date.now() +
        (newMessage.options?.autoClearDelay || DEFAULT_AUTOCLEAR_MS),
    },
  };

  if (newMessage?.options?.insertToPosition === StatusMessagePosition.Top) {
    return [message, ...messages];
  }

  return [...messages, message];
};

interface State {
  messages: StatusMessageInternal[];
}

export interface StatusMessageQueueContextValue {
  putStatusMessage: (message: StatusMessage) => void;
  removeStatusMessage: (messageId: string) => void;
}

const ACTIONS = {
  [StatusMessageAction.CLEAR_MESSAGE_BY_TIMEOUT]: (
    state: State,
    payload: number
  ) => {
    return {
      ...state,
      messages: state.messages.filter(
        message => message.options.deleteAfterTimestamp > payload
      ),
    };
  },
  [StatusMessageAction.REMOVE_MESSAGE]: (state: State, payload: string) => {
    const id = payload as string;

    return {
      ...state,
      messages: state.messages.filter(msg => msg.options.id !== id),
    };
  },
  [StatusMessageAction.PUT_MESSAGE]: (state: State, payload: StatusMessage) => {
    const { options } = payload;

    // check if needed to replace message
    if (options?.id) {
      const foundMessage = state.messages.find(
        message => message?.options?.id === options.id
      );

      if (foundMessage) {
        return {
          ...state,
          messages: state.messages.map(message => {
            if (message?.options?.id === options.id) {
              return payload;
            }

            return message;
          }),
        };
      }
    }

    // reduce messages with autoRemove
    const filteredMessages = state.messages.filter(sMessage => {
      if (sMessage.options.autoRemove) {
        return false;
      }

      return true;
    });

    const newMessages = addMessage(filteredMessages, payload as StatusMessage);

    // check list overflow
    if (newMessages.length > STATUS_BAR_MAX_MESSAGES_NUMBER) {
      if (options?.insertToPosition === StatusMessagePosition.Top) {
        newMessages.pop();
      } else {
        newMessages.shift();
      }
    }

    return { ...state, messages: newMessages };
  },
};

const reducer = (
  state: State,
  action: {
    type: StatusMessageAction;
    payload: any;
  }
) => {
  const actionReducer = ACTIONS[action.type] as {
    (state: State, payload: any): State;
  };

  return actionReducer(state, action.payload) as State;
};

const StatusMessageQueueProvider = (props: Props) => {
  const intervalTimer = useRef<NodeJS.Timeout | null>(null);

  const [state, dispatch] = useReducer(reducer, {
    messages: [],
  });

  useEffect(() => {
    intervalTimer.current = setInterval(() => {
      dispatch({
        type: StatusMessageAction.CLEAR_MESSAGE_BY_TIMEOUT,
        payload: Date.now(),
      });
    }, 1000);

    return () => {
      if (intervalTimer.current) {
        clearInterval(intervalTimer.current);
      }
    };
  }, [state]);

  const putStatusMessage = useCallback(
    (payload: StatusMessage) => {
      const { message, type, buttons, options } = payload;

      dispatch({
        type: StatusMessageAction.PUT_MESSAGE,
        payload: {
          message,
          type,
          buttons,
          options: {
            ...(options as StatusMessageOptions),
            timestamp: Date.now(),
          },
        },
      });
    },
    [dispatch]
  );

  const removeStatusMessage = useCallback(
    (messageId: string) => {
      dispatch({
        type: StatusMessageAction.REMOVE_MESSAGE,
        payload: messageId,
      });
    },
    [dispatch]
  );

  const value = useMemo<StatusMessageQueueContextValue>(() => {
    const val = {
      putStatusMessage,
      removeStatusMessage,
    };

    return val;
  }, [putStatusMessage, removeStatusMessage]);

  return (
    <StatusMessageQueueContext.Provider value={value}>
      {props.children}
      {state && (
        <StatusMessageQueueView
          onMessageClose={removeStatusMessage}
          messages={state.messages}
        />
      )}
    </StatusMessageQueueContext.Provider>
  );
};

export default StatusMessageQueueProvider;
