import { useEffect } from "react";
import {
  FAVICON_ANIMATION_FRAME_RATE,
  faviconUpdater,
} from "utils/faviconUpdater";
import {
  createHexColor,
  getColorsFromRange,
} from "utils/helpers/getColorsFromRange";

import { TabStatus } from "pages/DevelopWorkspace/Editor/TabsBar/Tab/Tab";
import { getDocumentStatus } from "pages/DevelopWorkspace/Editor/TabsBar/TabsBar";
import useActiveDocument from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useActiveDocument";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { useScripts } from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { getDocumentScript } from "pages/DevelopWorkspace/helpers/getDocumentScript";

import { setDocumentTitle } from "components/DocumentTitle/setDocumentTitle";

const useFaviconUpdate = () => {
  const { qsResults } = useDocuments();
  const { state: scriptsState } = useScripts();
  const activeDocument = useActiveDocument();

  const activeDocumentScript = activeDocument
    ? getDocumentScript(activeDocument, scriptsState)
    : null;

  useEffect(() => {
    if (activeDocument) {
      setDocumentTitle(
        activeDocumentScript?.title ? activeDocumentScript.title : ""
      );

      const successColor = getComputedStyle(document.body).getPropertyValue(
        "--Success_0"
      );
      const errorColor = getComputedStyle(document.body).getPropertyValue(
        "--Error_0"
      );
      const gray8 = getComputedStyle(document.body).getPropertyValue(
        "--grey_8"
      );
      const gray5 = getComputedStyle(document.body).getPropertyValue(
        "--grey_5"
      );
      const gradientColors = getColorsFromRange(
        createHexColor(gray8),
        createHexColor(gray5),
        FAVICON_ANIMATION_FRAME_RATE
      );
      const status = getDocumentStatus(activeDocument, qsResults);

      switch (status) {
        case TabStatus.Success:
          faviconUpdater.updateFavicon({
            type: "solid",
            color: successColor,
          });
          break;
        case TabStatus.Error:
          faviconUpdater.updateFavicon({
            type: "solid",
            color: errorColor,
          });
          break;
        case TabStatus.Running:
          faviconUpdater.updateFavicon({
            type: "gradient",
            colors: gradientColors,
          });
          break;

        default:
          faviconUpdater.updateFavicon(null);
          break;
      }
    }
  }, [activeDocument, activeDocumentScript, qsResults]);

  useEffect(() => {
    return () => {
      setDocumentTitle("");
      faviconUpdater.updateFavicon(null);
    };
  }, []);

  return null;
};

export default useFaviconUpdate;
