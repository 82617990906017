import { AxisBottom, AxisLeft, AxisRight } from "@visx/axis";
import { AxisScale, TickLabelProps } from "@visx/axis/lib/types";
import * as allCurves from "@visx/curve";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ScaleInput, scaleBand, scaleLinear, scaleTime } from "@visx/scale";
import { Bar, BarStack, Line, LinePath } from "@visx/shape";
import { TooltipWithBounds, useTooltip } from "@visx/tooltip";
import cn from "classnames";
import moment from "moment";
import React, { Fragment } from "react";
import { Formatter } from "utils/helpers/Format";

import {
  DateValue,
  Series,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/types";

import styles from "components/reports/charts/Chart/Chart.module.scss";
import TooltipContent, {
  TooltipData,
} from "components/reports/charts/Chart/TooltipContent/TooltipContent";

const getX = (d: DateValue) => d.date;
const getY = (d: DateValue) => d.value;

export enum ChartType {
  Line = "Line",
  Bar = "Bar",
}

export enum MainValueAxisPosition {
  Left = "Left",
  Right = "Right",
}

interface ChartProps<DataKey extends string> {
  chartType: ChartType;
  series: Series<DataKey>[];
  subSeries?: Series<DataKey>[];
  subSeriesValuesRange?: [number, number];
  tooltipTimeFormater?: (date: Date) => string;
  tooltipTimeLabel?: string;
  width: number;
  height: number;
  selectedDataKeys: DataKey[];
  highlightedDataKeys?: DataKey[];
  dataKeys: {
    [key in DataKey]: DataKey;
  };
  dataKeyNames: Record<DataKey, string>;
  dataKeyColors: Record<DataKey, string>;
  toggleDataKey: (metric: DataKey) => void;
  dateRange: [Date, Date];
  valuesRange: [number, number];
  valuesUnitFormatter?: (val: number) => string;
  dataKeyValueFormatter: (
    key: DataKey,
    value: number
  ) => {
    value: string;
    unit: string;
    unitPosition: "before" | "after";
  };
  mainValueAxisPosition: MainValueAxisPosition;
  showLegend?: boolean;
  showLegendPlaceholder?: boolean;
  showBottomAxis?: boolean;
  monthlyTicks?: boolean;
  scaleBandConfig?: {
    domain?: string[];
    paddingInner?: number;
    paddingOuter?: number;
  };
  barRenderer?: (bar: {
    key: string;
    x: number;
    y: number;
    height: number;
    width: number;
  }) => React.ReactNode;
  styles?: {
    mainAxis?: {
      tickLabelProps?: TickLabelProps<ScaleInput<AxisScale>>;
    };
    subAxis?: {
      tickLabelProps?: TickLabelProps<ScaleInput<AxisScale>>;
    };
    bottomAxis?: {
      tickLabelProps?: TickLabelProps<ScaleInput<AxisScale>>;
    };
    legend?: {
      classes?: {
        legend?: string;
        item?: string;
        icon?: string;
        label?: string;
      };
    };
  };
}

const Chart = <DataKey extends string>(props: ChartProps<DataKey>) => {
  const {
    chartType,
    series,
    width,
    height,
    tooltipTimeFormater,
    tooltipTimeLabel,
    selectedDataKeys,
    toggleDataKey,
    dataKeys,
    dataKeyNames,
    dataKeyColors,
    dateRange,
    valuesRange,
    valuesUnitFormatter,
    subSeries,
    subSeriesValuesRange,
    dataKeyValueFormatter,
    mainValueAxisPosition,
    styles: overrideStyles,
    showLegend = true,
    showLegendPlaceholder,
    barRenderer,
    showBottomAxis = true,
    scaleBandConfig,
    monthlyTicks,
    highlightedDataKeys = [],
  } = props;

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData<DataKey>>();

  const allSeries = [...series, ...(subSeries || [])];
  const allData: DateValue[] = allSeries.reduce(
    (acc, s) => acc.concat(s.data),
    [] as DateValue[]
  );

  const allDataGroupedByDate = series.reduce(
    (acc, s) => {
      s.data.forEach(d => {
        const date = d.date.getTime();

        if (!acc[date]) {
          acc[date] = {
            date: d.date,
            data: {} as Record<DataKey, number>,
          };
        }

        acc[date].data[dataKeys[s.type]] = d.value;
      });

      return acc;
    },
    {} as Record<
      number,
      {
        date: Date;
        data: Record<DataKey, number>;
      }
    >
  );

  const barsData = Object.values(allDataGroupedByDate);

  const tooltipIsEnabled = !!tooltipTimeFormater && !!tooltipTimeLabel;

  const rightAxisExists = !!subSeries?.length;

  const rightPadding = rightAxisExists ? 42 : 16;
  const leftPadding =
    mainValueAxisPosition === MainValueAxisPosition.Left ? 38 : rightPadding;

  const getBottomPadding = () => {
    let padding = 12;

    if (showLegend || showLegendPlaceholder) {
      padding += 23;
    }

    if (showBottomAxis) {
      padding += 20;
    }

    return padding;
  };

  const PADDINGS = {
    left: leftPadding,
    right:
      mainValueAxisPosition === MainValueAxisPosition.Left ? rightPadding : 38,
    top: 24,
    bottom: getBottomPadding(),
  };

  const MainAxisComponent =
    mainValueAxisPosition === MainValueAxisPosition.Left ? AxisLeft : AxisRight;

  const chartSize = {
    width: width - PADDINGS.left - PADDINGS.right,
    height: height - PADDINGS.top - PADDINGS.bottom,
  };

  const xScale = scaleTime<number>({
    domain: dateRange,
    range: [0, chartSize.width],
  });

  // list of dates between dateRange
  const domain: string[] = [];

  let startDate = dateRange[0];
  while (startDate <= dateRange[1]) {
    // add each date to the domain as ISO string
    domain.push(startDate.toISOString());
    // get next day
    startDate = moment(startDate).add(1, "day").toDate();
  }

  const barXScale = scaleBand<string>({
    domain,
    range: [0, chartSize.width],
    paddingInner: 0.1,
    ...scaleBandConfig,
  });

  const yScale = scaleLinear<number>({
    domain: valuesRange,
    range: [0, chartSize.height],
    reverse: true,
    nice: true,
  });

  const subYScale = scaleLinear<number>({
    domain: subSeriesValuesRange,
    range: [0, chartSize.height],
    reverse: true,
    nice: true,
  });

  const handleTooltip = (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
  ) => {
    if (!tooltipIsEnabled) {
      return;
    }

    const { x } = localPoint(event) || { x: 0 };

    const date = xScale.invert(x - PADDINGS.left);

    const getDistanceToDate = (d: DateValue) =>
      Math.abs(getX(d).getTime() - date.getTime());

    const barWidth = barXScale.bandwidth();

    if (!allData.length) {
      return;
    }

    const closestDate =
      chartType === ChartType.Line
        ? allData.reduce((prev, curr) =>
            getDistanceToDate(curr) < getDistanceToDate(prev) ? curr : prev
          )
        : barsData.reduce((prev, curr) => {
            const centerOfNextBar =
              (barXScale(curr.date.toISOString()) || 0) +
              PADDINGS.left +
              barWidth / 2;
            const centerOfPrevBar =
              (barXScale(prev.date.toISOString()) || 0) +
              PADDINGS.left +
              barWidth / 2;

            return Math.abs(centerOfNextBar - x) < Math.abs(centerOfPrevBar - x)
              ? curr
              : prev;
          });

    const tooltipData: TooltipData<DataKey> = allSeries.reduce(
      (acc, s) => {
        const found = s.data.find(
          d => d.date.getTime() === closestDate.date.getTime()
        );

        if (selectedDataKeys.includes(dataKeys[s.type])) {
          acc.data[dataKeys[s.type]] = found?.value ?? 0;
        }

        return acc;
      },
      {
        date: closestDate.date,
        data: {} as Record<DataKey, number>,
      }
    );

    const centerOfBar =
      (barXScale(closestDate.date.toISOString()) || 0) +
      barXScale.bandwidth() / 2;

    const tooltipLeft =
      chartType === ChartType.Line
        ? xScale(closestDate.date) + PADDINGS.left
        : centerOfBar + PADDINGS.left;

    showTooltip({
      tooltipData,
      tooltipLeft,
      tooltipTop: PADDINGS.top,
    });
  };

  const mainAxisPosition =
    mainValueAxisPosition === MainValueAxisPosition.Left
      ? {
          left: 20,
          top: PADDINGS.top,
        }
      : {
          left: width - PADDINGS.right,
          top: PADDINGS.top,
        };

  const getBottomAxisTickFormat = () => {
    if (chartType === ChartType.Line) {
      return undefined;
    }

    return (d: any) => {
      if (typeof d !== "string") {
        return "";
      }

      if (monthlyTicks) {
        if (moment(d).format("MMM") === "Jan") {
          return moment(d).format("YYYY");
        }

        return moment(d).format("MMM");
      }

      return moment(d).format("MMM D");
    };
  };

  return (
    <div
      className={styles.chartWrapper}
      style={{ width, height }}
    >
      <svg
        width={width}
        height={height}
        className={cn(styles.chart)}
      >
        <MainAxisComponent
          scale={yScale}
          left={mainAxisPosition.left}
          numTicks={3}
          tickFormat={d =>
            valuesUnitFormatter ? valuesUnitFormatter(+d) : `${d}`
          }
          tickLabelProps={() => ({
            fill: "var(--grey_5)",
            fontFamily: "Inter",
            fontSize: 7,
            fontWeight: 400,
            textAnchor: "start",
            verticalAnchor: "middle",
            ...overrideStyles?.mainAxis?.tickLabelProps,
          })}
          hideAxisLine
          hideTicks
          top={mainAxisPosition.top}
        />

        {!!subSeries?.length && (
          <AxisRight
            scale={subYScale}
            left={width - 20}
            tickFormat={d =>
              `${Formatter.bytesFormatter(+d, 0).size} ${
                Formatter.bytesFormatter(+d, 0).notation
              }`
            }
            tickValues={
              subSeriesValuesRange?.[1]
                ? [0, subSeriesValuesRange[1] / 2, subSeriesValuesRange[1] || 0]
                : [0]
            }
            tickLabelProps={() => ({
              fill: "var(--grey_5)",
              fontFamily: "Inter",
              fontSize: 7,
              fontWeight: 400,
              textAnchor: "end",
              verticalAnchor: "middle",
              ...overrideStyles?.subAxis?.tickLabelProps,
            })}
            hideAxisLine
            hideTicks
            top={PADDINGS.top}
          />
        )}

        {!!showBottomAxis && (
          <AxisBottom
            scale={chartType === ChartType.Line ? xScale : barXScale}
            top={chartSize.height + PADDINGS.top}
            left={PADDINGS.left}
            hideAxisLine
            hideTicks
            tickFormat={getBottomAxisTickFormat()}
            tickLabelProps={{
              fill: "var(--grey_5)",
              fontFamily: "Inter",
              fontSize: 7,
              fontWeight: 400,
              textAnchor: "middle",
              ...(overrideStyles?.bottomAxis?.tickLabelProps || {}),
            }}
            numTicks={monthlyTicks ? undefined : 6}
          />
        )}

        {chartType === ChartType.Bar && (
          <Group
            key="bars"
            top={PADDINGS.top}
            left={PADDINGS.left}
          >
            <BarStack
              data={barsData}
              keys={selectedDataKeys}
              x={d => d.date.toISOString()}
              xScale={barXScale}
              yScale={yScale}
              value={(d, key) => {
                return d.data[key];
              }}
              color={d => dataKeyColors[d]}
            >
              {barStacks => {
                return barStacks.map(barStack => {
                  return barStack.bars.map(bar => {
                    if (Number.isNaN(bar.bar[0]) || Number.isNaN(bar.bar[1])) {
                      return null;
                    }
                    return (
                      <Fragment
                        key={`bar-stack-${barStack.index}-${bar.index}`}
                      >
                        <rect
                          key={`bar-stack-${barStack.index}-${bar.index}`}
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          fill={bar.color}
                          onTouchStart={handleTooltip}
                          onTouchMove={handleTooltip}
                          onMouseMove={handleTooltip}
                          onMouseLeave={() => hideTooltip()}
                        />
                        {barRenderer && barRenderer(bar)}
                      </Fragment>
                    );
                  });
                });
              }}
            </BarStack>
          </Group>
        )}

        {chartType === ChartType.Line &&
          series.map(lineData => {
            return (
              <Group
                key={`lines-${lineData.type}`}
                top={PADDINGS.top}
                left={PADDINGS.left}
              >
                <LinePath<DateValue>
                  curve={allCurves.curveLinear}
                  data={lineData.data}
                  x={d => xScale(getX(d)) ?? 0}
                  y={d => yScale(getY(d)) ?? 0}
                  stroke={dataKeyColors[lineData.type]}
                  opacity={
                    !highlightedDataKeys.length ||
                    highlightedDataKeys.includes(lineData.type)
                      ? 1
                      : 0.3
                  }
                  strokeWidth={
                    highlightedDataKeys.includes(lineData.type) ? 1.75 : 1.25
                  }
                  shapeRendering="geometricPrecision"
                />
              </Group>
            );
          })}

        {subSeries?.map(lineData => {
          return (
            <Group
              key={`lines-${lineData.type}`}
              top={PADDINGS.top}
              left={PADDINGS.left}
            >
              <LinePath<DateValue>
                curve={allCurves.curveLinear}
                data={lineData.data}
                x={d => xScale(getX(d)) ?? 0}
                y={d => subYScale(getY(d)) ?? 0}
                stroke={dataKeyColors[lineData.type]}
                opacity={
                  !highlightedDataKeys.length ||
                  highlightedDataKeys.includes(lineData.type)
                    ? 1
                    : 0.3
                }
                strokeWidth={
                  highlightedDataKeys.includes(lineData.type) ? 1.75 : 1.25
                }
                shapeRendering="geometricPrecision"
              />
            </Group>
          );
        })}

        <Bar
          x={PADDINGS.left}
          y={PADDINGS.top}
          width={chartSize.width}
          height={chartSize.height}
          fill="transparent"
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />

        {tooltipOpen && tooltipData && (
          <Line
            from={{ x: tooltipLeft, y: PADDINGS.top }}
            to={{
              x: tooltipLeft,
              y: chartSize.height + PADDINGS.top + 4,
            }}
            stroke="var(--grey_stroke_2)"
            strokeWidth={1}
            pointerEvents="none"
          />
        )}
      </svg>

      {tooltipIsEnabled && tooltipOpen && tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          className={styles.tooltip}
        >
          <TooltipContent
            data={tooltipData}
            highlightedDataKeys={highlightedDataKeys}
            tooltipTimeFormater={tooltipTimeFormater}
            tooltipTimeLabel={tooltipTimeLabel}
            dataKeyNames={dataKeyNames}
            dataKeyColors={dataKeyColors}
            dataKeyValueFormatter={dataKeyValueFormatter}
          />
        </TooltipWithBounds>
      )}

      {(showLegend || showLegendPlaceholder) && (
        <div
          className={cn(styles.legend, overrideStyles?.legend?.classes?.legend)}
        >
          {showLegend &&
            Object.keys(dataKeyNames).map(key => {
              return (
                <div
                  key={key}
                  onClick={() => toggleDataKey(key as DataKey)}
                  className={cn(
                    styles.item,
                    overrideStyles?.legend?.classes?.item,
                    {
                      [styles.clickable]: Object.keys(dataKeyNames).length > 1,
                      [styles.active]: selectedDataKeys.includes(
                        key as DataKey
                      ),
                      [styles.highlighted]: highlightedDataKeys.includes(
                        key as DataKey
                      ),
                    }
                  )}
                >
                  <div
                    className={cn(
                      styles.icon,
                      overrideStyles?.legend?.classes?.icon
                    )}
                    style={{
                      backgroundColor: selectedDataKeys.includes(key as DataKey)
                        ? dataKeyColors[key as DataKey]
                        : "var(--grey_7)",
                    }}
                  />
                  <div
                    className={cn(
                      styles.label,
                      overrideStyles?.legend?.classes?.label
                    )}
                  >
                    {dataKeyNames[key as DataKey]}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Chart;
