import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useIsFirstUser } from "hooks/useIsFirstUser";
import { useDatabasesNames } from "services/databases/useDatabasesNames";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import DatabaseIcon from "assets/icons/DatabaseSelectorIcon.svg?react";

import { DocumentStatus } from "pages/DevelopWorkspace/Editor/Document/Document";
import DocumentActionsPanel from "pages/DevelopWorkspace/Editor/Document/DocumentContextSelector/DocumentActionsPanel/DocumentActionsPanel";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import {
  ExecutionType,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { ContextSelector } from "components/composite/ContextSelector/ContextSelector";
import { EngineContextErrorTooltipTitle } from "components/composite/ContextSelector/components/tooltipTitles";
import { EngineContextSelector } from "components/composite/ContextSelector/variations/EngineContextSelector";
import { EngineStatusToggle } from "components/composite/EngineStatusToggle/EngineStatusToggle";

import { EmptyDatabasesList } from "./EmptyDatabasesList/EmptyDatabasesList";
import { NONE } from "./types";

import styles from "./DocumentContextSelector.module.scss";

interface DocumentContextSelectorProps {
  document: WorkspaceDocument;
  documentStatus: DocumentStatus;
  executionType: ExecutionType;
  onExecutionTypeChange: (type: ExecutionType) => void;
}

const DocumentContextSelector = (props: DocumentContextSelectorProps) => {
  const { document, executionType, onExecutionTypeChange, documentStatus } =
    props;
  const isFirstUser = useIsFirstUser();
  const {
    data: engines,
    isLoading: isEnginesLoading,
    error: enginesError,
  } = useWorkspaceEngines({
    includeSystemEngine: true,
    isFirstUser,
  });

  const { t } = useTranslation();
  const databaseSelectorRef = useRef<HTMLDivElement>(null);
  const [databaseSelectorOpen, setDatabaseSelectorOpen] = useState(false);
  const [searchDatabase, setSearchDatabase] = useState("");

  const databases = useDatabasesNames({
    isFirstUser,
    showSystemDatabases: true,
  });
  const {
    actions: { changeDocumentContext },
  } = useDocuments();

  if (isEnginesLoading || !databases) {
    return <div>{t("workspace.context_selector.loading")}</div>;
  }

  if (enginesError) {
    return <div>{t("workspace.context_selector.error_loading")}</div>;
  }

  if (!engines || !databases) {
    return <div>{t("workspace.context_selector.no_data")}</div>;
  }

  const foundDatabases = databases.filter(database => {
    return database.catalogName
      .toLowerCase()
      .includes(searchDatabase.toLowerCase());
  });

  const engine = engines.find(
    engine => engine.engineName === document.context.engine.name
  );

  const isDisabled =
    documentStatus.isUnknown ||
    documentStatus.isRestoring ||
    documentStatus.isRunning;

  const dbItems = [
    ...foundDatabases.map(database => (
      <ContextMenuItem
        key={database.catalogName}
        checked={database.catalogName === document.context.database.name}
        onClick={() => {
          changeDocumentContext(document.id, {
            database: { name: database.catalogName, isUserSelected: true },
          });
          setDatabaseSelectorOpen(false);
        }}
        text={database.catalogName}
        testId={`context-db-item-${database.catalogName}`}
      />
    )),
  ];

  if (!searchDatabase) {
    dbItems.unshift(
      <ContextMenuItem
        text={t("workspace.context_selector.none")}
        key="none"
        testId="no-database-item"
        onClick={() => {
          changeDocumentContext(document.id, {
            database: { name: NONE, isUserSelected: true },
          });
          setDatabaseSelectorOpen(false);
        }}
      />
    );
  }

  const getDatabaseNameLabel = (databaseName: string | Symbol) => {
    if (!databaseName || databaseName === NONE) {
      return t("workspace.context_selector.no_db_selected");
    }
    return databaseName as string;
  };

  return (
    <div className={styles.root}>
      <div className={styles.selectorsPanel}>
        <ContextSelector
          active={!!document.context.database.name}
          isDisabled={isDisabled}
          selectorRef={databaseSelectorRef}
          onClick={() => {
            if (isDisabled) {
              return;
            }

            setSearchDatabase("");
            setDatabaseSelectorOpen(true);
          }}
          testId="database-context-selector"
          leftIcon={<DatabaseIcon />}
          label={getDatabaseNameLabel(document.context.database.name)}
          selectorIsOpen={databaseSelectorOpen}
          onClose={() => {
            setDatabaseSelectorOpen(false);
          }}
          showSearch={databases.length > 5}
          setSearchValue={setSearchDatabase}
          searchValue={searchDatabase}
          searchTestId="search-database-context"
          notFoundText={t("workspace.context_selector.no_matching_db")}
          items={dbItems}
          noItemsFallback={<EmptyDatabasesList />}
          noSearchResults={foundDatabases.length === 0}
        />

        <EngineContextSelector
          includeSystemEngine
          active={!!document.context.engine.name}
          isDisabled={isDisabled}
          tooltipError={
            document.execution?.documentExecutionError ? (
              <EngineContextErrorTooltipTitle
                title={document.execution.documentExecutionError.title}
                description={
                  document.execution.documentExecutionError.description
                }
              />
            ) : null
          }
          selectedEngineName={document.context.engine.name}
          onSelectEngine={engineName => {
            changeDocumentContext(document.id, {
              engine: { name: engineName, isUserSelected: true },
            });
          }}
        />

        {!!engine && engine.engineName !== "system" && (
          <EngineStatusToggle engine={engine} />
        )}
      </div>

      <DocumentActionsPanel
        executionType={executionType}
        onExecutionTypeChange={onExecutionTypeChange}
        document={document}
        documentStatus={documentStatus}
      />
    </div>
  );
};

export default DocumentContextSelector;
