import { Virtualizer, useVirtualizer } from "@tanstack/react-virtual";
import _orderBy from "lodash/orderBy";
import React, { ReactNode, useMemo, useRef, useState } from "react";

import { Row as TableRow } from "components/FlatTable";
import GroupHeader from "components/FlatTable/GroupHeader";

import { Header } from "./Header";
import { TableContext, WidthContext } from "./context";
import { ColumnType } from "./types";
import { useColumnFilters } from "./useColumnFilters";
import { useSort } from "./useSort";
import { getIdentity } from "./utils";

import styles from "./styles.module.scss";

type Row = unknown;
type ColumnWidths = Record<string, number>;

const GroupHeaderSymbol = Symbol("group-header");

type GroupHeaderType = {
  id: string;
  title: ReactNode;
  subtitle?: ReactNode;
  [GroupHeaderSymbol]: true;
};

export type RowGroup = {
  id: string;
  title: ReactNode;
  subtitle?: ReactNode;
  rows: Row[];
};

type TableProps = {
  rowRenderer: ({
    index,
    row,
    rows,
    lastInStandaloneGroup,
  }: {
    index: number;
    row: Row;
    rows: Row[];
    lastInStandaloneGroup?: boolean;
  }) => React.ReactElement | null;

  columns: ColumnType[];
  className?: string;
  disableResize?: boolean;
  loader?: React.ReactNode | null;
  emptyState?: React.ReactNode | null;
  dataTestId?: string;
  floatingColumn?: string;
  sortable?: boolean;
  isStandaloneRow?: (row: unknown) => boolean;
  disableColumnFilters?: boolean;
} & (
  | { rows: Row[]; rowGroups?: never }
  | { rowGroups: RowGroup[]; rows?: never }
);

type BasicBodyProps = {
  emptyState?: React.ReactNode | null;
  rowRenderer: ({
    index,
    row,
    rows,
    lastInStandaloneGroup,
  }: {
    index: number;
    row: Row;
    rows: Row[];
    lastInStandaloneGroup?: boolean;
  }) => React.ReactElement | null;
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  order: "asc" | "desc";
  orderBy: string | null;
  columns: ColumnType[];
  isStandaloneRow?: (row: unknown) => boolean; // checks if the row should be rendered at the top of the table regardless of sorting
};

type BodyProps = BasicBodyProps & {
  rows: Row[];
};

type GroupedBodyProps = BasicBodyProps & {
  rowGroups: RowGroup[];
  toggleGroup: (groupId: string) => void;
  collapsedGroups: string[];
};

const Body = (props: BodyProps) => {
  const {
    emptyState,
    virtualizer,
    rowRenderer,
    rows,
    isStandaloneRow,
    orderBy,
    order,
    columns,
  } = props;

  const items = virtualizer.getVirtualItems();

  const identity = useMemo(
    () => getIdentity(columns, orderBy),
    [columns, orderBy]
  );

  const sortedItems = useMemo(() => {
    const standaloneRows = isStandaloneRow ? rows.filter(isStandaloneRow) : [];
    const regularRows = rows.filter(row => !standaloneRows.includes(row));

    return [
      ..._orderBy(standaloneRows, [identity], [order]),
      ..._orderBy(regularRows, [identity], [order]),
    ];
  }, [identity, order, isStandaloneRow, rows]);

  if (!items.length) {
    return <div>{emptyState}</div>;
  }

  return (
    <div
      className={styles.virtualizedOuter}
      style={{
        height: virtualizer.getTotalSize(),
      }}
    >
      <div
        className={styles.virtualizedInner}
        style={{
          transform: `translateY(${items[0].start}px)`,
        }}
      >
        {items.map(virtualRow => {
          const index = virtualRow.index;
          const row = sortedItems[index];
          return rowRenderer({
            row,
            rows: sortedItems,
            index,
            lastInStandaloneGroup: isStandaloneRow
              ? sortedItems.findLastIndex(value => isStandaloneRow(value)) ===
                index
              : false,
          });
        })}
      </div>
    </div>
  );
};

const GroupedBody = (props: GroupedBodyProps) => {
  const {
    emptyState,
    virtualizer,
    rowRenderer,
    rowGroups,
    orderBy,
    order,
    columns,
    isStandaloneRow,
    toggleGroup,
    collapsedGroups,
  } = props;

  const items = virtualizer.getVirtualItems();

  const identity = useMemo(
    () => getIdentity(columns, orderBy),
    [columns, orderBy]
  );

  const sortedRowGroups = useMemo(() => {
    const sortedGroups = rowGroups.map(group => {
      if (collapsedGroups.includes(group.id)) {
        return {
          ...group,
          rows: [],
        };
      }

      const standaloneRows = isStandaloneRow
        ? group.rows.filter(isStandaloneRow)
        : [];
      const regularRows = group.rows.filter(
        row => !standaloneRows.includes(row)
      );

      const sortedRows = [
        ..._orderBy(standaloneRows, [identity], [order]),
        ..._orderBy(regularRows, [identity], [order]),
      ];

      return {
        ...group,
        rows: sortedRows,
      };
    });

    return sortedGroups;
  }, [identity, order, isStandaloneRow, rowGroups, collapsedGroups]);

  // flatter row groups, group title shoyld be the first row
  const rows = sortedRowGroups.reduce((acc, group) => {
    const groupHeader: GroupHeaderType = {
      id: group.id,
      title: group.title,
      subtitle: group.subtitle,
      [GroupHeaderSymbol]: true,
    };

    return [...acc, groupHeader, ...group.rows];
  }, [] as Row[]);

  if (!items.length) {
    return <div>{emptyState}</div>;
  }

  return (
    <div
      className={styles.virtualizedOuter}
      style={{
        height: virtualizer.getTotalSize(),
      }}
    >
      <div
        className={styles.virtualizedInner}
        style={{
          transform: `translateY(${items[0].start}px)`,
        }}
      >
        {items.map(virtualRow => {
          const index = virtualRow.index;
          const row = rows[index];

          if ((row as any)?.[GroupHeaderSymbol]) {
            const groupHeader = row as GroupHeaderType;

            const collapsed = collapsedGroups.includes(groupHeader.id);
            return (
              <TableRow
                key={"group-" + groupHeader.id}
                testId={"group-" + groupHeader.id}
                underline={true}
              >
                <GroupHeader
                  title={groupHeader.title}
                  subtitle={groupHeader.subtitle}
                  testId={
                    "group-header-" +
                    groupHeader.id +
                    (collapsed ? "-collapsed" : "")
                  }
                  collapsed={collapsed}
                  onClick={() => {
                    toggleGroup(groupHeader.id);
                  }}
                />
              </TableRow>
            );
          }

          return rowRenderer({
            row,
            rows,
            index,
          });
        })}
      </div>
    </div>
  );
};

export const VirtualizedTable: React.FC<TableProps> = React.memo(props => {
  const {
    rowRenderer,
    rows,
    rowGroups,
    columns,
    disableResize = true,
    loader,
    emptyState,
    dataTestId,
    floatingColumn,
    sortable,
    isStandaloneRow,
    disableColumnFilters,
  } = props;

  const { order, orderBy, toggleOrder, handleSort } = useSort();

  const [collapsedGroups, setCollapsedGroups] = useState<string[]>([]); // use keys of rowGroups

  const [columnWidths, setColumnWidths] = useState<ColumnWidths>(() => {
    return columns.reduce<Record<string, number>>((acc, { id, width }) => {
      acc[id] = width;
      return acc;
    }, {});
  });

  const fullWidthColumnIndex = columns.findIndex(
    column => column.fillAvailableWidth
  );

  const columnIndex =
    fullWidthColumnIndex === -1 ? columns.length - 1 : fullWidthColumnIndex;

  const leadingWidth = useMemo(
    () =>
      columns.reduce((acc, { id }, index) => {
        if (columnIndex === index) {
          return acc;
        }
        const width = columnWidths[id];
        return acc + width;
      }, 0),
    [columns, columnWidths, columnIndex]
  );

  const finishResize = (newWidths: ColumnWidths) => {
    setColumnWidths(newWidths);
  };

  const toggleGroup = (groupId: string) => {
    if (collapsedGroups.includes(groupId)) {
      setCollapsedGroups(collapsedGroups.filter(id => id !== groupId));
    } else {
      setCollapsedGroups([...collapsedGroups, groupId]);
    }
  };

  const {
    filterColumns,
    handleColumnClick,
    toggleShowAllColumns,
    visibleColumns,
  } = useColumnFilters(columns);

  const tableContextValue = useMemo(() => {
    return {
      floatingColumn,
      visibleColumns,
      filterColumns,
      handleColumnClick,
      toggleShowAllColumns,
    };
  }, [
    floatingColumn,

    visibleColumns,
    filterColumns,
    handleColumnClick,
    toggleShowAllColumns,
  ]);

  const widthContextValue = useMemo(() => {
    return {
      leadingWidth,
      columnWidths,
    };
  }, [leadingWidth, columnWidths]);

  const parentRef = useRef<HTMLDivElement>(null);

  const count = useMemo(() => {
    if (rows) {
      return rows.length;
    }
    if (rowGroups) {
      return rowGroups.reduce((acc, group) => {
        if (collapsedGroups.includes(group.id)) {
          return acc + 1; // +1 for the group title
        }

        return acc + group.rows.length + 1;
      }, 0);
    }
    return 0;
  }, [rows, rowGroups, collapsedGroups]);

  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 36,
  });

  return (
    <TableContext.Provider value={tableContextValue}>
      <WidthContext.Provider value={widthContextValue}>
        <div
          className={styles.virtualizedWrapper}
          data-testid={dataTestId}
        >
          <div className={styles.virtualizedTable}>
            <div
              ref={parentRef}
              className={styles.list}
            >
              <Header
                columns={columns}
                columnWidths={columnWidths}
                finishResize={finishResize}
                disableResize={disableResize}
                order={order}
                orderBy={orderBy}
                toggleOrder={toggleOrder}
                handleSort={handleSort}
                sortable={sortable}
                disableColumnFilters={disableColumnFilters}
              />
              {rowGroups && (
                <GroupedBody
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  virtualizer={virtualizer}
                  emptyState={emptyState}
                  rowRenderer={rowRenderer}
                  rowGroups={rowGroups}
                  isStandaloneRow={isStandaloneRow}
                  toggleGroup={toggleGroup}
                  collapsedGroups={collapsedGroups}
                />
              )}
              {rows && (
                <Body
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  virtualizer={virtualizer}
                  emptyState={emptyState}
                  rowRenderer={rowRenderer}
                  rows={rows}
                  isStandaloneRow={isStandaloneRow}
                />
              )}
            </div>
            {loader}
          </div>
        </div>
      </WidthContext.Provider>
    </TableContext.Provider>
  );
});
