export interface FetchOptions {
  abortController: AbortController;
  headers: HeadersInit;
}

export interface DocumentExecutionRuntime {
  id: string; // client side ID
  abortController: AbortController;
  progressFetchPaused: boolean;
}

export enum RunningQueryStatus {
  RUNNING = "RUNNING",
  SUSPENDED = "SUSPENDED",
}

export type RunningQuery = {
  status: RunningQueryStatus;
  queryLabel: string;
  queryId: string;
  durationUsec: number;
  scannedRows: number;
  scannedBytes: number;
  insertedRows: number;
};
