import { useTranslation } from "react-i18next";

import { getEngineSql } from "services/engines/createWorkspaceEngine";
import { WorkspaceEngine } from "services/engines/engine.types";
import { SqlRbacAction } from "services/rbac/action";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

import { useMenu } from "components/ActionMenu/useMenu";
import { useAccessManager } from "components/App/accessManager";
import DefinitionCodePreview from "components/DefinitionCodePreview/DefinitionCodePreview";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";

import { useDeleteEngine } from "./useDeleteEngine";
import { useEditEngine } from "./useEditEngine";

export type EngineMenuActions = {
  openEditEngine: ReturnType<typeof useEditEngine>["openEditEngine"];
  openDeleteEngine: ReturnType<typeof useDeleteEngine>["openDeleteEngine"];
};

type Props = {
  engine: WorkspaceEngine;
  actions: EngineMenuActions;
};

export const EngineMenu = (props: Props) => {
  const { engine, actions } = props;
  const { openDeleteEngine, openEditEngine } = actions;
  const menu = useMenu();
  const { t } = useTranslation();
  const { isAllowedTo } = useAccessManager();
  const {
    state: { activeDocumentId },
    actions: { changeDocumentContext },
  } = useDocuments();
  const { insertTextAtCursorPosition } = useActiveEditorView();

  const canEditEngine = isAllowedTo(
    "engine",
    engine.engineName,
    SqlRbacAction.MODIFY,
    { owner: engine.engineOwner }
  );

  const editItems = canEditEngine
    ? [
        {
          key: "edit",
          text: t("workspace.engine_menu.edit"),
          action: () => {
            openEditEngine(engine);
          },
          testId: "edit-engine-item",
        },
      ]
    : [];

  const deleteItems = canEditEngine
    ? [
        [
          {
            key: "delete",
            text: t("workspace.engine_menu.delete"),
            action: () => {
              openDeleteEngine(engine.engineName);
            },
            testId: "delete-engine-item",
          },
        ],
      ]
    : [];

  const items = [
    [
      {
        key: "context",
        text: t("workspace.engine_menu.set_context"),
        action: () => {
          if (activeDocumentId) {
            changeDocumentContext(activeDocumentId, {
              engine: { name: engine.engineName, isUserSelected: true },
            });
          }
        },
        testId: "set-engine-context-item",
      },
      ...editItems,
      {
        key: "view_definition",
        text: t("workspace.engine_menu.view_definition"),
        renderContent: (props: { onContentClose: () => void }) => {
          const code = getEngineSql(engine);

          return (
            <DefinitionCodePreview
              code={code}
              onSecondaryButtonClick={() => {
                insertTextAtCursorPosition(code);
                menu.closeMenu();
                props.onContentClose();
              }}
            />
          );
        },
        testId: "view-engine-definition-item",
      },
    ],
    ...deleteItems,
  ];
  return (
    <>
      <Menu
        testId="engine-more-icon"
        items={items}
        menu={menu}
      />
    </>
  );
};
