import cn from "classnames";
import { useTranslation } from "react-i18next";

import { FeatureFlag } from "featureFlags/constants";
import { useAllFlags } from "featureFlags/hooks/useAllFlags";
import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import EngineMonitoringIcon from "assets/icons/EngineMonitoringIcon.svg?react";
import OutputTabIconResults from "assets/icons/OutputTabIconResults.svg?react";
import OutputTabIconStatistics from "assets/icons/OutputTabIconStatistics.svg?react";
import QueryHistoryIcon from "assets/icons/QueryHistoryIcon.svg?react";

import DocumentResults from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/DocumentResults";
import DocumentStatistics from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentStatistics/DocumentStatistics";
import EngineMonitoring from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/EngineMonitoring";
import QueryHistory from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/QueryHistory/QueryHistory";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import {
  DocumentOutputTab,
  Execution,
  ExecutionType,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import { ExplainIcon } from "components/Icons";
import { useLayoutToggles } from "components/LayoutToggles/context";

import { ExplainResults, hasExplain } from "./DocumentResults/ExplainResults";

import styles from "./DocumentOutput.module.scss";

interface DocumentOutputProps {
  document: WorkspaceDocument;
}

type HeaderProps = {
  handleTabClick: (tab: DocumentOutputTab) => void;
  activeTab: DocumentOutputTab | false;
  execution: Execution | null;
};

const ResultsHeader = (props: HeaderProps) => {
  const { handleTabClick, activeTab, execution } = props;
  const { qsResults } = useDocuments();
  const flags = useAllFlags();
  const { t } = useTranslation();
  const showExplain: boolean = !!execution?.queryStatements.find(statement => {
    const qsResult = qsResults[statement.id];

    return hasExplain(statement) && (qsResult?.data || statement.error);
  });

  const isQueryHistoryEnabled =
    flags[FeatureFlag.FireboltAppEnableEngineObservAndQueryHistory];
  const isEngineMonitoringEnabled =
    flags[FeatureFlag.FireboltAppEnableEngineObservAndQueryHistory];

  return (
    <div className={styles.tabsPanel}>
      <div className={styles.panel}>
        <div
          onClick={() => {
            handleTabClick(DocumentOutputTab.Results);
          }}
          data-testid="results-tab"
          className={cn(styles.tab, {
            [styles.active]: activeTab === DocumentOutputTab.Results,
          })}
        >
          <div className={styles.icon}>
            <OutputTabIconResults />
          </div>
          <div className={styles.label}>Results</div>
        </div>

        <div
          onClick={() => {
            handleTabClick(DocumentOutputTab.Statistics);
          }}
          className={cn(styles.tab, {
            [styles.active]: activeTab === DocumentOutputTab.Statistics,
          })}
          data-testid="statistics-tab"
        >
          <div className={styles.icon}>
            <OutputTabIconStatistics />
          </div>
          <div className={styles.label}>Statistics</div>
        </div>

        {showExplain && (
          <div
            onClick={() => {
              handleTabClick(DocumentOutputTab.Explain);
            }}
            className={cn(styles.tab, {
              [styles.active]: activeTab === DocumentOutputTab.Explain,
            })}
            data-testid="query-plan-tab"
          >
            <div className={styles.icon}>
              <ExplainIcon />
            </div>
            <div className={styles.label}>Query Profile</div>
          </div>
        )}

        {isEngineMonitoringEnabled && (
          <div
            onClick={() => {
              handleTabClick(DocumentOutputTab.EngineMonitoring);
            }}
            className={cn(styles.tab, {
              [styles.active]: activeTab === DocumentOutputTab.EngineMonitoring,
            })}
            data-testid="engine-metering-tab"
          >
            <div className={styles.icon}>
              <EngineMonitoringIcon />
            </div>
            <div className={styles.label}>{t("engine_monitoring.title")}</div>
          </div>
        )}

        {isQueryHistoryEnabled && (
          <div
            onClick={() => {
              handleTabClick(DocumentOutputTab.QueryHistory);
            }}
            className={cn(styles.tab, {
              [styles.active]: activeTab === DocumentOutputTab.QueryHistory,
            })}
            data-testid="query-history-tab"
          >
            <div className={styles.icon}>
              <QueryHistoryIcon />
            </div>
            <div className={styles.label}>{t("query_history.title")}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const ExplainHeader = () => {
  return (
    <div className={styles.tabsPanel}>
      <div className={cn(styles.tab, styles.active)}>
        <div className={styles.icon}>
          <ExplainIcon />
        </div>
        <div className={styles.label}>Query Plan</div>
      </div>
    </div>
  );
};

const Header = (props: {
  handleTabClick: (tab: DocumentOutputTab) => void;
  activeTab: DocumentOutputTab | false;
  executionType?: ExecutionType;
  execution: Execution | null;
}) => {
  const { executionType, execution, handleTabClick, activeTab } = props;
  if (executionType === ExecutionType.Explain) {
    return <ExplainHeader />;
  }
  return (
    <ResultsHeader
      handleTabClick={handleTabClick}
      activeTab={activeTab}
      execution={execution}
    />
  );
};

const DocumentOutput = (props: DocumentOutputProps) => {
  const { document } = props;
  const {
    actions: { switchActiveOutputTab, updateDocumentEngineMonitoring },
  } = useDocuments();
  const { data: engines } = useWorkspaceEngines({ includeSystemEngine: true });
  const { layout, setLayout } = useLayoutToggles();

  const engineName = document.context.engine.name;
  const activeTab = layout.results.expanded && document.layout.activeOutputTab;

  const handleTabClick = (tab: DocumentOutputTab) => {
    setLayout({
      ...layout,
      results: {
        ...layout.results,
        expanded: true,
      },
    });

    switchActiveOutputTab({
      documentId: document.id,
      activeOutputTab: tab,
    });
  };

  const renderQueryHistory = () => {
    const currentEngine = engines?.find(
      engine => engine.engineName === engineName
    );
    if (!currentEngine) {
      return null;
    }

    return (
      <QueryHistory
        engine={currentEngine}
        document={document}
      />
    );
  };

  const renderEngineMonitoring = () => {
    const currentEngine = engines?.find(
      engine => engine.engineName === engineName
    );
    if (!currentEngine) {
      return null;
    }

    return (
      <EngineMonitoring
        engine={currentEngine}
        document={document}
        onSettingsChange={engineMonitoring => {
          updateDocumentEngineMonitoring(document.id, engineMonitoring);
        }}
      />
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case DocumentOutputTab.Results:
        return <DocumentResults document={document} />;
      case DocumentOutputTab.Statistics:
        return <DocumentStatistics document={document} />;
      case DocumentOutputTab.Explain:
        return (
          <ExplainResults
            document={document}
            isProfile
          />
        );
      case DocumentOutputTab.QueryHistory:
        return renderQueryHistory();
      case DocumentOutputTab.EngineMonitoring:
        return renderEngineMonitoring();
      default:
        return null;
    }
  };

  return (
    <div className={styles.root}>
      <Header
        executionType={document?.execution?.executionType}
        handleTabClick={handleTabClick}
        activeTab={activeTab}
        execution={document.execution}
      />
      <div
        className={cn(styles.body, {
          [styles.hidden]: !layout.results.expanded,
        })}
      >
        {renderTabContent()}
      </div>
    </div>
  );
};

export default DocumentOutput;
