import { Popover } from "@mui/material";
import classNames from "classnames";

import ChevronDown from "assets/icons/ChevronDownSmall.svg?react";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import CustomTooltip from "components/Tooltip";

import styles from "./styles.module.scss";

interface ChipProps {
  title: string;
  className: string;
  inactive?: boolean; // means has no menu actions
  disabled?: boolean;
  inline?: boolean;
  inMenu?: boolean;
  items: {
    disabled?: boolean;
    title: string;
    action: () => void;
    testId?: string;
  }[];
  size?: "small" | "regular";
  handleClick?: () => void;
  testId?: string;
  tooltip?: React.ReactNode | string;
}

export const Chip = (props: ChipProps) => {
  const {
    items,
    disabled,
    inactive,
    inline,
    inMenu,
    title,
    size = "regular",
    className,
    handleClick,
    testId,
    tooltip,
  } = props;

  const { menuElement, openMenu, closeMenu } = useMenu();
  const menuItems = items.map(item => {
    const { disabled, title, action, testId } = item;

    const handleClick = (e: any) => {
      e.stopPropagation();
      closeMenu();
      action();
    };

    return (
      <ContextMenuItem
        key={title}
        text={title}
        onClick={handleClick}
        testId={testId}
        disabled={disabled}
      />
    );
  });
  return (
    <>
      <CustomTooltip
        testId="chip-tooltip"
        title={tooltip ?? ""}
        placement="top-start"
        tooltipTitleClassName={styles.tooltipTitle}
        arrow
      >
        <div
          className={classNames(styles.chip, className, {
            [styles.active]: !!menuElement,
            [styles.small]: size === "small",
            [styles.disabled]: disabled,
            [styles.inactive]: inactive,
            [styles.inline]: inline,
            [styles.inMenu]: inMenu,
            [styles.hasChevronIcon]: !!items.length,
          })}
          onClick={e => {
            if (!disabled && !inactive && !items.length && handleClick) {
              return handleClick();
            }
            if (!disabled && !inactive) {
              openMenu(e);
            }
          }}
          data-testid={testId ?? "status-chip"}
        >
          <div className={styles.title}>{title}</div>

          {!disabled && !inactive && !!items.length && (
            <div
              className={styles.chevronIcon}
              data-testid="chip-chevron-icon-container"
            >
              <ChevronDown />
            </div>
          )}
        </div>
      </CustomTooltip>

      {!disabled && !inactive && menuElement && !!menuItems.length && (
        <Popover
          data-testid="chip-popover"
          open={true}
          anchorEl={menuElement}
          onClose={() => {
            closeMenu();
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ContextMenu>{menuItems}</ContextMenu>
        </Popover>
      )}
    </>
  );
};
