import { Validations } from "utils/validations";

export enum ContactFormField {
  Name = "SuppliedName",
  FirstName = "firstName",
  LastName = "lastName",
  Account = "SuppliedCompany",
  Organization = "Web_Organization__c",
  Email = "SuppliedEmail",
  Subject = "Subject",
  Description = "description",
  Severity = "Severity__c",
  EngineName = "Engine_Name__c",
  Origin = "Origin",
  Type = "Type",
}

export enum SeverityValues {
  S1 = "S1 - Critical",
  S2 = "S2 - Urgent",
  S3 = "S3 - Tolerable",
  S4 = "S4 - Question",
}

export interface Fields {
  [ContactFormField.FirstName]: string;
  [ContactFormField.LastName]: string;
  [ContactFormField.Account]: string;
  [ContactFormField.Organization]: string;
  [ContactFormField.Email]: string;
  [ContactFormField.Severity]?: string;
  [ContactFormField.EngineName]?: string;
  [ContactFormField.Subject]: string;
  [ContactFormField.Description]?: string;
}

export interface DisabledFields {
  [ContactFormField.FirstName]?: boolean;
  [ContactFormField.LastName]?: boolean;
  [ContactFormField.Account]?: boolean;
  [ContactFormField.Organization]?: boolean;
  [ContactFormField.Email]?: boolean;
  [ContactFormField.Severity]?: boolean;
  [ContactFormField.EngineName]?: boolean;
  [ContactFormField.Subject]?: boolean;
  [ContactFormField.Description]?: boolean;
}

export interface ErrorFields {
  [ContactFormField.FirstName]?: string;
  [ContactFormField.LastName]?: string;
  [ContactFormField.Account]?: string;
  [ContactFormField.Organization]?: string;
  [ContactFormField.Email]?: string;
  [ContactFormField.Severity]?: string;
  [ContactFormField.EngineName]?: string;
  [ContactFormField.Subject]?: string;
  [ContactFormField.Description]?: string;
}

export const SEVERITY_OPTIONS = [
  { value: SeverityValues.S1, text: "contact.severityCritical" },
  { value: SeverityValues.S2, text: "contact.severityUrgent" },
  { value: SeverityValues.S3, text: "contact.severityTolerable" },
  { value: SeverityValues.S4, text: "contact.severityQuestion" },
];

export const REQUIRED_FIELDS = [
  ContactFormField.FirstName,
  ContactFormField.LastName,
  ContactFormField.Account,
  ContactFormField.Organization,
  ContactFormField.Email,
  ContactFormField.Subject,
];

export const VALIDATION_BY_FIELD = {
  [ContactFormField.FirstName]: {
    maxLength: 35,
  },
  [ContactFormField.LastName]: {
    maxLength: 35,
  },
  [ContactFormField.Account]: {
    maxLength: 80,
  },
  [ContactFormField.Organization]: {
    maxLength: 80,
  },
  [ContactFormField.Email]: {
    maxLength: 80,
    validate: Validations.isValidEmail,
  },
  [ContactFormField.Subject]: {
    maxLength: 255,
  },
  [ContactFormField.EngineName]: {
    maxLength: 255,
  },
  [ContactFormField.Description]: {
    maxLength: 32000,
  },
};

export enum ERROR_TYPES {
  Empty = "emptyError",
  MaxLength = "maxLengthError",
  InvalidField = "invalidFieldError",
}

export interface ErrorObject {
  fieldName: string;
  errorType: ERROR_TYPES;
  inputForErrorMessage?: string | number;
}

export const TEST_IDS = {
  firstNameInput: "contact-first-name-field",
  lastNameInput: "contact-last-name-field",
  accountInput: "contact-account-field",
  emailInput: "contact-email-field",
  engineNameInput: "contact-engine-name-field",
  subjectInput: "contact-subject-field",
  descriptionInput: "contact-description-field",
  severitySelect: "contact-severity-selector",
  severityValue: "severity-value-",
  submitForm: "submit-contact-form",
  cancelForm: "cancel-contact-form",
};
