export enum OwnershipObjectType {
  database = "database",
  table = "table",
  view = "view",
  engine = "engine",
  role = "role",
  user = "user",
}

type OwnershipRecordBase = {
  key: string;
  type: OwnershipObjectType;
  name: string;
  owner: string;
  created: string;
  lastModified: string;
};

type OwnershipRecordWithParentName = OwnershipRecordBase & {
  parentName: string;
};

export type OwnershipRecord =
  | (OwnershipRecordBase & {
      type:
        | OwnershipObjectType.database
        | OwnershipObjectType.engine
        | OwnershipObjectType.user
        | OwnershipObjectType.role;
      parentName?: string;
    })
  | (OwnershipRecordWithParentName & {
      type: OwnershipObjectType.table | OwnershipObjectType.view;
    });

export interface AccountOwnershipFiltersType {
  nameContains: string;
  objectTypes: OwnershipObjectType[];
  owners: string[];
}
