import { systemEngineEnvironment } from "services/environment/systemEngine";

export const transferRoleOwnership = async ({
  roleName,
  newOwner,
}: {
  roleName: string;
  newOwner: string;
}) => {
  await systemEngineEnvironment.execute(
    `ALTER ROLE "${roleName}" OWNER TO "${newOwner}";`
  );
};
