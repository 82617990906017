import { useSuspenseQuery } from "@tanstack/react-query";

import { ReactQueryKeysAccount } from "services/queryKeys";
import { getUsers } from "services/users/getUsers";

import { useCurrentAccount } from "components/Account/useCurrentAccount";

import { User } from "./user.types";

export const useUsers = (options?: { byOwnerUsername?: string }): User[] => {
  const { getAccount } = useCurrentAccount();
  const currentAccount = getAccount();

  const queryKey: string[] = [ReactQueryKeysAccount.users];

  if (options?.byOwnerUsername) {
    queryKey.push(`BY_OWNER-${options.byOwnerUsername}`);
  }

  const { data } = useSuspenseQuery<User[], Error>({
    queryKey,
    queryFn: async () => {
      if (!currentAccount) {
        return [];
      }
      return await getUsers(currentAccount.id, undefined, options);
    },
  });

  return data || [];
};
