export const CANCEL_RESULT_PLACEHOLDER = {
  data: [{ "": "Query execution has been canceled.", No: 1 }],
  meta: [
    {
      name: "No",
      type: "",
      displayName: "No",
    } as any,
    {
      name: "",
      type: "string",
      displayName: "",
    },
  ],
  rows: 1,
};
