import { useTranslation } from "react-i18next";
import { SYSTEM_ENGINE_DOCS_URL } from "utils/constants";

import TooltipMessage from "components/Tooltip/TooltipMessage/TooltipMessage";

import styles from "../ContextSelector.module.scss";

export const SystemEngineTooltipTitle = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.tooltip__body}>
      <span className={styles.tooltip__title}>
        {t("workspace.context_selector.system_engine")}
      </span>
      <a
        href={SYSTEM_ENGINE_DOCS_URL}
        target="_blank"
        className={styles.tooltip__link}
        rel="noreferrer"
      >
        {t("workspace.context_selector.system_engine_documentation")}
      </a>
    </div>
  );
};

export const EngineOperateTooltipTitle = () => {
  const { t } = useTranslation();
  return (
    <TooltipMessage
      testId="engine-operate-no-permission-tooltip"
      title={t("workspace.engine_chip.no_permission")}
      description={t("workspace.engine_chip.no_permission_desc")}
    />
  );
};

export const EngineContextErrorTooltipTitle = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className={styles.tooltip__body}>
      <span className={styles.tooltip__title}>{title}</span>
      <span className={styles.tooltip__description}>{description}</span>
    </div>
  );
};
