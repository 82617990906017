import { useTranslation } from "react-i18next";

import { SqlRbacAction } from "services/rbac/action";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { useAccessManager } from "components/App/accessManager";
import Button, { ButtonSize, ButtonTemplate } from "components/Button";
import { useLoadData } from "components/LeftSidebar/CreateMenu/useLoadData";

import styles from "./EmptyDatabasesList.module.scss";

export const EmptyDatabasesList = () => {
  const { t } = useTranslation();
  const { openLoadData, loadDataMarkup } = useLoadData();
  const { isAllowedTo } = useAccessManager();
  const { getAccount } = useCurrentAccount();
  const account = getAccount();
  const canCreateDatabase = isAllowedTo(
    "account",
    account?.accountName as string,
    SqlRbacAction.DATABASE_CREATE
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{t("workspace.databases.empty")}</div>
        <div className={styles.subtitle}>
          {t("workspace.databases.empty_description")}
        </div>
        {canCreateDatabase && (
          <Button
            template={ButtonTemplate.Tertiary}
            text={t("workspace.databases.load_data")}
            onClick={() => {
              openLoadData();
            }}
            size={ButtonSize.Small}
            dataTestid="load-data-from-empty-db-context"
          />
        )}
      </div>
      {loadDataMarkup}
    </>
  );
};
