import { CSSProperties } from "react";

export type ColumnType = {
  id: string;
  name: string;
  width: number;
  fillAvailableWidth?: boolean;
  disableResize?: boolean;
  disableColumnFilter?: boolean;
  sortable?: boolean;
  className?: string;
  orderIdentity?: (item: unknown) => unknown;
  initiallyVisible?: boolean;
};

export type HeaderCellType = {
  column: ColumnType;
  fillAvailableWidth?: boolean;
  isDragging: boolean;
  style: CSSProperties;
  onResize: (dragOffset: number) => void;
  onResizeFinish: () => void;
  onResizeStart: (column?: ColumnType) => void;
  dragOffset: number;
  order?: "asc" | "desc";
  orderBy?: string | null;
  disableResize?: boolean;
  handleSort: (fieldId: string) => void;
  toggleOrder: () => void;
  sortable?: boolean;
  className?: string;
};

export const ORDER_ASC = "asc";
export const ORDER_DESC = "desc";
