import { useQueryClient } from "@tanstack/react-query";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { User } from "services/users/user.types";

import OwnerSelector from "components/AccountOwnership/TransferOnwershipModalSingle/OwnerSelector/OwnerSelector";
import { canModifyOwnershipObject } from "components/AccountOwnership/checkObjectPermission";
import { DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE } from "components/AccountOwnership/constants";
import {
  OwnershipObjectType,
  OwnershipRecord,
} from "components/AccountOwnership/types";
import useTransferOwnershipActions, {
  TransferOwnershipActionType,
} from "components/AccountOwnership/useTransferOwnershipActions";
import { useAccessManager } from "components/App/accessManager";
import { ButtonTemplate } from "components/Button";
import Checkbox from "components/Checkbox";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { Cell, Row } from "components/FlatTable";
import { VirtualizedTable } from "components/FlatTable/VirtualizedTable";
import LoadingOverlap from "components/LoadingOverlap/LoadingOverlap";
import { Modal } from "components/Modal/Modal";
import {
  InlineNotification,
  NotificationType,
} from "components/Notification/InlineNotification";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import styles from "./TransferOwnershipModalBulk.module.scss";

interface Props {
  objects: OwnershipRecord[];
  onClose: () => void;
  isLoadingObjects: boolean;
  onTransferDone: () => void;
  isRunningDeleteUserMutation: boolean;
  users: User[];
  userName?: string;
  deleteFlow?: boolean;
}

type FormOwnershipRecord = OwnershipRecord & {
  newOwner: string;
  selected: boolean;
  errorMessage: string;
  canModify: boolean;
  disabled: boolean;
};

const TransferOwnershipModalBulk = (props: Props) => {
  const {
    objects,
    onClose,
    isLoadingObjects,
    onTransferDone,
    isRunningDeleteUserMutation,
    users,
    userName,
    deleteFlow,
  } = props;

  const queryClient = useQueryClient();
  const { actionHandlers } = useTransferOwnershipActions();
  const [errorSummary, setErrorSummary] = useState<string[]>([]);
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();
  const accessManager = useAccessManager();
  const [transferProgress, setTransferProgress] = useState({
    done: 0,
    total: 0,
  });

  const mapToFormObjects = (
    objects: OwnershipRecord[]
  ): FormOwnershipRecord[] => {
    return objects.map(object => {
      return {
        ...object,
        newOwner: object.owner,
        selected: false,
        errorMessage: "",
        disabled: false,
        canModify: canModifyOwnershipObject(object, accessManager),
      };
    });
  };

  const [formObjects, setFormObjects] = useState<FormOwnershipRecord[]>(
    mapToFormObjects(objects)
  );

  const normalizedFormObjects = formObjects.map(object => {
    if (
      object.type === OwnershipObjectType.table ||
      object.type === OwnershipObjectType.view
    ) {
      const parentDatabase = formObjects.find(
        obj =>
          obj.type === OwnershipObjectType.database &&
          obj.name === object.parentName
      );
      if (
        parentDatabase?.selected &&
        parentDatabase.newOwner === DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE
      ) {
        return {
          ...object,
          selected: true,
          disabled: true,
          newOwner: parentDatabase.newOwner,
        };
      }
    }
    return object;
  });

  const [showCommonOwner, setShowCommonOwner] = useState(false);

  const availableObjectTypes = Object.values(OwnershipObjectType).filter(
    objectType =>
      normalizedFormObjects.some(
        object => object.type === objectType && object.canModify
      )
  );

  const [isRunningTransfer, setIsRunningTransfer] = useState(false);

  const disabledControls = isRunningTransfer || isLoadingObjects;

  const applyToAllSelected = (newOwner: string) => {
    setFormObjects((prevObjects: FormOwnershipRecord[]) => {
      return prevObjects.map(prevObject => {
        if (prevObject.selected) {
          return { ...prevObject, newOwner };
        }
        return prevObject;
      });
    });

    setShowCommonOwner(false);
  };

  const selectObjectsByType = (types: OwnershipObjectType[]) => {
    setFormObjects((prevObjects: FormOwnershipRecord[]) => {
      return prevObjects.map(prevObject => {
        if (types.includes(prevObject.type) && prevObject.canModify) {
          return { ...prevObject, selected: true };
        }
        return prevObject;
      });
    });
  };

  const selectedObjects = normalizedFormObjects.filter(
    object => object.selected
  );

  useEffect(() => {
    if (selectedObjects.length) {
      setShowCommonOwner(true);
    }
  }, [selectedObjects.length]);

  const objectsToTransfer = selectedObjects.filter(
    object => object.newOwner && object.newOwner !== object.owner
  );

  const runTransfer = async () => {
    if (objectsToTransfer.length === 0) {
      return;
    }

    setErrorSummary([]);
    setIsRunningTransfer(true);

    // reset all errors for objectsToTransfer
    setFormObjects((prevObjects: FormOwnershipRecord[]) => {
      return prevObjects.map(prevObject => {
        if (objectsToTransfer.find(object => object.key === prevObject.key)) {
          return { ...prevObject, errorMessage: "" };
        }
        return prevObject;
      });
    });

    const keysToInvalidate = new Set<string | undefined>();

    const failedObjects: FormOwnershipRecord[] = [];

    const promises = objectsToTransfer.map(async object => {
      keysToInvalidate.add(
        actionHandlers[object.type].keyToInvalidate(object)[0]
      );

      if (
        object.type === OwnershipObjectType.table ||
        object.type === OwnershipObjectType.view
      ) {
        const parentDatabase = objectsToTransfer.find(
          obj =>
            obj.type === OwnershipObjectType.database &&
            obj.name === object.parentName
        );

        if (
          parentDatabase?.newOwner === DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE
        ) {
          return new Promise(resolve => {
            setTransferProgress(prevProgress => {
              return {
                ...prevProgress,
                done: prevProgress.done + 1,
              };
            });

            resolve(true);
          });
        }
      }

      if (object.newOwner === DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE) {
        try {
          await actionHandlers[object.type][TransferOwnershipActionType.DELETE](
            object
          );
        } catch (e: any) {
          failedObjects.push({
            ...object,
            errorMessage: e.message,
          });
        } finally {
          setTransferProgress(prevProgress => {
            return {
              ...prevProgress,
              done: prevProgress.done + 1,
            };
          });
        }
        return;
      }

      try {
        await actionHandlers[object.type][
          TransferOwnershipActionType.TRANSFER_OWNERSHIP
        ](object, object.newOwner);
      } catch (e: any) {
        failedObjects.push({
          ...object,
          errorMessage: e.message,
        });
      } finally {
        setTransferProgress(prevProgress => {
          return {
            ...prevProgress,
            done: prevProgress.done + 1,
          };
        });
      }
    });

    setTransferProgress({
      done: 0,
      total: promises.length,
    });

    await Promise.all(promises);

    for (const key of keysToInvalidate) {
      if (key) {
        // eslint-disable-next-line no-await-in-loop -- we need to invalidate queries one by one
        await queryClient.invalidateQueries({
          queryKey: [key],
        });
      }
    }

    setIsRunningTransfer(false);
    setTransferProgress({
      done: 0,
      total: 0,
    });

    if (failedObjects.length) {
      setErrorSummary(failedObjects.map(obj => obj.errorMessage));
    }

    if (!failedObjects.length) {
      putStatusMessage({
        message: t(
          `account_ownership.snackbar_messages.ownership_transferred_bulk`,
          { count: objectsToTransfer.length }
        ),
        type: StatusMessageType.Success,
      });
    }

    if (
      !failedObjects.length &&
      objectsToTransfer.length === normalizedFormObjects.length
    ) {
      onTransferDone();
    }

    setFormObjects((prevObjects: FormOwnershipRecord[]) => {
      const newObjects = prevObjects
        .map(object => {
          const wasFailed = failedObjects.find(
            failedObject => failedObject.key === object.key
          );

          if (wasFailed) {
            return { ...object, errorMessage: wasFailed.errorMessage };
          }
          return object;
        })
        .filter(object => {
          const wasSelected = objectsToTransfer.find(
            selectedObject => selectedObject.key === object.key
          );
          const wasFailed = failedObjects.find(
            failedObject => failedObject.key === object.key
          );

          if (wasSelected && !wasFailed) {
            return false;
          }

          return true;
        });

      return newObjects;
    });
  };

  const renderTable = () => {
    const rowRenderer = ({ row }: { row: unknown }) => {
      const item = row as FormOwnershipRecord;

      return (
        <Row
          key={item.key}
          testId={`ownership-row-${item.key}`}
          noBorder={true}
        >
          <Cell id="name">
            <div className={styles.checkCell}>
              <Checkbox
                disabled={disabledControls || !item.canModify || item.disabled}
                checked={item.selected}
                onChange={() => {
                  setFormObjects((prevObjects: FormOwnershipRecord[]) => {
                    return prevObjects.map(prevObject => {
                      if (prevObject.key === item.key) {
                        return {
                          ...prevObject,
                          selected: !prevObject.selected,
                        };
                      }
                      return prevObject;
                    });
                  });
                }}
              />

              <span>
                {item.parentName ? (
                  <span className={styles.parentName}>
                    {item.parentName + "/"}
                  </span>
                ) : null}
                {item.name}
              </span>
            </div>
          </Cell>

          <Cell id="type">
            {t(`transfer_ownership.object_type.${item.type}`)}
          </Cell>
          <Cell id="newOwner">
            <OwnerSelector
              disabled={disabledControls || !item.canModify || item.disabled}
              cellView={true}
              className={styles.ownerSelector}
              errorMessage={item.errorMessage}
              onUserSelect={userName => {
                setFormObjects((prevObjects: FormOwnershipRecord[]) => {
                  return prevObjects.map(prevObject => {
                    if (prevObject.key === item.key) {
                      return {
                        ...prevObject,
                        newOwner: userName,
                        errorMessage: "",
                      };
                    }
                    return prevObject;
                  });
                });
              }}
              selectedUser={item.newOwner}
              users={users}
            />
          </Cell>

          <Cell id="created">{item.created}</Cell>
        </Row>
      );
    };

    const rows = normalizedFormObjects;

    const tableHead = [
      {
        id: "name",
        name: t("ownership.columns.name"),
        width: 240,
        sortable: true,
        initiallyVisible: true,
      },
      {
        id: "type",
        name: t("ownership.columns.type"),
        width: 140,
        sortable: true,
        initiallyVisible: true,
      },
      {
        id: "newOwner",
        name: t("ownership.columns.owner"),
        width: 240,
        sortable: true,
        initiallyVisible: true,
      },
      {
        id: "created",
        name: t("ownership.columns.created"),
        width: 200,
        sortable: true,
        initiallyVisible: true,
      },
    ];

    return (
      <VirtualizedTable
        dataTestId="transfer-ownership-table"
        rowRenderer={rowRenderer}
        rows={rows}
        columns={tableHead}
        floatingColumn="action"
        sortable
        disableResize={false}
      />
    );
  };

  const progressBar = (
    <div
      className={cn(styles.progressBar, {
        [styles.inactive]: !transferProgress.total || transferProgress.done < 5,
      })}
    >
      {!!transferProgress.total && (
        <div
          className={styles.progressBarInner}
          style={{
            width: `${(transferProgress.done / transferProgress.total) * 100}%`,
          }}
        />
      )}
    </div>
  );

  const noObjectsFound =
    normalizedFormObjects.length === 0 && !isLoadingObjects;

  const getPrimaryButtonText = () => {
    if (noObjectsFound) {
      return t("transfer_ownership_modal_bulk.close");
    }

    if (isRunningTransfer) {
      return t(
        "transfer_ownership_modal_bulk.transfer_ownership_button_in_progress"
      );
    }

    return t("transfer_ownership_modal_bulk.transfer_ownership_button");
  };

  const renderContent = () => {
    if (noObjectsFound) {
      return (
        <div className={styles.errorWrapper}>
          <InlineNotification
            type={NotificationType.Error}
            title={t(
              "transfer_ownership_modal_bulk.objects_no_found_error.title"
            )}
            body={
              <div>
                {t(
                  "transfer_ownership_modal_bulk.objects_no_found_error.description"
                )}
              </div>
            }
          />
        </div>
      );
    }

    return (
      <>
        {deleteFlow && (
          <div className={styles.description}>
            <Trans
              i18nKey="transfer_ownership_modal_bulk.description"
              values={{
                userName,
              }}
              testId="transfer-ownership-modal-bulk-description"
              components={{
                span: <span className={styles.username} />,
              }}
            />
          </div>
        )}
        <div className={styles.selectorsPanel}>
          {!!availableObjectTypes.length && (
            <OutlinedSelect
              className={styles.select}
              testId="common-object-type-select"
              onSelect={(items: string[]) => {
                if (items[0] === "all") {
                  if (
                    selectedObjects.length ===
                    normalizedFormObjects.filter(obj => obj.canModify).length
                  ) {
                    setFormObjects((prevObjects: FormOwnershipRecord[]) => {
                      return prevObjects.map(prevObject => {
                        return { ...prevObject, selected: false };
                      });
                    });
                  } else {
                    setShowCommonOwner(true);
                    selectObjectsByType(availableObjectTypes);
                  }

                  return;
                }
                setShowCommonOwner(true);
                selectObjectsByType(items as OwnershipObjectType[]);
              }}
              multiple={false}
              disabled={disabledControls}
              renderValue={() => {
                if (selectedObjects.length > 0) {
                  return (
                    <span className={styles.selectorPlaceholder}>
                      <span className={styles.prefix}>
                        {t("transfer_ownership_modal_bulk.selected")}{" "}
                      </span>
                      {t("transfer_ownership_modal_bulk.selected_objects", {
                        count: selectedObjects.length,
                      })}
                    </span>
                  );
                }

                return (
                  <span className={styles.selectorPlaceholder}>
                    {t("transfer_ownership_modal_bulk.select_objects")}
                  </span>
                );
              }}
              controlledValue={[]}
            >
              {[
                <ContextMenuItem
                  value="all"
                  key="all"
                  testId="common-object-type-item-all"
                  text={
                    selectedObjects.length ===
                    normalizedFormObjects.filter(obj => obj.canModify).length
                      ? t("transfer_ownership_modal_bulk.none")
                      : t("transfer_ownership_modal_bulk.all")
                  }
                  secondaryText={normalizedFormObjects
                    .filter(obj => obj.canModify)
                    .length.toString()}
                />,
                ...availableObjectTypes.map(objectType => {
                  return (
                    <ContextMenuItem
                      value={objectType}
                      key={objectType}
                      secondaryText={normalizedFormObjects
                        .filter(obj => obj.type === objectType && obj.canModify)
                        .length.toString()}
                      testId={`common-object-type-item-${objectType}`}
                      text={t(
                        `transfer_ownership_modal_bulk.select.select_${objectType}`
                      )}
                    />
                  );
                }),
              ]}
            </OutlinedSelect>
          )}

          {!!selectedObjects.length && showCommonOwner && (
            <>
              <div className={styles.label}>
                {t("transfer_ownership_modal_bulk.transfer_ownership_to")}
              </div>

              <OwnerSelector
                disabled={disabledControls}
                onUserSelect={userName => {
                  applyToAllSelected(userName);
                }}
                selectedUser=""
                users={users}
              />
            </>
          )}
        </div>

        {noObjectsFound ? null : (
          <div className={styles.table}>
            {!!normalizedFormObjects.length && renderTable()}
          </div>
        )}

        {isLoadingObjects && (
          <LoadingOverlap
            isLoading={true}
            title={t("transfer_ownership_modal_bulk.loading_objects")}
          />
        )}

        {!!errorSummary.length && (
          <div className={styles.errorSummary}>
            <InlineNotification
              type={NotificationType.Error}
              title={t("transfer_ownership_modal_bulk.error_summary_title")}
              body={
                <div>
                  {errorSummary.map(error => (
                    <div key={uuidv4()}>{error}</div>
                  ))}
                </div>
              }
            />
          </div>
        )}
      </>
    );
  };

  return (
    <Modal
      title={t("transfer_ownership_modal_bulk.title")}
      onSubmit={e => {
        if (noObjectsFound) {
          onClose();
          return;
        }

        e.preventDefault();
        if (
          !isRunningTransfer ||
          !isLoadingObjects ||
          isRunningDeleteUserMutation
        ) {
          runTransfer();
        }
      }}
      maxWidth="lg"
      borderedFooter={true}
      primaryButton={getPrimaryButtonText()}
      onClose={() => {
        if (!isRunningTransfer) {
          onClose();
        }
      }}
      footer={<>{progressBar}</>}
      hideCancel={isRunningTransfer || noObjectsFound}
      primaryButtonTemplate={
        isRunningTransfer ? ButtonTemplate.Danger : ButtonTemplate.Primary
      }
      isLoading={false}
      disabledSubmit={noObjectsFound ? false : objectsToTransfer.length === 0}
    >
      <div className={styles.modalContent}>{renderContent()}</div>
    </Modal>
  );
};

export default TransferOwnershipModalBulk;
