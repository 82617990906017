import { Dispatch, SetStateAction, useEffect } from "react";

import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import { EngineContextSelector } from "components/composite/ContextSelector/variations/EngineContextSelector";

import { IngestionStep, WizardDataType } from "../types";

import styles from "./styles.module.scss";

type Props = {
  wizardData: WizardDataType;
  updateEngine: (engineName: string) => void;
  setActiveStep: Dispatch<SetStateAction<IngestionStep>>;
  disabled: boolean;
};

export const EngineSelector = (props: Props) => {
  const { wizardData, updateEngine, setActiveStep, disabled } = props;

  const engineData = wizardData[IngestionStep.selectEngine] as {
    type?: string;
    engine: {
      engineName: string;
    };
  };

  const {
    engine: { engineName },
  } = engineData;

  const { data: engines = [] } = useWorkspaceEngines();

  useEffect(() => {
    const engine = engines.find(engine => engine.engineName === engineName);
    if (!engine) {
      updateEngine("");
      setActiveStep(IngestionStep.selectEngine);
    }
  }, [engineName, engines, updateEngine, setActiveStep]);

  return (
    <div className={styles.root}>
      <EngineContextSelector
        active
        isDisabled={disabled}
        selectedEngineName={engineName}
        onSelectEngine={newEngineName => {
          updateEngine(newEngineName);
        }}
      />
    </div>
  );
};
