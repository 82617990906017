import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Search } from "components/LeftSidebar/Search";

import { MissingPermission } from "../MissingPermissionError/MissingPermission";
import { ROLES_WIZARD_ADD_GROUP_EMPTY } from "../privilegesReducer";
import { PrivilegesState } from "../types";
import { usePrivilegesState } from "../usePrivilegesContext";
import { AccountGroup } from "./AccountGroup";
import { DatabasesGroup } from "./DatabasesGroup";
import { EnginesGroup } from "./EnginesGroup";
import { SchemasGroup } from "./SchemasGroup";
import { TablesGroup } from "./TablesGroup";
import { ViewsGroup } from "./ViewsGroup";

import styles from "./styles.module.scss";

const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.row}>
      <div className={classNames(styles.column, styles.column__objects)}>
        {t("roles_wizard.privileges.column_objects")}
      </div>
      <div className={classNames(styles.column, styles.column__granted)}>
        {t("roles_wizard.privileges.column_granted")}
      </div>
    </div>
  );
};

export const PrivilegesTable = () => {
  const { t } = useTranslation();
  const sections = [
    {
      type: "account",
      title: t("roles_wizard.privileges.column_accounts"),
      Component: AccountGroup,
      headerAction: true,
    },
    {
      type: "engine",
      title: t("roles_wizard.privileges.column_engines"),
      Component: EnginesGroup,
      headerAction: true,
    },
    {
      type: "database",
      title: t("roles_wizard.privileges.column_databases"),
      Component: DatabasesGroup,
      headerAction: true,
    },
    {
      type: "schema",
      title: t("roles_wizard.privileges.column_schemas"),
      Component: SchemasGroup,
      headerAction: true,
    },
    {
      type: "table",
      title: t("roles_wizard.privileges.column_tables"),
      Component: TablesGroup,
      headerAction: true,
    },
    {
      type: "view",
      title: t("roles_wizard.privileges.column_views"),
      Component: ViewsGroup,
      headerAction: true,
    },
  ];

  const [search, setSearch] = useState("");

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const { privilegesState, privilegesDispatch } = usePrivilegesState();

  const filteredSections = sections.filter(({ title }) =>
    title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  return (
    <div className={styles.table}>
      <div className={styles.table__inner}>
        <div className={styles.table__header}>
          <TableHeader />
          <Search
            value={search}
            onChange={handleChangeSearch}
            placeholder="Search"
            testId="search-roles"
            className={styles.search}
          />
        </div>
        {filteredSections.map(section => {
          const { Component, title, type, headerAction } = section;
          const errors =
            privilegesState.permissionErrors?.[
              type as keyof typeof privilegesState.permissionErrors
            ];
          const sectionGroups = privilegesState[type as keyof PrivilegesState];
          return (
            <div
              className={styles.resource}
              key={title}
              data-testid={`roles-wizard-section-${type}`}
            >
              <div className={styles.resource__header}>
                {title}
                {headerAction && (
                  <div
                    data-testid={`add-new-group-${type}`}
                    className={styles.addGroup}
                    onClick={() => {
                      privilegesDispatch({
                        type: ROLES_WIZARD_ADD_GROUP_EMPTY,
                        resource: type,
                        objects: [],
                      });
                    }}
                  >
                    {t("roles_wizard.privileges.button_add_group")}
                  </div>
                )}
              </div>
              <div
                className={styles.sectionGroup}
                data-testid={`privileges-groups-${type}`}
              >
                {sectionGroups.map((_group, index) => {
                  return (
                    <Component
                      // eslint-disable-next-line react/no-array-index-key -- it's fine here
                      key={index}
                      index={index}
                      resource={type}
                    />
                  );
                })}
                {errors?.length ? (
                  <MissingPermission
                    errors={errors}
                    resource={type}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
