import React, { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clearLocalStorage, {
  LOCAL_STORAGE_CLEAR_MESSAGE_ID,
} from "utils/localStorageCleaner";

import { useLoginId } from "hooks/useLoginId";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import Button, { ButtonTemplate } from "components/Button";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

/**
 * @description
 * Hook for storing state in local storage
 *
 * @param key - IMPORTANT: if you make changes that are not backwards compatible (state structure),
 * always change the key (add suffix v2 so on)
 * @param initialState - as for useState
 * @param options - it can automatically add loginId/accountId to the key to make it unique per user/account for the same type of data
 */
const usePersistedState = <T,>(
  key: string,
  initialState: T,
  options: { loginDependent?: boolean; accountDependent?: boolean } = {
    loginDependent: true,
    accountDependent: false,
  }
): [T, React.Dispatch<SetStateAction<T>>] => {
  const { loginDependent, accountDependent } = options;
  const { loginId } = useLoginId();
  const { getAccount } = useCurrentAccount();

  const { putStatusMessage, removeStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();

  const getKey = () => {
    const segments = [key];

    if (loginDependent && loginId) {
      segments.push(loginId);
    }

    const account = getAccount();
    if (accountDependent && account) {
      segments.push(account.id);
    }

    return segments.join("|");
  };

  const KEY = getKey();

  const [state, setState] = useState<T>(() => {
    const storageValue = localStorage.getItem(KEY);

    if (storageValue) {
      try {
        return JSON.parse(storageValue);
      } catch (e: any) {
        console.error('State for key "' + KEY + '" is invalid', e.message);
        return initialState;
      }
    } else {
      return initialState;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(KEY, JSON.stringify(state));
    } catch (e) {
      console.error('Error saving state for key "' + KEY);
      if (e instanceof DOMException && e.name === "QuotaExceededError") {
        putStatusMessage({
          type: StatusMessageType.Error,
          message: t("local_storage.quota_exceeded"),
          buttons: (
            <>
              <Button
                text={t("local_storage.clear_message")}
                template={ButtonTemplate.Tertiary}
                onClick={e => {
                  e.preventDefault();
                  clearLocalStorage();
                  removeStatusMessage(LOCAL_STORAGE_CLEAR_MESSAGE_ID);
                  putStatusMessage({
                    type: StatusMessageType.Success,
                    message: t("local_storage.clear_success"),
                  });
                }}
              />
            </>
          ),
          options: {
            insertToPosition: StatusMessagePosition.Top,
            autoRemove: true,
            id: LOCAL_STORAGE_CLEAR_MESSAGE_ID,
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, KEY]);

  return [state, setState];
};

export default usePersistedState;
