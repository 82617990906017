import classNames from "classnames";
import React, { useRef, useState } from "react";

import Minus from "assets/icons/Minus.svg?react";
import PlusIcon from "assets/icons/Plus.svg?react";

import styles from "./styles.module.scss";

type Props = {
  disabled?: boolean;
  value: number;
  maxValue?: number;
  name: string;
  testId?: string;
  errorMessage?: string;
  onChange: (value: number) => void;
  validationError?: string;
};

const Ticker = (props: {
  disabled?: boolean;
  onDecrement: () => void;
  onIncrement: () => void;
}) => {
  const { disabled, onDecrement, onIncrement } = props;
  return (
    <div className={classNames(styles.ticker, { [styles.disabled]: disabled })}>
      <button
        className={classNames(styles.tickerItem)}
        onClick={event => {
          event.preventDefault();
          !disabled && onDecrement();
        }}
        data-testid="ticker-decrement"
      >
        <Minus />
      </button>
      <button
        className={styles.tickerItem}
        onClick={event => {
          event.preventDefault();
          !disabled && onIncrement();
        }}
        data-testid="ticker-increment"
      >
        <PlusIcon />
      </button>
    </div>
  );
};

export const NumberInput = (props: Props) => {
  const {
    value,
    maxValue = 128,
    onChange,
    name,
    testId,
    errorMessage,
    validationError,
    disabled,
  } = props;
  const input = useRef<HTMLInputElement | null>(null);
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState(false);

  const handleChangeValue = (input: string | number) => {
    if (disabled) return;
    if (Number.isNaN(+input)) {
      setError(true);
      return;
    }
    const clapped = input === "" ? 0 : +input;
    onChange(clapped);
    if (clapped > maxValue || clapped < 1) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleDecrement = () => {
    if (disabled) return;
    const clapped = Math.max(value - 1, 1);
    if (input.current) {
      input.current.value = `${clapped}`;
    }
    handleChangeValue(clapped);
  };

  const handleIncrement = () => {
    const clapped = Math.min(value + 1, maxValue);
    if (input.current) {
      input.current.value = `${clapped}`;
    }
    handleChangeValue(clapped);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeValue(event.target.value);
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.wrapper, {
          [styles.focused]: focus,
          [styles.error]: (error && !!errorMessage) || validationError,
          [styles.disabled]: disabled,
        })}
      >
        <input
          ref={input}
          type="text"
          autoComplete="off"
          defaultValue={value}
          name={name}
          onChange={handleChangeInput}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className={styles.input}
          data-testid={testId}
          disabled={disabled}
        />
        <Ticker
          disabled={disabled}
          onDecrement={handleDecrement}
          onIncrement={handleIncrement}
        />
      </div>
      {validationError && (
        <p
          data-testid={`${testId}-error-message`}
          className={styles.helperText}
        >
          {validationError}
        </p>
      )}
      {error && !!errorMessage && !validationError && (
        <p
          data-testid={`${testId}-error-message`}
          className={styles.helperText}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};
