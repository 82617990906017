import { QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";

import HelmetWrapper from "components/DocumentTitle/HelmetWrapper";
import StatusMessageQueueProvider from "components/StatusMessageQueue/StatusMessageQueueProvider";
import { router } from "components/browserRouter";

import { queryClient } from "../queryClient";
import { AccessManagerContext, accessManager } from "./accessManager";

type Props = {
  onFinishRender: () => void;
};

const helmetContext = {};

const App = (props: Props) => {
  const { onFinishRender } = props;

  useEffect(() => {
    onFinishRender && onFinishRender();
  }, [onFinishRender]);

  useEffect(() => {
    // @ts-ignore
    window?.pendo?.initialize({
      visitor: {
        id: "VISITOR-UNIQUE-ID", // pendo treats this as anonymous
      },
    });
  }, []);

  return (
    <AccessManagerContext.Provider value={accessManager}>
      <QueryClientProvider client={queryClient}>
        <StatusMessageQueueProvider>
          <HelmetProvider context={helmetContext}>
            <HelmetWrapper />
            <RouterProvider router={router} />
          </HelmetProvider>
        </StatusMessageQueueProvider>
      </QueryClientProvider>
    </AccessManagerContext.Provider>
  );
};

export default App;
