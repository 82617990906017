import classNames from "classnames";

import styles from "./styles.module.scss";

type Props = {
  className?: string;
  testId: string;
  underline?: boolean;
  noBorder?: boolean;
};

export const Row = (props: React.PropsWithChildren<Props>) => {
  const { className, underline, noBorder, testId, children } = props;
  return (
    <div
      className={classNames(
        styles.row,
        {
          [styles.underline]: underline,
          [styles.noBorder]: noBorder,
        },
        className
      )}
      data-testid={testId}
    >
      {children}
    </div>
  );
};
