import { Route } from "react-router-dom";
import { ROUTES } from "utils/routes.constants";

import DevelopWorkspace from "pages/DevelopWorkspace/DevelopWorkspace";
import {
  AsyncAccountAdminConfigure,
  AsyncChooseAccount,
  AsyncConfigure,
  AsyncDemoTool,
  AsyncGovern,
} from "pages/pages";

import { withTitle } from "components/DocumentTitle/withTitle";

import { AccountRoute } from "./AccountRoute";
import { protectedRoute } from "./Protected";
import { adminRestricted } from "./adminRestricted";

const ProtectedDevelopWorkspace = protectedRoute(
  withTitle([""], DevelopWorkspace)
);

const ProtectedAsyncDemoTool = protectedRoute(
  // @ts-ignore
  withTitle("title.demo", AsyncDemoTool)
);

const ProtectedAsyncChooseAccount = withTitle(
  "title.choose_account",
  protectedRoute(AsyncChooseAccount)
);

const ProtectedAsyncConfigure = adminRestricted({
  OrganizationAdminComponent: protectedRoute(AsyncConfigure),
  AccountAdminComponent: protectedRoute(AsyncAccountAdminConfigure),
});

const ProtectedAsyncGovern = protectedRoute(AsyncGovern);

export const ProtectedAccountRoute = protectedRoute(AccountRoute);

export const protectedRoutes = [
  <Route
    key={ROUTES.ROOT}
    path={ROUTES.ROOT}
    element={<ProtectedDevelopWorkspace />}
  />,
  <Route
    key={ROUTES.DEVELOP}
    path={ROUTES.DEVELOP}
    element={<ProtectedDevelopWorkspace />}
  />,
  <Route
    key="configure"
    path="/:accountName?/configure"
  >
    <Route
      path="*"
      element={<ProtectedAsyncConfigure />}
    />
  </Route>,
  <Route
    key="govern"
    path="/:accountName?/govern"
  >
    <Route
      path="*"
      element={<ProtectedAsyncGovern />}
    />
  </Route>,
];

export const chooseAccountRoute = [
  <Route
    key={ROUTES.CHOOSE_ACCOUNT}
    path={ROUTES.CHOOSE_ACCOUNT}
    element={<ProtectedAsyncChooseAccount />}
  />,
];

export const demoToolRoute = [
  <Route
    key={ROUTES.DEMO_TOOL}
    path={ROUTES.DEMO_TOOL}
    element={<ProtectedAsyncDemoTool />}
  />,
];
