import { useSuspenseQuery } from "@tanstack/react-query";

import { ReactQueryKeysAccount } from "services/queryKeys";

import { getRoles } from "./getRoles";
import { Role } from "./types";

export const useRbacRoles = (options?: {
  byOwnerUsername?: string;
}): Role[] => {
  const queryKey: string[] = [ReactQueryKeysAccount.rbacRoles];

  if (options?.byOwnerUsername) {
    queryKey.push(`BY_OWNER-${options.byOwnerUsername}`);
  }

  const { data } = useSuspenseQuery<Role[], Error>({
    queryKey,
    queryFn: () => {
      return getRoles(options);
    },
  });

  return data || [];
};
