import classNames from "classnames";
import React, { useRef } from "react";

import Sort from "assets/icons/Sort.svg?react";

import { HeaderCellType, ORDER_DESC } from "./types";

import styles from "./styles.module.scss";

export const HeaderCell = (props: HeaderCellType) => {
  const {
    column,
    fillAvailableWidth,
    isDragging,
    style,
    onResize,
    onResizeFinish,
    onResizeStart,
    dragOffset,
    order,
    orderBy,
    disableResize,
    toggleOrder,
    handleSort,
    sortable,
    className,
  } = props;
  const { id, name } = column;

  const resizeFinish = useRef(onResizeFinish);
  const resizeStart = useRef(onResizeStart);

  resizeFinish.current = onResizeFinish;
  resizeStart.current = onResizeStart;

  const onPointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
    if (event.pointerType === "mouse" && event.buttons !== 1) {
      return;
    }

    const { currentTarget, pointerId } = event;
    const { right } = currentTarget.getBoundingClientRect();
    const startOffset = right - event.clientX;

    function onPointerMove(event: PointerEvent) {
      if (event.pointerId !== pointerId) {
        return;
      }
      if (event.pointerType === "mouse" && event.buttons !== 1) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onPointerUp();
        return;
      }
      onResize(event.clientX - right + startOffset);
    }

    function onPointerUp() {
      resizeFinish.current();
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("pointerup", onPointerUp);
    }

    event.preventDefault();
    resizeStart.current();
    window.addEventListener("pointermove", onPointerMove);
    window.addEventListener("pointerup", onPointerUp);
  };

  const showSortable =
    column.sortable !== undefined ? column.sortable : sortable;

  const handleClickHeader = () => {
    if (!showSortable) {
      return;
    }
    if (orderBy === id) {
      toggleOrder();
    } else {
      handleSort(id);
    }
  };
  return (
    <div
      style={style}
      className={classNames(styles.header__item, column.className, className)}
    >
      <div
        className={classNames(styles.name, {
          [styles.name__sortable]: showSortable,
          [styles.active]: orderBy === id,
        })}
        onClick={handleClickHeader}
      >
        <div className={styles.name__text}>{name}</div>
        {showSortable && (
          <div className={classNames(styles.sort, {})}>
            <Sort
              className={classNames(styles.sortIcon, {
                [styles.desc]: order === ORDER_DESC,
              })}
            />
          </div>
        )}
      </div>
      {!fillAvailableWidth && !disableResize && (
        <>
          <div
            style={{ right: -dragOffset }}
            className={classNames(styles.draggable, {
              [styles.draggable__active]: isDragging,
            })}
            onPointerDown={onPointerDown}
          />
        </>
      )}
    </div>
  );
};
