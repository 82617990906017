import { transferDatabaseOwnership } from "services/accountOwnership/transferDatabaseOwnership";
import { transferEngineOwnership } from "services/accountOwnership/transferEngineOwnership";
import { transferRoleOwnership } from "services/accountOwnership/transferRoleOwnership";
import { transferTableOwnership } from "services/accountOwnership/transferTableOwnership";
import { transferUserOwnership } from "services/accountOwnership/transferUserOwnership";
import { transferViewOwnership } from "services/accountOwnership/transferViewOwnership";
import { deleteDatabase } from "services/databases/deleteDatabase";
import { deleteTable } from "services/databases/deleteTable";
import { deleteView } from "services/databases/deleteView";
import { deleteEngineMutation } from "services/engines/deleteEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";
import { deleteRole } from "services/rbac/deleteRole";
import { deleteUser } from "services/users/deleteUser";

import {
  OwnershipObjectType,
  OwnershipRecord,
} from "components/AccountOwnership/types";

export enum TransferOwnershipActionType {
  DELETE = "DELETE",
  TRANSFER_OWNERSHIP = "TRANSFER_OWNERSHIP",
}

const useTransferOwnershipActions = () => {
  const actionHandlers = {
    [OwnershipObjectType.engine]: {
      keyToInvalidate: (_: OwnershipRecord) => [
        ReactQueryKeysAccount.workspaceEngines,
      ],
      [TransferOwnershipActionType.DELETE]: async (engine: OwnershipRecord) => {
        return deleteEngineMutation(engine.name);
      },
      [TransferOwnershipActionType.TRANSFER_OWNERSHIP]: async (
        engine: OwnershipRecord,
        newOwner: string
      ) => {
        return transferEngineOwnership({
          engineName: engine.name,
          newOwner,
        });
      },
    },
    [OwnershipObjectType.database]: {
      keyToInvalidate: (_: OwnershipRecord) => [
        ReactQueryKeysAccount.databasesNames,
      ],
      [TransferOwnershipActionType.DELETE]: async (
        database: OwnershipRecord
      ) => {
        return deleteDatabase(database.name);
      },
      [TransferOwnershipActionType.TRANSFER_OWNERSHIP]: async (
        database: OwnershipRecord,
        newOwner: string
      ) => {
        return transferDatabaseOwnership({
          databaseName: database.name,
          newOwner,
        });
      },
    },
    [OwnershipObjectType.role]: {
      keyToInvalidate: (_: OwnershipRecord) => [
        ReactQueryKeysAccount.rbacRoles,
      ],
      [TransferOwnershipActionType.DELETE]: async (role: OwnershipRecord) => {
        return deleteRole(role.name);
      },
      [TransferOwnershipActionType.TRANSFER_OWNERSHIP]: async (
        role: OwnershipRecord,
        newOwner: string
      ) => {
        return transferRoleOwnership({
          roleName: role.name,
          newOwner,
        });
      },
    },
    [OwnershipObjectType.user]: {
      keyToInvalidate: (_: OwnershipRecord) => [ReactQueryKeysAccount.users],
      [TransferOwnershipActionType.DELETE]: async (user: OwnershipRecord) => {
        return deleteUser(user.name);
      },
      [TransferOwnershipActionType.TRANSFER_OWNERSHIP]: async (
        user: OwnershipRecord,
        newOwner: string
      ) => {
        return transferUserOwnership({
          userName: user.name,
          newOwner,
        });
      },
    },

    [OwnershipObjectType.table]: {
      keyToInvalidate: (table: OwnershipRecord) => [
        ReactQueryKeysAccount.database_tables,
        table.parentName,
      ],
      [TransferOwnershipActionType.DELETE]: async (table: OwnershipRecord) => {
        if (!table.parentName) {
          throw new Error("Table database is missing");
        }
        return deleteTable(table.parentName, table.name);
      },
      [TransferOwnershipActionType.TRANSFER_OWNERSHIP]: async (
        table: OwnershipRecord,
        newOwner: string
      ) => {
        if (!table.parentName) {
          throw new Error("Table database is missing");
        }
        return transferTableOwnership({
          databaseName: table.parentName,
          tableName: table.name,
          newOwner,
        });
      },
    },

    [OwnershipObjectType.view]: {
      keyToInvalidate: (view: OwnershipRecord) => [
        ReactQueryKeysAccount.database_views,
        view.parentName,
      ],
      [TransferOwnershipActionType.DELETE]: async (view: OwnershipRecord) => {
        if (!view.parentName) {
          throw new Error("View database is missing");
        }

        return deleteView(view.parentName, view.name);
      },
      [TransferOwnershipActionType.TRANSFER_OWNERSHIP]: async (
        view: OwnershipRecord,
        newOwner: string
      ) => {
        if (!view.parentName) {
          throw new Error("View database is missing");
        }

        return transferViewOwnership({
          databaseName: view.parentName,
          viewName: view.name,
          newOwner,
        });
      },
    },
  };

  return {
    actionHandlers,
  };
};

export default useTransferOwnershipActions;
