import { useEffect } from "react";

import { systemEngineEnvironment } from "services/environment/systemEngine";

import useActiveDocument from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useActiveDocument";
import {
  SchemaDescriptor,
  useSQLynx,
} from "pages/DevelopWorkspace/contexts/SQLynxContext/SQLynxLoader";
import { MAX_IDENTIFIERS_COUNT } from "pages/DevelopWorkspace/contexts/SQLynxContext/constants";

import { NONE } from "../Editor/Document/DocumentContextSelector/types";

const SCHEMA_PUBLIC = "public";
const SCHEMA_INFORMATION = "information_schema";

interface TableRecord {
  tableName: string;
  columns: string[];
}

const fetchTablesColumnsCount = async (
  databaseName: string,
  schemaName: string
): Promise<{ tablesCount: number; columnsCount: number }> => {
  try {
    const response = await systemEngineEnvironment.execute(
      `SELECT 
        COUNT(DISTINCT table_name) AS tables_count,
        COUNT(column_name) AS columns_count
    FROM information_schema.columns where table_schema='${schemaName}';`,
      { database: databaseName }
    );

    const rows = response?.[0]?.rows
      ? (response[0].rows as unknown as {
          tablesCount: string;
          columnsCount: string;
        }[])
      : undefined;
    if (rows) {
      return {
        tablesCount: parseInt(rows[0].tablesCount, 10),
        columnsCount: parseInt(rows[0].columnsCount, 10),
      };
    }
  } catch (error) {
    console.error(
      `Error fetching columns schema descriptor for schema ${databaseName}.${schemaName}:`,
      error
    );
  }

  return { tablesCount: 0, columnsCount: 0 };
};

const fetchTablesSchemaDescriptor = async (
  databaseName: string,
  schemaName: string
): Promise<SchemaDescriptor | undefined> => {
  try {
    const response = await systemEngineEnvironment.execute(
      `SELECT table_name
FROM information_schema.tables where table_schema = '${schemaName}';`,
      { database: databaseName }
    );

    const rows = response?.[0]?.rows
      ? (response[0].rows as unknown as Omit<TableRecord, "columns">[])
      : undefined;

    if (rows?.length) {
      return {
        databaseName,
        schemaName,
        tables: rows.map(row => {
          return {
            tableName: row.tableName,
            columns: [],
          };
        }),
      };
    }
  } catch (error) {
    console.error(
      `Error fetching tables schema descriptor for schema ${databaseName}.${schemaName}:`,
      error
    );
  }
  return undefined;
};

const fetchFullSchemaDescriptor = async (
  databaseName: string,
  schemaName: string
): Promise<SchemaDescriptor | undefined> => {
  try {
    const response = await systemEngineEnvironment.execute(
      `SELECT table_name, ARRAY_AGG(column_name) AS columns
FROM information_schema.columns where table_schema = '${schemaName}' group by table_name;`,
      { database: databaseName }
    );

    const rows = response?.[0]?.rows
      ? (response[0].rows as unknown as TableRecord[])
      : undefined;

    if (rows?.length) {
      return {
        databaseName,
        schemaName,
        tables: rows,
      };
    }
  } catch (error) {
    console.error(
      `Error fetching schema descriptor for schema ${databaseName}.${schemaName}:`,
      error
    );
  }
  return undefined;
};

const getDescriptor = async (
  databaseName: string,
  schemaName: string
): Promise<SchemaDescriptor | undefined> => {
  if (schemaName === SCHEMA_INFORMATION) {
    // no limitations for information schema
    return fetchFullSchemaDescriptor(databaseName, schemaName);
  }

  const { tablesCount, columnsCount } = await fetchTablesColumnsCount(
    databaseName,
    schemaName
  );

  if (tablesCount === 0 && columnsCount === 0) {
    return undefined;
  }

  if (columnsCount + tablesCount < MAX_IDENTIFIERS_COUNT) {
    // it fits into the limit
    return fetchFullSchemaDescriptor(databaseName, schemaName);
  }

  if (tablesCount < MAX_IDENTIFIERS_COUNT) {
    // we keep only table names

    return fetchTablesSchemaDescriptor(databaseName, schemaName);
  }

  // even tables count is too big, so just skip this schema
  return undefined;
};

const useSchemaUpdater = (): void => {
  const { addDescriptor, descriptors } = useSQLynx();
  const activeDocument = useActiveDocument();

  useEffect(() => {
    const databaseName = activeDocument?.context.database.name;

    if (!databaseName || databaseName === NONE) return;

    // Check if the information schema descriptor is missing
    const informationSchemaDescriptor = descriptors.find(
      d => d.databaseName === "" && d.schemaName === SCHEMA_INFORMATION
    );

    if (!informationSchemaDescriptor) {
      getDescriptor(databaseName as string, SCHEMA_INFORMATION)
        .then(schemaDescriptor => {
          if (schemaDescriptor) {
            addDescriptor({
              ...schemaDescriptor,
              databaseName: "",
            });
          }
        })
        .catch(e => {
          console.error(e);
        });
    }

    // Check if the public schema descriptor is missing
    const publicSchemaDescriptor = descriptors.find(
      d => d.databaseName === databaseName && d.schemaName === SCHEMA_PUBLIC
    );

    if (!publicSchemaDescriptor) {
      getDescriptor(databaseName as string, SCHEMA_PUBLIC)
        .then(schemaDescriptor => {
          if (schemaDescriptor) {
            addDescriptor(schemaDescriptor);
          }
        })
        .catch(e => {
          console.error(e);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this effect when the databaseName changes
  }, [activeDocument?.context.database.name]);
};

export default useSchemaUpdater;
