import classNames from "classnames";
import { useContext } from "react";

import { TableColumnFilters } from "components/ColumnFilters/TableColumnFilters";

import { TableContext } from "./context";

import styles from "./styles.module.scss";

type Props = {
  style: React.CSSProperties;
};

export const ColumnFiltersCell = (props: Props) => {
  const { style } = props;

  const {
    visibleColumns,
    handleColumnClick,
    toggleShowAllColumns,
    filterColumns,
  } = useContext(TableContext);

  return (
    <div
      style={style}
      className={classNames(styles.header__item, styles.header__floating)}
    >
      <TableColumnFilters
        columns={filterColumns}
        visibleColumns={visibleColumns}
        handleColumnClick={handleColumnClick}
        toggleShowAllColumns={toggleShowAllColumns}
      />
    </div>
  );
};
