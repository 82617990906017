import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Popover } from "@mui/material";
import cn from "classnames";
import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import DocumentObjectExplorerCollapseIcon from "assets/icons/DocumentObjectExplorerCollapseIcon.svg?react";
import DocumentOutputCollpaseIcon from "assets/icons/DocumentOutputCollpaseIcon.svg?react";
import PlusIcon from "assets/icons/PlusIcon.svg?react";
import TabsMenuIcon from "assets/icons/TabsMenuIcon.svg?react";

import Tab, { TabStatus } from "pages/DevelopWorkspace/Editor/TabsBar/Tab/Tab";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { useDocumentsActionConfirmation } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocumentsActionConfirmation";
import {
  Script,
  useScripts,
} from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { useShortcutsOverlay } from "pages/DevelopWorkspace/contexts/ShortcutsOverlayContext/ShortcutsOverlayContext";
import { documentHasErrors } from "pages/DevelopWorkspace/helpers/documentHasError";
import { documentHasResults } from "pages/DevelopWorkspace/helpers/documentHasResults";
import { getDocumentScript } from "pages/DevelopWorkspace/helpers/getDocumentScript";
import { isDocumentRunning } from "pages/DevelopWorkspace/helpers/isDocumentRunning";
import { SHORTCUTS } from "pages/DevelopWorkspace/services/shortcuts/constants";
import { getShortcutHint } from "pages/DevelopWorkspace/services/shortcuts/helpers/getShortcutHint";
import { ShortcutAction } from "pages/DevelopWorkspace/services/shortcuts/types";
import useShortcutsHandler from "pages/DevelopWorkspace/services/shortcuts/useShortcutsHandler";
import {
  QueryStatementResult,
  ScriptType,
  SortOrder,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuHeader from "components/ContextMenu/ContextMenuHeader";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { useLayoutToggles } from "components/LayoutToggles/context";

import styles from "./TabsBar.module.scss";

export const getDocumentStatus = (
  document: WorkspaceDocument,
  qsResults: {
    [qsId: string]: QueryStatementResult | undefined;
  }
) => {
  if (isDocumentRunning(document)) {
    return TabStatus.Running;
  }

  if (documentHasErrors(document)) {
    return TabStatus.Error;
  }

  if (documentHasResults(document, qsResults)) {
    return TabStatus.Success;
  }

  return undefined;
};

interface TabWrapperProps {
  document: WorkspaceDocument;
  handleDeleteDocument: (documentId: string) => void;
  handleDeleteAllDocuments: (documentIds: string[]) => void;
  hasScroll: boolean;
  lastScrollableTab: boolean;
  tabWidth: number;
  tabRefs: React.MutableRefObject<Record<string, HTMLDivElement>>;
  isDragged: boolean;
  script: Script | null;
  isUnsaved: boolean;
}

const TabWrapper = (props: TabWrapperProps) => {
  const {
    document,
    tabWidth,
    handleDeleteDocument,
    handleDeleteAllDocuments,
    lastScrollableTab,
    tabRefs,
    isDragged,
    script,
    isUnsaved,
  } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: document.id,
    });

  const {
    state: { activeDocumentId },
    qsResults,
    actions: { setActiveDocument, renameDocument },
  } = useDocuments();

  const status = getDocumentStatus(document, qsResults);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: tabWidth + "px",
  };

  return (
    <div
      key={document.id}
      className={cn(styles.tab, {
        [styles.lastScrollableTab]: lastScrollableTab,
      })}
      data-testid={`document-tab-${script?.title}`}
      style={style}
      ref={ref => {
        if (!ref) {
          return;
        }

        setNodeRef(ref);

        tabRefs.current[document.id] = ref;
      }}
      {...attributes}
      {...listeners}
    >
      {isDragged ? (
        <div className={styles.tabDragPlaceholder} />
      ) : (
        <Tab
          isLoadingScript={!script}
          title={script?.title}
          content={script?.content}
          isUnsaved={isUnsaved}
          isActive={document.id === activeDocumentId}
          onSelect={() => setActiveDocument(document.id)}
          onClose={(closeAllOther: boolean) => {
            if (closeAllOther) {
              handleDeleteAllDocuments([document.id]);
              return;
            }

            handleDeleteDocument(document.id);
          }}
          onRenameSubmit={(newTitle: string) => {
            renameDocument(document.id, newTitle);
          }}
          status={status}
        />
      )}
    </div>
  );
};

const DraggedTab = forwardRef(({ title, ...props }: any, ref: any) => {
  return (
    <div
      {...props}
      ref={ref}
      className={styles.draggedTab}
    >
      <div className={styles.title}>{title}</div>
    </div>
  );
});

const TabsBar = () => {
  const {
    state,
    actions: {
      createDocument,
      setActiveDocument,
      sortDocuments,
      reorderDocument,
    },
  } = useDocuments();
  const { t } = useTranslation();
  const { handleDeleteDocument, handleDeleteAllDocuments, modalMarkup } =
    useDocumentsActionConfirmation();
  useShortcutsHandler({
    handleDeleteDocument,
    handleDeleteAllDocuments,
    confirmationModalMarkup: modalMarkup,
  });
  const [draggedDocumentId, setDraggedDocumentId] = useState<string | null>(
    null
  );
  const [tabWidth, setTabWidth] = useState(0);
  const [hasScroll, setHasScroll] = useState(false);
  const tabsContainerRef = useRef<HTMLDivElement | null>(null);
  const tabRefs = useRef<Record<string, HTMLDivElement>>({});
  const { layout, setLayout } = useLayoutToggles();
  const { state: scriptsState } = useScripts();
  const tabsMenuAnchorEl = useRef<HTMLDivElement | null>(null);
  const [tabsMenuOpen, setTabsMenuOpen] = useState<boolean>(false);
  const { showOverlay } = useShortcutsOverlay();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const { documents, activeDocumentId } = state;

  const updateTabWidth = () => {
    const MAX_TAB_WIDTH = 140;
    const DEFAULT_TAB_WIDTH = MAX_TAB_WIDTH;
    const MIN_TAB_WIDTH = 70;
    const TAB_MARGIN = 2;
    const ADD_BUTTON_WIDTH = 40;

    const tabsContainer = tabsContainerRef.current;

    if (!tabsContainer) {
      setTabWidth(DEFAULT_TAB_WIDTH);
      return;
    }

    const tabsContainerWidth = tabsContainer.clientWidth - ADD_BUTTON_WIDTH;

    const width = Math.min(
      MAX_TAB_WIDTH,
      Math.max(
        MIN_TAB_WIDTH,
        tabsContainerWidth / documents.length - TAB_MARGIN
      )
    );

    setHasScroll(tabsContainerWidth < documents.length * width);

    setTabWidth(width);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateTabWidth();
    });

    resizeObserver.observe(tabsContainerRef.current!);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- it's ok here
  }, [documents.length, tabsContainerRef.current]);

  useLayoutEffect(() => {
    updateTabWidth();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- it's ok here
  }, [tabsContainerRef.current, documents.length, layout.leftSidebar.expanded]);

  useEffect(() => {
    if (!activeDocumentId) {
      return;
    }

    const activeTabElement = tabRefs.current[activeDocumentId];

    if (activeTabElement) {
      setTimeout(() => {
        activeTabElement.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      }, 0);
    }
  }, [activeDocumentId]);

  const draggedDocumentTitle = useMemo(() => {
    const document = documents.find(
      document => document.id === draggedDocumentId
    );

    if (!document) {
      return "";
    }

    const script = getDocumentScript(document, scriptsState);

    return script?.title || "";
  }, [draggedDocumentId, documents, scriptsState]);

  const activeDocument = documents.find(
    document => document.id === activeDocumentId
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;

    setDraggedDocumentId(`${active.id}`);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setDraggedDocumentId(null);

    if (event.over?.id) {
      reorderDocument(event.active.id as string, event.over?.id as string);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.tabsWrapper}>
          <div
            className={styles.tabs}
            ref={tabsContainerRef}
          >
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                strategy={horizontalListSortingStrategy}
                items={documents.map(document => document.id)}
              >
                {documents.map((document, index) => {
                  const scriptStorage =
                    document.script.type === ScriptType.local
                      ? scriptsState.localScripts
                      : scriptsState.remoteScripts;

                  const scriptState = scriptStorage[document.script.id];

                  const script = getDocumentScript(document, scriptsState);

                  return (
                    <TabWrapper
                      isDragged={draggedDocumentId === document.id}
                      key={document.id}
                      document={document}
                      handleDeleteDocument={handleDeleteDocument}
                      handleDeleteAllDocuments={handleDeleteAllDocuments}
                      hasScroll={hasScroll}
                      lastScrollableTab={
                        hasScroll && index === documents.length - 1
                      }
                      tabWidth={tabWidth}
                      tabRefs={tabRefs}
                      script={script}
                      isUnsaved={!!scriptState?.isUnsaved}
                    />
                  );
                })}
              </SortableContext>

              <DragOverlay>
                {draggedDocumentId ? (
                  <DraggedTab
                    id={draggedDocumentId}
                    title={draggedDocumentTitle}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>

            <div className={styles.addButtonWrapper}>
              <div
                className={styles.addButton}
                title={`${t(SHORTCUTS[ShortcutAction.ScriptOpenNew].title)}: ${getShortcutHint(
                  SHORTCUTS[ShortcutAction.ScriptOpenNew]
                ).join(" + ")}`}
                onClick={() => {
                  createDocument({
                    context: activeDocument
                      ? {
                          database: {
                            name: activeDocument.context.database.name,
                          },
                          engine: { name: activeDocument.context.engine.name },
                        }
                      : undefined,
                  });
                }}
                data-testid="add-script-btn"
              >
                <div className={styles.icon}>
                  <PlusIcon />
                </div>
              </div>
            </div>
          </div>

          {hasScroll && <div className={styles.gradientBoundaryRight} />}
        </div>
      </div>

      <div className={styles.iconsWrapper}>
        <div
          className={styles.icon}
          ref={tabsMenuAnchorEl}
          onClick={() => {
            setTabsMenuOpen(true);
          }}
          data-testid="other-tabs-menu-icon"
        >
          <TabsMenuIcon />
        </div>
        <div
          className={cn(styles.icon, {
            [styles.active]: layout.leftSidebar.expanded,
          })}
          onClick={() => {
            setLayout({
              ...layout,
              leftSidebar: {
                ...layout.leftSidebar,
                expanded: !layout.leftSidebar.expanded,
              },
            });
          }}
          data-testid="toggle-workspace-left-sidebar"
        >
          <DocumentObjectExplorerCollapseIcon />
        </div>
        <div
          id={styles.outputCollapseIcon}
          className={cn(styles.icon, {
            [styles.active]: layout.results.expanded,
          })}
          data-testid="toggle-workspace-results"
          title={`${t(SHORTCUTS[ShortcutAction.ToggleResultsSection].title)}: ${getShortcutHint(
            SHORTCUTS[ShortcutAction.ToggleResultsSection]
          ).join(" + ")}`}
          onClick={() => {
            setLayout({
              ...layout,
              results: {
                ...layout.results,
                expanded: !layout.results.expanded,
              },
            });
          }}
        >
          <DocumentOutputCollpaseIcon />
        </div>
      </div>

      {tabsMenuOpen && (
        <Popover
          open={tabsMenuOpen}
          anchorEl={tabsMenuAnchorEl.current}
          classes={{ paper: styles.tabsMenuPaper }}
          onClose={() => {
            setTabsMenuOpen(false);
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <ContextMenu>
            <ContextMenuItem
              key="close-all"
              text={t(SHORTCUTS[ShortcutAction.ScriptsCloseAll].title)}
              testId="close-all-tabs"
              secondaryText={getShortcutHint(
                SHORTCUTS[ShortcutAction.ScriptsCloseAll]
              ).join(" + ")}
              onClick={e => {
                e.stopPropagation();
                setTabsMenuOpen(false);
                handleDeleteAllDocuments([]);
              }}
            />
            <ContextMenuItem
              key="close-all-other"
              text={t(SHORTCUTS[ShortcutAction.ScriptsCloseAllOther].title)}
              testId="close-all-other-tabs"
              secondaryText={getShortcutHint(
                SHORTCUTS[ShortcutAction.ScriptsCloseAllOther]
              ).join(" + ")}
              onClick={e => {
                e.stopPropagation();
                setTabsMenuOpen(false);
                handleDeleteAllDocuments(
                  activeDocumentId ? [activeDocumentId] : []
                );
              }}
            />
            <ContextMenuDivider />
            <ContextMenuItemsGroup
              maxHeight={300}
              isGroup={true}
            >
              {documents.map(document => {
                const script = getDocumentScript(document, scriptsState);

                return (
                  <ContextMenuItem
                    checked={document.id === activeDocumentId}
                    key={document.id}
                    text={script?.title || ""}
                    testId={`script-tab-menu-item-${script?.title}`}
                    onClick={e => {
                      e.stopPropagation();
                      setTabsMenuOpen(false);
                      setActiveDocument(document.id);
                    }}
                  />
                );
              })}
            </ContextMenuItemsGroup>
            <ContextMenuDivider />
            <ContextMenuHeader
              text={`${t("workspace.tabs_menu.sort_tabs_by")}:`}
            />
            <ContextMenuItem
              key="newest-first"
              text={t("workspace.tabs_menu.newest_first")}
              testId="sort-tabs-newest-first"
              onClick={e => {
                e.stopPropagation();
                setTabsMenuOpen(false);
                sortDocuments(SortOrder.NewestFirst);
              }}
            />

            <ContextMenuItem
              key="newest-last"
              text={t("workspace.tabs_menu.newest_last")}
              testId="sort-tabs-newest-last"
              onClick={e => {
                e.stopPropagation();
                setTabsMenuOpen(false);
                sortDocuments(SortOrder.NewestLast);
              }}
            />
            <ContextMenuDivider />
            <ContextMenuItem
              key="keyboard-shortcuts"
              text={t("workspace.tabs_menu.keyboard_shortcuts")}
              secondaryText={getShortcutHint(
                SHORTCUTS[ShortcutAction.ShortcutsOverlayOpen]
              ).join(" + ")}
              onClick={e => {
                e.stopPropagation();
                setTabsMenuOpen(false);
                showOverlay();
              }}
            />
          </ContextMenu>
        </Popover>
      )}

      {modalMarkup}
    </div>
  );
};

export default TabsBar;
