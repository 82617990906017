import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchVersion } from "utils/maintenance";

import Button, { ButtonTemplate } from "components/Button/Button";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

export const useAppVersionUpdateNotification = () => {
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();

  const [currentVersion, setCurrentVersion] = useState<string>(
    (document.getElementById("app-version") as HTMLMetaElement).content
  );

  useEffect(() => {
    const interval = setInterval(async () => {
      const version = await fetchVersion();

      if (!version) return;

      if (version !== currentVersion) {
        setCurrentVersion(version);

        putStatusMessage({
          message: t("app_update.message"),
          buttons: (
            <>
              <Button
                text={t("app_update.link")}
                template={ButtonTemplate.Secondary}
                onClick={() => {
                  document.location.reload();
                }}
              />
            </>
          ),
          type: StatusMessageType.Success,
          options: {
            autoRemove: false,
            insertToPosition: StatusMessagePosition.Bottom,
            id: "app-update",
            testId: "app-version-update",
            autoClearDelay: 60 * 1000,
          },
        });
      }
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [currentVersion, putStatusMessage, setCurrentVersion, t]);

  return null;
};
