import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAccounts } from "services/account/useAccounts";
import { useUser } from "services/auth/useUser";
import { useLogin } from "services/login/useLogin";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import Button, { ButtonTemplate } from "components/Button/Button";
import FormModal from "components/FormModal/FormModal";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

import Form from "./components/Form";
import { DisabledFields, ErrorFields, Fields, TEST_IDS } from "./constants";
import { createSupportTicket } from "./createSupportTicket";
import {
  canSubmit,
  generateDisabledFields,
  generateInitialState,
  getAllErrors,
  getErrorFromField,
  prepareDataForRequest,
} from "./helpers";
import { Translations } from "./translations";

import styles from "./ContactSupport.module.scss";

interface Props {
  onClose: () => void;
  preCompletedFields?: Fields;
}

const ContactSupport: React.FunctionComponent<Props> = props => {
  const { preCompletedFields } = props;
  const { t } = useTranslation();
  const login = useLogin();
  const user = useUser();
  const accounts = useAccounts();
  const { putStatusMessage } = useStatusMessageQueue();
  const { getAccount } = useCurrentAccount();
  const currentAccount = getAccount();
  const accountName = currentAccount?.accountName ?? "";
  const accountNameDisabled = accounts.length < 2;

  const [fields, setFields] = useState<Fields>(
    generateInitialState({
      preCompletedFields,
      user,
      login,
      accountName,
      defaultEngineName: "",
    }) as Fields
  );
  const [disabledFields, setDisabledFields] = useState<DisabledFields>(
    generateDisabledFields({ login, user, accountNameDisabled })
  );
  const [errors, setErrors] = useState<ErrorFields>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setDisabledFields(oldState => ({
      ...oldState,
      ...generateDisabledFields({ login, user, accountNameDisabled }),
    }));
  }, [login, accountNameDisabled, setDisabledFields, user]);

  const handleChange = (name: string, value: string) => {
    setFields(oldState => ({
      ...oldState,
      [name]: value,
    }));
    const error = getErrorFromField(name, value);
    const errorMessage = error
      ? t(`${Translations.errors}${error.errorType}`, {
          inputForErrorMessage: t(error.inputForErrorMessage || "error"),
        })
      : null;
    setErrors(oldState => ({
      ...oldState,
      [name]: errorMessage,
    }));
  };

  const onSubmitSuccess = () => {
    putStatusMessage({
      message: (
        <>
          <div>{t(Translations.successSubmitMessage1)}</div>
          <div>{t(Translations.successSubmitMessage2)}</div>
        </>
      ),
      type: StatusMessageType.Success,
    });
    props.onClose();
  };

  const onSubmitError = (errMessage?: string) => {
    putStatusMessage({
      message: errMessage || t(Translations.errorSubmitMessage),
      type: StatusMessageType.Error,
      options: {
        insertToPosition: StatusMessagePosition.Bottom,
        id: null,
        autoRemove: true,
        autoClearDelay: 10000,
      },
    });
    setLoading(false);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const errors = getAllErrors(fields);

    if (errors.length) {
      const errorState: { [key: string]: string } = {};
      errors.forEach(error => {
        const { fieldName, errorType, inputForErrorMessage } = error;
        errorState[fieldName as keyof typeof errorState] = t(
          `${Translations.errors}${errorType}`,
          {
            inputForErrorMessage,
          }
        );
      });
      setErrors({ ...errorState });
      return;
    }

    const params = prepareDataForRequest(fields);
    setLoading(true);
    createSupportTicket({
      body: params,
      onSuccess: onSubmitSuccess,
      onError: onSubmitError,
      t,
    });
  };

  return (
    <FormModal
      open={true}
      classes={{
        paper: cn(styles.contactModal, styles.paper),
      }}
      onClose={() => {
        if (loading) return;
        props.onClose();
      }}
    >
      <div className={styles.contentContainer}>
        <h2 className={styles.title}>{t(Translations.pageTitle)}</h2>
        <form
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          name="contactForm"
        >
          <div className={styles.content}>
            <Form
              fields={fields}
              disabledFields={disabledFields}
              errors={errors}
              handleChange={handleChange}
            />
          </div>
          <div className={styles.footer}>
            <Button
              text={t(Translations.submit)}
              isLoading={loading}
              disabled={!canSubmit(fields)}
              className={styles.submitBtn}
              template={ButtonTemplate.Primary}
              onClick={handleSubmit}
              dataTestid={TEST_IDS.submitForm}
              id="contactSubmit"
            />
            <Button
              text={t(Translations.cancel)}
              template={ButtonTemplate.Secondary}
              onClick={props.onClose}
              disabled={loading}
              dataTestid={TEST_IDS.cancelForm}
            />
          </div>
        </form>
      </div>
    </FormModal>
  );
};

export default ContactSupport;
