import classNames from "classnames";
import React from "react";

import { VerticalBarsIcon } from "components/Icons";

import styles from "./ColumnFilters.module.scss";

type Props = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  testId?: string;
  active?: boolean;
  inverse?: boolean;
};

export const ColumnFilterIcon = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      active,
      inverse,
      onClick,
      className,
      testId = "action-button",
    } = props;
    return (
      <div
        ref={ref}
        className={classNames(styles.action, className, {
          [styles.active]: active,
          [styles.inverse]: inverse,
        })}
        onClick={onClick}
        data-testid={testId}
      >
        <VerticalBarsIcon />
      </div>
    );
  }
);
