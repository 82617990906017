import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { deleteEngineMutation } from "services/engines/deleteEngine";
import {
  WorkspaceEngine,
  WorkspaceEngineStatus,
} from "services/engines/engine.types";
import { ReactQueryKeysAccount } from "services/queryKeys";

import {
  ConfirmationModal,
  ObjectType,
} from "components/ConfirmationModal/ConfirmationModal";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

export const useDeleteEngine = (options?: {
  onDeleted?: (engineName: string) => void;
}) => {
  const queryClient = useQueryClient();
  const [deleteEngineName, setDeleteEngineName] = useState<string | null>(null);
  const { t } = useTranslation();

  const { putStatusMessage } = useStatusMessageQueue();

  const handleClose = () => {
    setDeleteEngineName(null);
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: deleteEngineMutation,
    onSuccess: async () => {
      // todo: we are re-fetching engines twice on delete. once here, and once through WS
      // todo: question: should we remove this?
      // todo: downside of removing: engine is visible in the list after modal close, for some milliseconds
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.workspaceEngines],
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.users],
      });
      if (deleteEngineName) {
        options?.onDeleted?.(deleteEngineName);
      }

      putStatusMessage({
        type: StatusMessageType.Success,
        message: t("workspace.delete_engine.success", {
          engineName: deleteEngineName,
        }),
      });

      handleClose();
    },
    onMutate: async (engineName: string) => {
      await queryClient.cancelQueries({
        queryKey: [ReactQueryKeysAccount.workspaceEngines],
      });
      const previousEngines = queryClient.getQueryData([
        ReactQueryKeysAccount.workspaceEngines,
      ]);

      queryClient.setQueryData<WorkspaceEngine[]>(
        [ReactQueryKeysAccount.workspaceEngines],
        engines => {
          return (engines || []).map(engine => {
            if (engine.engineName === engineName) {
              return {
                ...engine,
                status: WorkspaceEngineStatus.STOPPING,
              };
            }
            return engine;
          });
        }
      );

      return { previousEngines };
    },
    onError: async (error: Error) => {
      putStatusMessage({
        type: StatusMessageType.Error,
        message: error.message,
        options: {
          id: "start",
          insertToPosition: StatusMessagePosition.Top,
          autoRemove: true,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.workspaceEngines],
      });
    },
  });

  const handleConfirm = () => {
    if (isLoading) {
      return;
    }
    if (deleteEngineName) {
      mutate(deleteEngineName);
    }
  };

  const deleteEngineMarkup = deleteEngineName ? (
    <ConfirmationModal
      title={t("workspace.delete_engine.title")}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isLoading={isLoading}
      confirmButtonOptions={{ text: t("workspace.delete_engine.confirm") }}
      message={
        <Trans
          i18nKey="workspace.delete_engine.message"
          components={{ br: <br /> }}
        />
      }
      objectName={deleteEngineName}
      objectType={ObjectType.Engine}
    >
      confirm remove
    </ConfirmationModal>
  ) : null;

  const openDeleteEngine = (name: string) => {
    setDeleteEngineName(name);
  };

  return {
    deleteEngineMarkup,
    openDeleteEngine,
  };
};
