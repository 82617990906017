import { systemEngineEnvironment } from "services/environment/systemEngine";

export const transferTableOwnership = async ({
  databaseName,
  tableName,
  newOwner,
}: {
  databaseName: string;
  tableName: string;
  newOwner: string;
}) => {
  await systemEngineEnvironment.execute(
    `ALTER TABLE "${tableName}" OWNER TO "${newOwner}";`,
    {
      database: databaseName,
    }
  );
};
