import { useSuspenseQuery } from "@tanstack/react-query";

import { ReactQueryKeysAccount } from "services/queryKeys";

import { View, getViews } from "./getViews";

export const useViewNames = ({ database }: { database: string }): View[] => {
  const { data } = useSuspenseQuery<View[], Error>({
    queryKey: [ReactQueryKeysAccount.viewNames, database],
    queryFn: async () => {
      const views = await getViews(database);
      return views;
    },
  });
  return data || [];
};
