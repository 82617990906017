import { Breadcrumbs, BreadcrumbsProps } from "@mui/material";
import classNames from "classnames";

import { ChevronRight } from "components/Icons";

import styles from "./Breadcrumbs.module.scss";

export type CustomBreadcrumbsProps = {
  breadcrumbs: string[];
  handleBreadCrumbClick: (_b: { breadcrumb: string; index: number }) => void;
} & BreadcrumbsProps;

const CustomBreadcrumbs = (props: CustomBreadcrumbsProps) => {
  const { breadcrumbs, handleBreadCrumbClick, maxItems = 3 } = props;
  return (
    <Breadcrumbs
      maxItems={maxItems}
      separator={<ChevronRight />}
      classes={{
        root: classNames(styles.root, styles.forced),
        separator: styles.separator,
      }}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return (
          <div
            className={classNames(styles.breadcrumb, {
              [styles.last]: isLast,
            })}
            key={breadcrumb}
            data-testid={`breadcrumb-${breadcrumb}`}
            onClick={() =>
              !isLast && handleBreadCrumbClick({ breadcrumb, index })
            }
          >
            {breadcrumb}
          </div>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
