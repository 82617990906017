import { systemEngineEnvironment } from "services/environment/systemEngine";

export const transferEngineOwnership = async ({
  engineName,
  newOwner,
}: {
  engineName: string;
  newOwner: string;
}) => {
  await systemEngineEnvironment.execute(
    `ALTER ENGINE "${engineName}" OWNER TO "${newOwner}";`
  );
};
