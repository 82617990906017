import { Breakpoint, IconButton } from "@mui/material";
import classNames from "classnames";

import { Dialog } from "components/Dialog/Dialog";
import { CrossIcon } from "components/Icons";

import styles from "./styles.module.scss";

type Props = {
  title: string;
  onClose: () => void;
  header?: React.ReactNode;
  body: React.ReactNode;
  testId: string;
  size?: string;
  maxWidth?: Breakpoint;
};

export const Wizard = (props: Props) => {
  const { title, onClose, header, body, testId, size, maxWidth } = props;

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose}
      size={size}
      open
    >
      <div className={classNames(styles.wrapper, size && styles[size])}>
        <div className={styles.header}>
          <div className={styles.header__items}>
            <div className={styles.header__inner}>
              <div
                className={styles.header__title}
                data-testid={`${testId}-title`}
              >
                {title}
              </div>
            </div>
            {header}
          </div>
          <IconButton
            onClick={onClose}
            edge="start"
            size="small"
            className={styles.close}
          >
            <CrossIcon data-testid="close-modal-cross" />
          </IconButton>
        </div>
        <div className={styles.body}>{body}</div>
      </div>
    </Dialog>
  );
};
