import cn from "classnames";
import { useTranslation } from "react-i18next";

import { User } from "services/users/user.types";

import { DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE } from "components/AccountOwnership/constants";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import styles from "./OwnerSelector.module.scss";

interface Props {
  users: User[];
  selectedUser: string;
  onUserSelect: (user: string) => void;
  disabled: boolean;
  cellView?: boolean;
  errorMessage?: string;
  className?: string;
}

const OwnerSelector = (props: Props) => {
  const {
    users,
    selectedUser,
    onUserSelect,
    disabled,
    cellView,
    errorMessage,
    className,
  } = props;

  const { t } = useTranslation();

  const usersOptions = [
    <ContextMenuItem
      value={DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE}
      key={DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE}
      testId={`new-owner-item-${DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE}`}
      checked={selectedUser === DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE}
      checkedIconPlaceholder={true}
      text={t("transfer_ownership.delete_object")}
      warning={true}
      skipFilter={true}
    />,
    <ContextMenuDivider key="divider" />,
    ...users.map(item => {
      const { userName } = item;
      return (
        <ContextMenuItem
          value={userName}
          key={userName}
          testId={`new-owner-item-${userName}`}
          checked={selectedUser === userName}
          checkedIconPlaceholder={true}
          text={userName}
        />
      );
    }),
  ];

  return (
    <OutlinedSelect
      noBorder={cellView}
      searchOptions={{
        searchPlaceholder: t("transfer_ownership.search_owner"),
        noResultsText: t("transfer_ownership.no_users_found"),
      }}
      testId="new-owner-select"
      className={cn(
        styles.select,
        {
          [styles.cellView]: cellView,
          [styles.withError]: errorMessage,
        },
        className
      )}
      onSelect={(items: string[]) => {
        const [item] = items;
        onUserSelect(item);
      }}
      disabled={disabled}
      renderValue={value => {
        if (value[0] === "") {
          return (
            <span className={styles.placeholder}>
              {t("transfer_ownership.select_owner")}
            </span>
          );
        }

        if (value[0] === DELETE_OWNERSHIP_OBJECT_RESERVED_VALUE) {
          return (
            <span className={cn(styles.placeholder, styles.deleteObjectLabel)}>
              {t("transfer_ownership.delete_object")}
            </span>
          );
        }

        return <span className={styles.placeholder}>{value[0]}</span>;
      }}
      controlledValue={[selectedUser]}
    >
      {usersOptions}
    </OutlinedSelect>
  );
};

export default OwnerSelector;
