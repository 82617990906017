import { ReactNode } from "react";

import { DropdownArrowDown, DropdownArrowRight } from "components/Icons";

import styles from "./styles.module.scss";

interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
  testId: string;
  collapsed: boolean;
  onClick: () => void;
}

const GroupHeader = (props: Props) => {
  const { title, subtitle, testId, collapsed, onClick } = props;

  return (
    <div
      onClick={onClick}
      data-testid={testId}
      className={styles.groupHeader}
    >
      <div className={styles.icon}>
        {collapsed ? <DropdownArrowRight /> : <DropdownArrowDown />}
      </div>
      <div className={styles.title}>
        {title} <span className={styles.subtitle}>{subtitle}</span>
      </div>
    </div>
  );
};

export default GroupHeader;
