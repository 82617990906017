import SubstractIcon from "assets/icons/SubstractIcon.svg?react";

import styles from "./ErrorMessage.module.scss";

interface ErrorMessageProps {
  description?: string;
  title: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { description, title } = props;

  return (
    <div className={styles.errorMessage}>
      <div className={styles.header}>
        <SubstractIcon className={styles.icon} />
        <span className={styles.title}>{title}</span>
      </div>
      {!!description && (
        <div className={styles.descriptionContainer}>{description}</div>
      )}
    </div>
  );
};

export default ErrorMessage;
