import { TextFieldProps } from "@mui/material";
import classNames from "classnames";
import React, { useContext, useState } from "react";

import { InputDisabledContext } from "components/InputState/InputDisabledContext";

import styles from "./styles.module.scss";

type Props = {
  inputRootClassName?: string;
  labelClassName?: string;
  testId?: string;
  showAsterisk?: boolean;
  mainField?: boolean;
} & Omit<TextFieldProps, "variant">;

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      error,
      required,
      autoFocus,
      autoComplete,
      helperText,
      label,
      InputProps = {},
      onFocus,
      onBlur,
      onChange,
      onKeyDown,
      value,
      name,
      type,
      className,
      labelClassName,
      inputRootClassName,
      testId,
      placeholder,
      showAsterisk,
      mainField,
      multiline,
    } = props;
    const isDisabled = useContext(InputDisabledContext);
    const disabled = props.disabled || isDisabled;
    const [inputFocused, setInputFocused] = useState(false);
    const { startAdornment, endAdornment } = InputProps;

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setInputFocused(true);
      onFocus && onFocus(event);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setInputFocused(false);
      onBlur && onBlur(event);
    };

    const inputProps = {};
    if (value !== undefined) {
      Object.assign(inputProps, { value });
    }

    const inputComponentProps = {
      className: styles.input,
      ref,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onChange,
      onKeyDown,
      required,
      autoFocus,
      autoComplete,
      disabled,
      name,
      type,
      "data-testid": testId,
      placeholder,
      "main-field": mainField ? "true" : undefined,
      ...inputProps,
    };

    const inputMarkup = React.createElement(
      multiline ? "textarea" : "input",
      inputComponentProps
    );

    return (
      <div className={classNames(styles.root, className)}>
        {label && (
          <div className={classNames(styles.label, labelClassName)}>
            {label}
            {showAsterisk && required && (
              <span className={styles.required}>*</span>
            )}
          </div>
        )}
        <div
          className={classNames(styles.inputRoot, inputRootClassName, {
            [styles.inputError]: !!error,
            [styles.focused]: inputFocused,
            [styles.disabled]: disabled,
            [styles.startAdornment]: !!startAdornment,
            [styles.endAdornment]: !!endAdornment,
          })}
        >
          <div className={styles.inputWrapper}>
            {startAdornment}
            {inputMarkup}
            {endAdornment}
          </div>
          {error && (
            <p
              data-testid={`${testId}-error-message`}
              className={styles.helperText}
            >
              {helperText}
            </p>
          )}
        </div>
      </div>
    );
  }
);
