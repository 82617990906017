import cn from "classnames";

import ResultsControls from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/ResultsControls/ResultsControls";
import TabsBar from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentResults/TabsBar/TabsBar";
import OutputSubPanel from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/components/OutputSubPanel/OutputSubPanel";
import { isQueryStatementResolved } from "pages/DevelopWorkspace/contexts/DocumentsContext/helpers/getQueryStatementState";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import {
  DocumentOutputTab,
  QueryStatement,
  VISIBLE_QUERY_STATEMENTS_STATUSES,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import { ExplainStatement } from "../ExplainStatement/ExplainStatement";

import styles from "./DocumentResults.module.scss";

export const hasExplain = (queryStatement: QueryStatement) =>
  queryStatement.explain && Object.keys(queryStatement.explain).length > 0;

export const ExplainResults = (props: {
  document: WorkspaceDocument | null;
  isProfile?: boolean;
}) => {
  const { qsResults } = useDocuments();
  const { document, isProfile } = props;

  const {
    actions: { switchDocumentActiveQueryStatement },
  } = useDocuments();

  if (!document) {
    return null;
  }

  if (!document.execution) {
    return null;
  }

  const activeQueryStatementIndex =
    document.execution.activeQueryStatementIndex;

  const visibleQueryStatements = document.execution.queryStatements.filter(
    qs => {
      const qsResult = qsResults[qs.id];

      return isQueryStatementResolved(qs) && (qsResult?.data || qs.error);
    }
  );

  const lastExplainQueryIndex =
    document.execution.queryStatements.findLastIndex(
      queryStatement =>
        VISIBLE_QUERY_STATEMENTS_STATUSES.includes(queryStatement.status) &&
        hasExplain(queryStatement)
    );

  const activeIndex = hasExplain(
    document.execution.queryStatements[activeQueryStatementIndex]
  )
    ? activeQueryStatementIndex
    : lastExplainQueryIndex;

  const activeQueryStatement = document.execution.queryStatements[activeIndex];

  const isHidden = (statement: QueryStatement) => !hasExplain(statement);

  const handleSetActiveTab = (index: number) => {
    switchDocumentActiveQueryStatement({
      documentId: document.id,
      queryStatementIndex: index,
      userSelectedActiveQueryStatementIndexTimestamp: Date.now(),
      activeOutputTab: DocumentOutputTab.Explain,
    });
  };

  return (
    <div className={styles.root}>
      {!!visibleQueryStatements.length && (
        <OutputSubPanel
          body={
            <TabsBar
              queryStatements={visibleQueryStatements}
              activeQueryStatementIndex={activeIndex}
              setActiveTab={handleSetActiveTab}
              isHidden={isHidden}
            />
          }
          controls={
            activeQueryStatement && (
              <ResultsControls
                queryStatement={activeQueryStatement}
                document={document}
                isProfile={isProfile}
              />
            )
          }
        />
      )}
      <div className={styles.body}>
        {visibleQueryStatements.map((queryStatement, index) => {
          const isActive = index === activeIndex;
          if (isActive) {
            return (
              <div
                key={queryStatement.id}
                className={cn(styles.resultsContainer, {
                  [styles.active]: isActive,
                })}
              >
                <ExplainStatement
                  document={document}
                  queryStatement={queryStatement}
                  isProfile={isProfile}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
