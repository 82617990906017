import React from "react";
import { useTranslation } from "react-i18next";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";

import { CLEAR_ALL, SELECT_ALL } from "./constant";

export const SelectItems = (props: {
  items?: React.ReactElement[];
  withoutSystemItems?: React.ReactElement;
}) => {
  const { items = [], withoutSystemItems = [] } = props;
  return (
    <ContextMenu>
      <ContextMenuItemsGroup
        isGroup
        maxHeight={400}
      >
        {items.map(item => {
          if (item.props.onClick) {
            return React.cloneElement(item, {
              ...item.props,
              onClick: () => {
                item.props.onClick(withoutSystemItems);
              },
            });
          }
          return item;
        })}
      </ContextMenuItemsGroup>
    </ContextMenu>
  );
};

export const SelectItemsWithBulkActions = (props: {
  items?: React.ReactElement[];
  withoutSystemItems?: React.ReactElement[];
  toAssign: string[];
  onChange: (privileges: string[]) => void;
}) => {
  const { toAssign, items = [], withoutSystemItems = [], onChange } = props;
  const { t } = useTranslation();
  return (
    <ContextMenu>
      <ContextMenuItemsGroup
        isGroup
        maxHeight={400}
      >
        {toAssign.length === 0 && (
          <ContextMenuItem
            value={SELECT_ALL}
            checkedIconPlaceholder={true}
            checkedOptions={{ indeterminate: true }}
            text={t("roles_wizard.privileges.select_all")}
            testId="select-all-privileges"
            bulkItem
            direction="left"
            skipFilter
            key="all"
            onClick={() => {
              const privileges = withoutSystemItems.map(
                element => element.props.value
              );
              onChange(privileges);
            }}
          />
        )}
        {toAssign.length !== 0 && (
          <ContextMenuItem
            value={CLEAR_ALL}
            checked={true}
            checkedIconPlaceholder={true}
            checkedOptions={{ indeterminate: true }}
            testId="clear-all-privileges"
            text={t("roles_wizard.privileges.clear_all")}
            direction="left"
            skipFilter
            key="all"
            onClick={() => {
              const privileges = withoutSystemItems.map(
                element => element.props.value
              );
              const filteredPrivileges = toAssign.filter(
                privilege => !privileges.includes(privilege)
              );
              onChange(filteredPrivileges);
            }}
          />
        )}
        <ContextMenuDivider key="divider" />
        {items}
      </ContextMenuItemsGroup>
    </ContextMenu>
  );
};
