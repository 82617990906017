import styles from "./TooltipMessage.module.scss";

interface TooltipMessageProps {
  title: string;
  description?: string;
  testId?: string;
}

const TooltipMessage = (props: TooltipMessageProps) => {
  const { title, description, testId } = props;

  return (
    <div
      className={styles.root}
      data-testid={testId ?? "tooltip-message"}
    >
      <div className={styles.title}>{title}</div>
      {!!description && <div className={styles.description}>{description}</div>}
    </div>
  );
};

export default TooltipMessage;
