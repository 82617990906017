import moment from "moment";

import { ColumnType } from "./types";

export const getIdentity = (columns: ColumnType[], orderBy: string | null) => {
  const orderColumn = columns.find(column => column.id === orderBy);
  return (row: Record<string, unknown>) => {
    const element = orderColumn?.orderIdentity
      ? orderColumn.orderIdentity(row)
      : row[orderBy as string];

    if (typeof element === "string") {
      const date = moment(element, moment.ISO_8601, true);
      if (date.isValid()) {
        return date;
      }
      return element.toLocaleLowerCase();
    }
    return element;
  };
};
