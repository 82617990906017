import { systemEngineEnvironment } from "services/environment/systemEngine";

export type Table = {
  tableName: string;
  tableType: string;
  tableOwner: string;
  created: string;
  lastAltered: string;
};

export const getDatabaseTables = async (
  database: string,
  options?: {
    byOwnerUsername?: string;
  }
) => {
  let byOwnerWhere = "";

  if (options?.byOwnerUsername) {
    byOwnerWhere = `AND tb.table_owner = '${options.byOwnerUsername}'`;
  }

  const result = await systemEngineEnvironment.execute<Table>(
    `select tb.table_name, tb.table_type, tb.table_owner, tb.created, tb.last_altered from information_schema.tables tb where tb.table_type IN ('BASE TABLE', 'FACT', 'DIMENSION') ${byOwnerWhere}`,
    { database }
  );
  const [response] = result;
  return response.rows;
};
