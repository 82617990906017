import { Brand } from "ts-brand";

export type HexColor = Brand<string, "HexColor">;

export const createHexColor = (
  color: string,
  fallback = "#ffffff"
): HexColor => {
  const hexColor = color.toLowerCase().replace(/^#/, "");

  // Validate the hex color format
  if (!/^([0-9a-f]{3}|[0-9a-f]{6})$/.test(hexColor)) {
    return fallback as HexColor;
  }

  return `#${hexColor}` as HexColor;
};

const interpolateColors = (
  color1: HexColor,
  color2: HexColor,
  steps: number
): HexColor[] => {
  const hexToRgb = (hexColor: HexColor): [number, number, number] => {
    let hex = hexColor.toLowerCase().replace(/^#/, "");

    if (hex.length === 3)
      hex = hex
        .split("")
        .map(c => c + c)
        .join(""); // Convert shorthand hex to full
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return [r, g, b];
  };

  const rgbToHex = ([r, g, b]: [number, number, number]): string =>
    // eslint-disable-next-line no-bitwise
    `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

  // Easing function: ease-in-out (non-linear)
  const easeInOut = (t: number): number => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  const [r1, g1, b1] = hexToRgb(color1);
  const [r2, g2, b2] = hexToRgb(color2);
  const gradient: string[] = [];

  // First, interpolate from color1 to color2 with non-linear easing
  for (let i = 0; i <= steps; i++) {
    const t = easeInOut(i / steps); // Apply easing function
    const r = Math.round(r1 + (r2 - r1) * t);
    const g = Math.round(g1 + (g2 - g1) * t);
    const b = Math.round(b1 + (b2 - b1) * t);
    gradient.push(rgbToHex([r, g, b]));
  }

  // Then, interpolate from color2 back to color1 with non-linear easing
  for (let i = 0; i <= steps; i++) {
    const t = easeInOut(i / steps); // Apply easing function
    const r = Math.round(r2 + (r1 - r2) * t);
    const g = Math.round(g2 + (g1 - g2) * t);
    const b = Math.round(b2 + (b1 - b2) * t);
    gradient.push(rgbToHex([r, g, b]));
  }

  return gradient as HexColor[];
};

export const getColorsFromRange = (() => {
  const cache = new Map<string, HexColor[]>(); // Cache for storing computed values

  return (
    startHexColor: HexColor,
    endHexColor: HexColor,
    stepsPerDirection: number
  ): HexColor[] => {
    const cacheKey = `${startHexColor}-${endHexColor}-${stepsPerDirection}`;

    if (cache.has(cacheKey)) {
      return cache.get(cacheKey)!; // Return cached result
    }

    // Compute new color range
    const colors = interpolateColors(
      startHexColor,
      endHexColor,
      stepsPerDirection
    );

    cache.set(cacheKey, colors); // Store result in cache

    return colors;
  };
})();
