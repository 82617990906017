import { Suspense, useMemo } from "react";

import { useUsers } from "services/users/useUsers";

import TransferOwnershipModalBulk from "components/AccountOwnership/TransferOwnershipModalBulk/TransferOwnershipModalBulk";
import { MAP_TO_OWNERSHIP_RECORD } from "components/AccountOwnership/constants";
import {
  OwnershipObjectType,
  OwnershipRecord,
} from "components/AccountOwnership/types";
import useOwnershipRecords from "components/AccountOwnership/useOwnershipRecords";

interface Props {
  userName: string;
  onClose: () => void;
  onTransferDone: () => void;
  isRunningDeleteUserMutation: boolean;
  deleteFlow?: boolean;
}

const Fallback = (props: {
  onClose: () => void;
  userName?: string;
  deleteFlow?: boolean;
}) => (
  <TransferOwnershipModalBulk
    onClose={props.onClose}
    isLoadingObjects={true}
    onTransferDone={() => {}}
    isRunningDeleteUserMutation={false}
    objects={[]}
    users={[]}
    deleteFlow={props.deleteFlow}
    userName={props.userName}
  />
);

const Inner = (props: Props) => {
  const {
    userName,
    onClose,
    onTransferDone,
    deleteFlow,
    isRunningDeleteUserMutation,
  } = props;

  const users = useUsers();
  const {
    listedDatabases,
    engines,
    userRoles,
    databasesTables,
    databaseViews,
    ownedUsers,
  } = useOwnershipRecords({ userName });

  const objects = useMemo(() => {
    const ownershipObjects: OwnershipRecord[] = [];

    listedDatabases.forEach(database => {
      ownershipObjects.push(
        MAP_TO_OWNERSHIP_RECORD[OwnershipObjectType.database](database)
      );
    });

    engines?.data?.forEach(engine => {
      ownershipObjects.push(
        MAP_TO_OWNERSHIP_RECORD[OwnershipObjectType.engine](engine)
      );
    });

    userRoles.forEach(role => {
      ownershipObjects.push(
        MAP_TO_OWNERSHIP_RECORD[OwnershipObjectType.role](role)
      );
    });

    ownedUsers.forEach(ownedUser => {
      ownershipObjects.push(
        MAP_TO_OWNERSHIP_RECORD[OwnershipObjectType.user](ownedUser)
      );
    });

    databasesTables.objects.forEach(({ table, databaseName }) => {
      ownershipObjects.push(
        MAP_TO_OWNERSHIP_RECORD[OwnershipObjectType.table](table, databaseName)
      );
    });

    databaseViews.objects.forEach(({ view, databaseName }) => {
      ownershipObjects.push(
        MAP_TO_OWNERSHIP_RECORD[OwnershipObjectType.view](view, databaseName)
      );
    });

    return ownershipObjects;
  }, [
    databasesTables.objects,
    databaseViews.objects,
    listedDatabases,
    engines,
    userRoles,
    ownedUsers,
  ]);

  if (databasesTables.isLoading || databaseViews.isLoading) {
    return (
      <Fallback
        onClose={onClose}
        deleteFlow={deleteFlow}
        userName={userName}
      />
    );
  }

  return (
    <TransferOwnershipModalBulk
      onClose={onClose}
      isLoadingObjects={false}
      isRunningDeleteUserMutation={isRunningDeleteUserMutation}
      onTransferDone={onTransferDone}
      objects={objects}
      users={users}
      userName={userName}
      deleteFlow={deleteFlow}
    />
  );
};

const TransferOwnershipBulk = (props: Props) => {
  const {
    userName,
    onClose,
    onTransferDone,
    deleteFlow,
    isRunningDeleteUserMutation,
  } = props;

  return (
    <Suspense
      fallback={
        <Fallback
          onClose={onClose}
          userName={userName}
          deleteFlow={deleteFlow}
        />
      }
    >
      <Inner
        onTransferDone={onTransferDone}
        isRunningDeleteUserMutation={isRunningDeleteUserMutation}
        userName={userName}
        onClose={onClose}
        deleteFlow={deleteFlow}
      />
    </Suspense>
  );
};

export default TransferOwnershipBulk;
