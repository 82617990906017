import { useTranslation } from "react-i18next";

import IndexesIcon from "assets/icons/workspace/Index.svg?react";
import PrimaryIndexIcon from "assets/icons/workspace/PrimaryIndex.svg?react";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";

import { useMenu } from "components/ActionMenu/useMenu";
import DefinitionCodePreview from "components/DefinitionCodePreview/DefinitionCodePreview";
import { RecordLabelWithIcon } from "components/LeftSidebar/RecordLabelWithIcon";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { TreeNodeType } from "components/Tree/types";

import styles from "./styles.module.scss";

type Props = {
  node: TreeNodeType;
};

enum IndexType {
  aggregating = "aggregating",
  primary = "primary",
}

export const IndexLabel = (props: Props) => {
  const { node } = props;
  const { label } = node;
  const menu = useMenu();
  const { t } = useTranslation();
  const { insertTextAtCursorPosition } = useActiveEditorView();

  const { payload } = node;
  const { indexType, indexName, tableName, indexDefinition } = payload;
  const items =
    indexType === IndexType.aggregating
      ? [
          [
            {
              key: "view",
              text: t("workspace.index_menu.view_definition"),
              renderContent: (props: { onContentClose: () => void }) => {
                const code = `CREATE AGGREGATING INDEX ${indexName} ON ${tableName} (
${indexDefinition.replace(/^[[]|[\]]$/g, "")}
)`;

                return (
                  <DefinitionCodePreview
                    code={code}
                    onSecondaryButtonClick={() => {
                      insertTextAtCursorPosition(code);
                      menu.closeMenu();
                      props.onContentClose();
                    }}
                  />
                );
              },
              testId: "index-view-definition-item",
            },
          ],
        ]
      : [];

  return (
    <>
      <RecordLabelWithIcon
        icon={
          indexType === IndexType.primary ? (
            <PrimaryIndexIcon />
          ) : (
            <IndexesIcon />
          )
        }
        label={<span className={styles.databaseLabel}>{label}</span>}
        testId={`index-label-${label}`}
      />
      <Menu
        testId="index-more-icon"
        items={items}
        menu={menu}
      />
    </>
  );
};
