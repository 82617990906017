import { systemEngineEnvironment } from "services/environment/systemEngine";

export const transferViewOwnership = async ({
  databaseName,
  viewName,
  newOwner,
}: {
  databaseName: string;
  viewName: string;
  newOwner: string;
}) => {
  await systemEngineEnvironment.execute(
    `ALTER VIEW "${viewName}" OWNER TO "${newOwner}";`,
    {
      database: databaseName,
    }
  );
};
