import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { useMenu } from "components/ActionMenu/useMenu";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";

import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_DELETE_GROUP,
  ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
  ROLES_WIZARD_SET_RESOURCE_OBJECTS,
  ROLES_WIZARD_SET_REVOKE_PRIVILEGES,
} from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { replaceUsage } from "../utils";
import { GrantPrivilegesSelector } from "./GrantPrivilegesSelector";
import { RevokePrivilegeSelector } from "./RevokePrivilegeSelector";

import styles from "./styles.module.scss";

type Props = {
  index: number;
  resource: string;
};

const privileges = [
  // "CONNECT",
  "CREATE DATABASE",
  "CREATE ENGINE",
  // "CREATE LOCATION",
  "CREATE ROLE",
  // "MANAGE GRANTS",
  // "METER USAGE",
  // "MODIFY ACCOUNT",
  // "MODIFY ANY LOCATION",
  "MODIFY ANY DATABASE",
  "MODIFY ANY ENGINE",
  "MODIFY ANY ROLE",
  "MONITOR ANY USAGE",
  "OPERATE ANY ENGINE",
  "USAGE ANY DATABASE",
  "USAGE ANY ENGINE",
];

export const AccountGroup = (props: Props) => {
  const { index, resource } = props;
  const { t } = useTranslation();
  const menu = useMenu();
  const { getAccount } = useCurrentAccount();
  const currentAccount = getAccount();

  const { privilegesState, privilegesDispatch } = usePrivilegesState();

  const groupState = privilegesState.account[index];
  const { objects } = groupState;

  const dependencies = useRef({
    index,
    privilegesDispatch,
    resource,
    objects,
    accountName: currentAccount.accountName,
  });

  useEffect(() => {
    const { index, privilegesDispatch, resource, objects, accountName } =
      dependencies.current;
    if (!objects.length) {
      privilegesDispatch({
        type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
        resource,
        objects: [accountName],
        index,
      });
    }
  }, [dependencies]);

  const items = [
    [
      {
        key: "add_group",
        text: t("roles_wizard.privileges.menu.add"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_EMPTY,
            resource,
          });
        },
        testId: "roles-wizard-add-account-group",
      },
    ],
    ...(privilegesState.account.length > 1
      ? [
          [
            {
              key: "delete",
              text: t("roles_wizard.privileges.menu.delete"),
              action: () => {
                privilegesDispatch({
                  type: ROLES_WIZARD_DELETE_GROUP,
                  resource,
                  index,
                });
              },
              testId: "roles-wizard-delete-account-group",
            },
          ],
        ]
      : []),
  ];

  const onGrantPrivileges = (items: string[]) => {
    privilegesDispatch({
      type: ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
      resource,
      privileges: items,
      index,
    });
  };

  const onRevokePrivileges = (items: string[]) => {
    privilegesDispatch({
      type: ROLES_WIZARD_SET_REVOKE_PRIVILEGES,
      resource,
      privileges: items,
      index,
    });
  };

  const privilegesOptions = privileges.map(privilege => {
    return {
      value: privilege,
      text: replaceUsage(privilege),
    };
  });

  return (
    <div className={styles.group}>
      <div className={styles.group__inner}>
        <div className={styles.objects}>
          <div className={styles.object}>{currentAccount.accountName}</div>
        </div>
      </div>
      <GrantPrivilegesSelector
        onChange={onGrantPrivileges}
        privileges={privilegesOptions}
        resource={resource}
        index={index}
      />

      <RevokePrivilegeSelector
        onChange={onRevokePrivileges}
        privileges={privilegesOptions}
        resource={resource}
        index={index}
      />
      <Menu
        items={items}
        menu={menu}
        testId={`roles-wizard-${resource}-${index}-menu`}
        alwaysVisible
      />
    </div>
  );
};
