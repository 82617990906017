import { systemEngineEnvironment } from "services/environment/systemEngine";

export type View = {
  tableName: string;
  viewDefinition: string;
  viewOwner: string;
  created: string;
  lastAltered: string;
};
export const getViews = async (
  database: string,
  options?: {
    byOwnerUsername?: string;
  }
) => {
  let byOwnerWhere = "";

  if (options?.byOwnerUsername) {
    byOwnerWhere = `AND view_owner = '${options.byOwnerUsername}'`;
  }

  const result = await systemEngineEnvironment.execute<View>(
    `SELECT table_name, view_definition, view_owner, created, last_altered FROM information_schema.views WHERE table_schema = 'public' ${byOwnerWhere} order by table_name`,
    { database }
  );

  const [response] = result;
  return response.rows;
};
