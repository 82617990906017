import {
  QueryStatement,
  QueryStatementStatus,
} from "pages/DevelopWorkspace/workspace.types";

export const isQueryStatementActive = (
  queryStatement: QueryStatement
): boolean => {
  return [
    QueryStatementStatus.pending,
    QueryStatementStatus.running,
    QueryStatementStatus.suspended,
  ].includes(queryStatement.status);
};

export const isQueryStatementDispatched = (
  queryStatement: QueryStatement
): boolean => {
  return [
    QueryStatementStatus.running,
    QueryStatementStatus.suspended,
  ].includes(queryStatement.status);
};

export const isQueryStatementResolved = (
  queryStatement: QueryStatement
): boolean => {
  return [QueryStatementStatus.success, QueryStatementStatus.error].includes(
    queryStatement.status
  );
};

export const queryStatementHasError = (queryStatement: QueryStatement) => {
  return (
    queryStatement.error || queryStatement.status === QueryStatementStatus.error
  );
};

export const queryStatementHasErrorToDisplay = (
  queryStatement: QueryStatement
) => {
  return (
    queryStatement.error && queryStatement.status === QueryStatementStatus.error
  );
};

export const isQueryStatementCompleted = (queryStatement: QueryStatement) => {
  return [
    QueryStatementStatus.cancelled,
    QueryStatementStatus.error,
    QueryStatementStatus.success,
  ].includes(queryStatement.status);
};
