import { systemEngineEnvironment } from "services/environment/systemEngine";

export const transferUserOwnership = async ({
  userName,
  newOwner,
}: {
  userName: string;
  newOwner: string;
}) => {
  await systemEngineEnvironment.execute(
    `ALTER USER "${userName}" OWNER TO "${newOwner}";`
  );
};
