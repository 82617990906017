import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { deleteDatabase as deleteDatabaseMutation } from "services/databases/deleteDatabase";
import { ReactQueryKeysAccount } from "services/queryKeys";

import {
  ConfirmationModal,
  ObjectType,
} from "components/ConfirmationModal/ConfirmationModal";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

export const useDeleteDatabase = (options?: {
  onDeleted?: (databaseName: string) => void;
}) => {
  const queryClient = useQueryClient();
  const [deleteDatabaseName, setDeleteDatabaseName] = useState<string | null>(
    null
  );
  const { t } = useTranslation();

  const { putStatusMessage } = useStatusMessageQueue();

  const handleClose = () => {
    setDeleteDatabaseName(null);
  };

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: deleteDatabaseMutation,
    onSuccess: async (_, databaseName) => {
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.databasesNames],
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.users],
      });
      if (databaseName) {
        options?.onDeleted?.(databaseName);
      }
      putStatusMessage({
        type: StatusMessageType.Success,
        message: t("workspace.delete_database.success", {
          databaseName,
        }),
      });

      handleClose();
    },
    onError: async (error: Error) => {
      putStatusMessage({
        type: StatusMessageType.Error,
        message: error.message,
        options: {
          id: "start",
          insertToPosition: StatusMessagePosition.Top,
          autoRemove: true,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.databasesNames],
      });
    },
  });

  const runMutation = (databaseName: string) => {
    if (databaseName && !isLoading) {
      mutate(databaseName);
    }
  };

  const handleConfirm = () => {
    if (deleteDatabaseName && !isLoading) {
      runMutation(deleteDatabaseName);
    }
  };

  const deleteDatabaseMarkup = deleteDatabaseName ? (
    <ConfirmationModal
      title={t("workspace.delete_database.title")}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isLoading={isLoading}
      confirmButtonOptions={{ text: t("workspace.delete_database.confirm") }}
      message={
        <Trans
          i18nKey="workspace.delete_database.message"
          components={{ br: <br /> }}
        />
      }
      objectName={deleteDatabaseName}
      objectType={ObjectType.Database}
    />
  ) : null;

  const deleteDatabase = (name: string) => {
    setDeleteDatabaseName(name);
  };

  return {
    deleteDatabaseMarkup,
    deleteDatabase,
  };
};
