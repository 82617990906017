import { DialogProps, Dialog as MUIDialog } from "@mui/material";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const Dialog = (
  props: React.PropsWithChildren<DialogProps & { size?: string }>
) => {
  const { children, size, ...rest } = props;
  return (
    <MUIDialog
      scroll="body"
      classes={{
        paper: classNames(styles.paper, size && styles[size]),
      }}
      {...rest}
      open
    >
      {children}
    </MUIDialog>
  );
};
