import { Dialog } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import DatabaseIcon from "assets/icons/objectsIcons/Database.svg?react";
import EngineIcon from "assets/icons/objectsIcons/Engine.svg?react";
import RoleIcon from "assets/icons/objectsIcons/Role.svg?react";
import TableIcon from "assets/icons/objectsIcons/Table.svg?react";
import ViewIcon from "assets/icons/objectsIcons/View.svg?react";

import Button, { ButtonTemplate } from "components/Button/Button";
import { CrossIcon, UserIcon } from "components/Icons";

import styles from "./styles.module.scss";

export enum ObjectType {
  Engine = "Engine",
  Database = "Database",
  Table = "Table",
  Role = "Role",
  User = "User",
  View = "View",
}

type Props = {
  onClose: () => void;

  title: React.ReactNode;
  onConfirm: () => void;

  isLoading?: boolean;
  confirmButtonOptions: {
    text: string;
  };
  cancelButtonOptions?: {
    text?: string;
    isDisabled?: boolean;
  };
  message: string | React.ReactNode;
  objectName?: string;
  objectType?: ObjectType;
};

const iconMap = {
  [ObjectType.Engine]: EngineIcon,
  [ObjectType.Database]: DatabaseIcon,
  [ObjectType.Table]: TableIcon,
  [ObjectType.Role]: RoleIcon,
  [ObjectType.User]: UserIcon,
  [ObjectType.View]: ViewIcon,
};

export const ConfirmationModal = (props: React.PropsWithChildren<Props>) => {
  const {
    onClose,
    onConfirm,
    title,
    isLoading,
    confirmButtonOptions,
    cancelButtonOptions,
    message,
    objectName,
    objectType,
  } = props;
  const { t } = useTranslation();

  const handleCloseModal = useCallback(
    (event: { preventDefault: () => void }) => {
      event.preventDefault(); // prevent system Esc call

      onClose();
      return false;
    },
    [onClose]
  );

  const Icon = objectType ? iconMap[objectType] : null;
  return (
    <Dialog
      fullWidth
      classes={{ paper: styles.dialog }}
      onClose={handleCloseModal}
      scroll="body"
      onKeyDown={event => {
        if (event.key === "Enter") {
          event.preventDefault();
          event.stopPropagation();
          onConfirm();
        }
      }}
      open
    >
      <form
        className={styles.wrapper}
        onSubmit={event => {
          event.preventDefault();
          onConfirm();
        }}
        data-testid={"confirmation-modal-" + objectType}
      >
        <div className={styles.header}>
          <div className={styles.header__inner}>
            <div className={styles.header__title}>{title}</div>
          </div>
          <div
            onClick={onClose}
            className={styles.close}
            data-testid="close-confirmation-cross"
          >
            <CrossIcon />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.message}>{message}</div>
          {objectType && objectName && (
            <div className={styles.object}>
              {Icon && <Icon />}
              <span className={styles.object__name}>{objectName}</span>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <Button
            text={confirmButtonOptions.text}
            isLoading={isLoading}
            disabled={isLoading}
            className={styles.submitBtn}
            template={ButtonTemplate.Danger}
            dataTestid="confirmation-button"
            id="submit"
            type="submit"
          />
          <Button
            text={
              cancelButtonOptions?.text || t("workspace.create_engine.cancel")
            }
            template={ButtonTemplate.Ghost}
            onClick={onClose}
            dataTestid="secondary-button"
            disabled={isLoading || cancelButtonOptions?.isDisabled}
          />
        </div>
      </form>
    </Dialog>
  );
};
