import classNames from "classnames";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { Formatter } from "utils/helpers/Format";

import { FileTree } from "services/fileObjects/getFilesByPath";

import ArrowDown from "assets/icons/v2/ArrowDown.svg?react";
import ArrowUp from "assets/icons/v2/ArrowUp.svg?react";

import Spinner from "components/Spinner";
import Tooltip from "components/Tooltip";
import { highlightText } from "components/helpers";

import { SUPPORTED_EXTENSIONS } from "../Steps/constants";
import { SupportedExtensions } from "../types";
import { ColumnAction } from "./ColumnAction";
import { sortItems } from "./helpers";
import { SortValues } from "./types";

import styles from "./styles.module.scss";

type Props = {
  items: FileTree[];
  selectedItems: Set<string>;
  loadingItems: Set<string>;
  handleOpenItem: (path: string) => void;
  toggleSelectItem: (path: string) => void;
  searchQuery?: string;
};

type HeaderProps = {
  onHeaderCellClick?: (newSort: SortValues) => void;
  sort?: SortValues;
  scrollbarWidth: number;
};

/* Wrappers around the table component may have non-integer heights (e.g., 32.12px),
   leading to the appearance of an extra scrollbar. To mitigate this, we adjust the height,
   even though this results in the container being slightly smaller than the actual height.
   This is a workaround to address the issue. */
const HEIGHT_ADJUSTMENT = 1;

const Header = (props: HeaderProps) => {
  const { onHeaderCellClick, sort, scrollbarWidth } = props;
  const { t } = useTranslation();
  return (
    <div
      className={styles.header}
      style={{
        paddingRight: `${scrollbarWidth}px`,
      }}
    >
      <div />
      <div
        className={classNames(styles.column__name, {
          [styles.clickable]: !!onHeaderCellClick,
        })}
        onClick={() => {
          if (onHeaderCellClick) {
            if (sort === SortValues.nameAsc) {
              return onHeaderCellClick(SortValues.nameDesc);
            }
            onHeaderCellClick(SortValues.nameAsc);
          }
        }}
      >
        <div
          className={classNames(styles.header_content, {
            [styles.sortActive]:
              sort === SortValues.nameAsc || sort === SortValues.nameDesc,
          })}
        >
          <div className={styles.text}>{t("fileTable.header.name")}</div>
          <div className={styles.sortIcon}>
            {sort === SortValues.nameDesc ? <ArrowDown /> : <ArrowUp />}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.column__type, {
          [styles.clickable]: !!onHeaderCellClick,
        })}
        onClick={() => {
          if (onHeaderCellClick) {
            if (sort === SortValues.typeAsc) {
              return onHeaderCellClick(SortValues.typeDesc);
            }
            onHeaderCellClick(SortValues.typeAsc);
          }
        }}
      >
        <div
          className={classNames(styles.header_content, {
            [styles.sortActive]:
              sort === SortValues.typeAsc || sort === SortValues.typeDesc,
          })}
        >
          <div className={styles.text}>{t("fileTable.header.type")}</div>
          <div className={styles.sortIcon}>
            {sort === SortValues.typeDesc ? <ArrowDown /> : <ArrowUp />}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.column__size, {
          [styles.clickable]: !!onHeaderCellClick,
        })}
        onClick={() => {
          if (onHeaderCellClick) {
            if (sort === SortValues.sizeAsc) {
              return onHeaderCellClick(SortValues.sizeDesc);
            }
            onHeaderCellClick(SortValues.sizeAsc);
          }
        }}
      >
        <div
          className={classNames(styles.header_content, {
            [styles.sortActive]:
              sort === SortValues.sizeAsc || sort === SortValues.sizeDesc,
          })}
        >
          <div className={styles.text}>{t("fileTable.header.size")}</div>
          <div className={styles.sortIcon}>
            {sort === SortValues.sizeDesc ? <ArrowDown /> : <ArrowUp />}
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.column__modified, {
          [styles.clickable]: !!onHeaderCellClick,
        })}
        onClick={() => {
          if (onHeaderCellClick) {
            if (sort === SortValues.modifiedAsc) {
              return onHeaderCellClick(SortValues.modifiedDesc);
            }
            onHeaderCellClick(SortValues.modifiedAsc);
          }
        }}
      >
        <div
          className={classNames(styles.header_content, {
            [styles.sortActive]:
              sort === SortValues.modifiedAsc ||
              sort === SortValues.modifiedDesc,
          })}
        >
          <div className={styles.text}>{t("fileTable.header.modified")}</div>
          <div className={styles.sortIcon}>
            {sort === SortValues.modifiedDesc ? <ArrowDown /> : <ArrowUp />}
          </div>
        </div>
      </div>
    </div>
  );
};

const itemSize = () => 32;

export const FileTable = (props: Props) => {
  const {
    items,
    selectedItems,
    loadingItems,
    handleOpenItem,
    toggleSelectItem,
    searchQuery,
  } = props;
  const { t } = useTranslation();

  const [sort, setSort] = useState<SortValues>(SortValues.noSort);

  const innerRef = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  // Measure scrollbar width dynamically to adjust the header cells
  // It's relevant for Chrome where scrollbar narrows the content
  // eslint-disable-next-line react-hooks/exhaustive-deps -- it's fine to call it on every render
  useEffect(() => {
    if (innerRef.current && outerRef.current) {
      if (outerRef.current.offsetWidth - outerRef.current.clientWidth > 0) {
        setScrollbarWidth(
          outerRef.current.offsetWidth - outerRef.current.clientWidth
        );
      } else {
        setScrollbarWidth(0);
      }
    }
  });

  const sortedItems = sort
    ? sortItems(items, sort).sort((a, b) => {
        // parent folder should be on top
        if (a.type === "parent" && b.type !== "parent") {
          return -1;
        }

        if (a.type !== "parent" && b.type === "parent") {
          return 1;
        }

        return 0;
      })
    : items;

  const onHeaderSort = useCallback(
    (newSort: SortValues) => {
      setSort(newSort);
    },
    [setSort]
  );

  const handleClick = (item: FileTree) => () => {
    const { type, objectName, objectType } = item;
    const isLoading = loadingItems.has(objectName);
    if (isLoading) {
      return;
    }
    if (objectType === "folder") {
      handleOpenItem(item.objectName);
    }
    if (type === "parent") {
      handleOpenItem(item.objectName);
    }

    if (item.objectType === "file") {
      toggleSelectItem(objectName);
    }
  };

  const renderNode = ({ index, style }: ListChildComponentProps) => {
    const item = sortedItems[index];
    const {
      url,
      name,
      type,
      objectName,
      objectType,
      objectBytes,
      lastModified,
    } = item;
    const isSelected = selectedItems.has(objectName);
    const isLoading = loadingItems.has(objectName);
    const isFolder = objectType === "folder" || type === "parent";

    const modifiedFormated = isFolder
      ? null
      : String(moment(lastModified).utc().format("YYYY/MM/DD"));

    const sizeFormated = isFolder
      ? null
      : Formatter.bytesFormatter(objectBytes!).formattedString;

    const isSupported =
      item.objectType === "file" &&
      SUPPORTED_EXTENSIONS.includes(item.extension as SupportedExtensions);

    return (
      <div
        className={classNames(styles.row, {
          [styles.folder]: isFolder,
        })}
        style={style}
        key={objectName}
        onClick={handleClick(item)}
        data-testid={`file-row-${index}`}
      >
        <ColumnAction
          item={item}
          index={index}
          toggleSelectItem={toggleSelectItem}
          isSelected={isSelected}
        />
        <Tooltip
          arrow={false}
          enterDelay={500}
          enterNextDelay={500}
          placement="top"
          classes={{
            tooltip: classNames(styles.tooltipWrapper, styles.forced),
          }}
          disableHoverListener={isFolder || !isSupported}
          title={
            <div className={styles.tooltip}>
              <div className={styles.tooltip__item}>
                <span className={styles.tooltip__key}>Name:</span>{" "}
                <span className={styles.tooltip__value}>{name}</span>
              </div>
              <div className={styles.tooltip__item}>
                <span className={styles.tooltip__key}>Path:</span>{" "}
                <span className={styles.tooltip__value}>{url}</span>
              </div>
              <div className={styles.tooltip__item}>
                <span className={styles.tooltip__key}>Size:</span>{" "}
                <span className={styles.tooltip__value}>{sizeFormated}</span>
              </div>
            </div>
          }
        >
          <div
            className={styles.column__name}
            data-testid={`file-row-name-tooltip-${index}`}
          >
            <div
              className={classNames(styles.object_name, {
                [styles.is_loading]: isLoading,
              })}
            >
              {highlightText({
                searchWord: searchQuery,
                textToHighlight: name,
              })}
            </div>
            {isLoading && (
              <span className={styles.column__action}>
                <div className={styles.expandWrapper}>
                  <div className={styles.loading}>
                    <Spinner size={12} />
                  </div>
                </div>
              </span>
            )}
          </div>
        </Tooltip>
        <div className={styles.column__type}>{objectType}</div>
        <div className={styles.column__size}>{sizeFormated}</div>
        <div className={styles.column__modified}>{modifiedFormated}</div>
      </div>
    );
  };

  if (!sortedItems.length) {
    return (
      <div
        className={styles.wrapper}
        data-testid="wizard-file-table"
      >
        <Header scrollbarWidth={scrollbarWidth} />
        <div className={styles.emptyState}>
          <div className={styles.emptyState__header}>
            {t("wizard.select_data.empty_table_title")}
          </div>
          <div className={styles.emptyState__body}>
            {t("wizard.select_data.empty_table_subtitle")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles.wrapper}
      data-testid="wizard-file-table"
    >
      <Header
        sort={sort}
        onHeaderCellClick={onHeaderSort}
        scrollbarWidth={scrollbarWidth}
      />
      <div className={styles.list}>
        <AutoSizer
          style={{ height: "auto" }}
          disableWidth
        >
          {({ height = 300 }) => {
            return (
              <VariableSizeList
                style={{
                  overflowY: "scroll",
                }}
                height={height - HEIGHT_ADJUSTMENT}
                innerRef={innerRef}
                outerRef={outerRef}
                width="auto"
                itemSize={itemSize}
                itemCount={sortedItems.length}
                itemData={sortedItems}
              >
                {renderNode}
              </VariableSizeList>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};
