import { useTranslation } from "react-i18next";

import HelmetWrapper from "components/DocumentTitle/HelmetWrapper";

type Props = {
  children?: React.ReactNode;
};

export const getFormattedDocumentTitle = (sections: string[]) => {
  return sections.join(" | ");
};

export function withTitle<T>(
  key: string[] | string,
  Component: (props?: any) => React.ReactElement | JSX.Element | null
) {
  return (props: T & Props) => {
    const { t } = useTranslation();

    const getPageTitle = () => {
      if (Array.isArray(key)) {
        return key.map(k => t(k));
      }

      return [t(key)];
    };

    return (
      <>
        <HelmetWrapper>
          <title>{getFormattedDocumentTitle(getPageTitle())}</title>
        </HelmetWrapper>
        <Component {...props} />
      </>
    );
  };
}

withTitle.displayName = "ComponentWithTitle";
