import { systemEngineEnvironment } from "services/environment/systemEngine";

export const transferDatabaseOwnership = async ({
  databaseName,
  newOwner,
}: {
  databaseName: string;
  newOwner: string;
}) => {
  await systemEngineEnvironment.execute(
    `ALTER DATABASE "${databaseName}" OWNER TO "${newOwner}";`
  );
};
