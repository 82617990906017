import { useEffect, useMemo, useRef } from "react";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import useActiveDocument from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useActiveDocument";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { useShortcutsOverlay } from "pages/DevelopWorkspace/contexts/ShortcutsOverlayContext/ShortcutsOverlayContext";
import { SHORTCUTS } from "pages/DevelopWorkspace/services/shortcuts/constants";
import { getModifiersFromEvent } from "pages/DevelopWorkspace/services/shortcuts/helpers/getModifiersFromEvent";
import { shouldTriggerShortcut } from "pages/DevelopWorkspace/services/shortcuts/helpers/shouldTriggerShortcut";
import {
  Modifier,
  ShortcutAction,
  ShortcutHandlerType,
} from "pages/DevelopWorkspace/services/shortcuts/types";

import { useLayoutToggles } from "components/LayoutToggles/context";

const handledShortcuts = Object.values(SHORTCUTS).filter(shortcut => {
  return shortcut.handlerType === ShortcutHandlerType.workspace;
});

const useShortcutsHandler = ({
  handleDeleteDocument,
  handleDeleteAllDocuments,
  confirmationModalMarkup,
}: {
  handleDeleteDocument: (documentId: string) => void;
  handleDeleteAllDocuments: (excludedDocumentIds: string[]) => void;
  confirmationModalMarkup?: React.ReactNode;
}) => {
  const workspaceDocument = useActiveDocument();
  const {
    actions: { createDocument, setActiveDocument },
    state: { documents },
  } = useDocuments();
  const { showOverlay } = useShortcutsOverlay();

  const { formatText } = useActiveEditorView();
  const { layout, setLayout } = useLayoutToggles();
  const pressedKeysRef = useRef<string[]>([]);

  const activeDocument = useActiveDocument();

  const handlers: {
    [key in ShortcutAction]: () => void;
  } = useMemo(() => {
    return {
      [ShortcutAction.ScriptsClose]: () => {
        if (workspaceDocument) {
          handleDeleteDocument(workspaceDocument.id);
        }
      },
      [ShortcutAction.ScriptsCloseAll]: () => {
        handleDeleteAllDocuments([]);
      },
      [ShortcutAction.ScriptsCloseAllOther]: () => {
        if (!activeDocument) return;

        handleDeleteAllDocuments([activeDocument.id]);
      },
      [ShortcutAction.ScriptOpenNew]: () => {
        createDocument({
          context: workspaceDocument
            ? {
                database: { name: workspaceDocument.context.database.name },
                engine: { name: workspaceDocument.context.engine.name },
              }
            : undefined,
        });
      },
      [ShortcutAction.EditorRunSelection]: () => {
        // handled in editor
      },
      [ShortcutAction.EditorRunAll]: () => {
        // handled in editor
      },
      [ShortcutAction.ScriptToggleComment]: () => {
        // handled in editor
      },
      [ShortcutAction.ShortcutsOverlayOpen]: () => {
        showOverlay();
      },
      [ShortcutAction.ScriptFormat]: () => {
        formatText();
      },
      [ShortcutAction.ScriptNext]: () => {
        if (!activeDocument) return;

        const currentIndex = documents.findIndex(
          document => document.id === activeDocument.id
        );
        const nextDocument = documents[currentIndex + 1];

        if (nextDocument) {
          setActiveDocument(nextDocument.id);
        }
      },
      [ShortcutAction.ScriptPrevious]: () => {
        if (!activeDocument) return;

        const currentIndex = documents.findIndex(
          document => document.id === activeDocument.id
        );
        const previousDocument = documents[currentIndex - 1];

        if (previousDocument) {
          setActiveDocument(previousDocument.id);
        }
      },
      [ShortcutAction.CloseOverlay]: () => {},
      [ShortcutAction.ToggleResultsSection]: () => {
        if (!activeDocument || !activeDocument.execution) {
          return;
        }

        setLayout({
          ...layout,
          results: {
            ...layout.results,
            expanded: !layout.results.expanded,
          },
        });
      },
    };
  }, [
    workspaceDocument,
    createDocument,
    handleDeleteDocument,
    handleDeleteAllDocuments,
    showOverlay,
    activeDocument,
    documents,
    setActiveDocument,
    formatText,
    layout,
    setLayout,
  ]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      event.stopPropagation();

      // ignore if confirmation modal is open
      if (event.repeat || confirmationModalMarkup) {
        return;
      }

      const code = event.code;

      const modifiers = getModifiersFromEvent(event);

      if (modifiers.length) {
        for (const shortcut of Object.values(handledShortcuts)) {
          const shouldTrigger = shouldTriggerShortcut(
            shortcut,
            [code],
            modifiers as [Modifier, ...Modifier[]]
          );

          if (shouldTrigger) {
            event.preventDefault();
            const handler = handlers[shortcut.action];
            handler();

            return;
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [workspaceDocument, handlers, confirmationModalMarkup]);

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      event.stopPropagation();

      pressedKeysRef.current = [];
    };

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [workspaceDocument, handleDeleteDocument]);
};

export default useShortcutsHandler;
