import { TooltipProps } from "@mui/material";
import React from "react";

import InfoIcon from "assets/icons/Info.svg?react";

import Tooltip from "components/Tooltip";

import styles from "../../ContextSelector.module.scss";

type PropsTooltip = {
  title: React.ReactNode;
  placement?: TooltipProps["placement"];
};

export const EngineItemInfo = ({ title, placement }: PropsTooltip) => {
  return (
    <Tooltip
      onClick={e => e.stopPropagation()}
      title={title}
      placement={placement ?? "right"}
      arrow
      classes={{ tooltip: styles.tooltip }}
    >
      <div data-testid="engine-item-info-icon">
        <InfoIcon className={styles.infoIcon} />
      </div>
    </Tooltip>
  );
};
