import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import StatusCancelledIcon from "assets/icons/queryStatementStatus/StatusCancelledIcon.svg?react";
import StatusErrorIcon from "assets/icons/queryStatementStatus/StatusErrorIcon.svg?react";
import StatusRunningIcon from "assets/icons/queryStatementStatus/StatusRunningIcon.svg?react";
import StatusSuccessIcon from "assets/icons/queryStatementStatus/StatusSuccessIcon.svg?react";
import StatusSuspendedIcon from "assets/icons/queryStatementStatus/StatusSuspendedIcon.svg?react";
import StatusUnknownIcon from "assets/icons/queryStatementStatus/StatusUnknownIcon.svg?react";

import { UserFacingQueryStatementStatus } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/types";
import { QueryStatementStatus } from "pages/DevelopWorkspace/workspace.types";

import styles from "./StatusCell.module.scss";

const ErrorState = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="query-error-state"
      className={cn(styles.state, styles.queryError)}
    >
      <div className={styles.iconContainer}>
        <StatusErrorIcon className={styles.icon} />
      </div>

      <span className={styles.text}>{t("queries.error")}</span>
    </div>
  );
});

const CancelState = React.memo(() => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="query-cancel-state"
      className={cn(styles.state, styles.queryCancel)}
    >
      <div className={styles.iconContainer}>
        <StatusCancelledIcon className={styles.icon} />
      </div>
      <span className={styles.text}>{t("queries.canceled")}</span>
    </div>
  );
});

const RunningState = React.memo(() => {
  const { t } = useTranslation();
  const statusText = t("queries.running");
  return (
    <div
      data-testid="query-running-state"
      className={cn(styles.state, styles.queryRunning)}
    >
      <div className={styles.iconContainer}>
        <StatusRunningIcon className={styles.icon} />
      </div>
      <span className={styles.text}>{statusText}</span>
    </div>
  );
});

const SuspendedState = React.memo(() => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="query-suspended-state"
      className={cn(styles.state, styles.querySuspended)}
    >
      <div className={styles.iconContainer}>
        <StatusSuspendedIcon className={styles.icon} />
      </div>
      <span className={styles.text}>{t("queries.suspended")}</span>
    </div>
  );
});

const SuccessState = () => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="query-success-state"
      className={cn(styles.state, styles.querySuccess)}
    >
      <div className={styles.iconContainer}>
        <StatusSuccessIcon className={styles.icon} />
      </div>
      <span className={styles.text}>{t("queries.success")}</span>
    </div>
  );
};

const UnknownState = () => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="query-unknown-state"
      className={cn(styles.state, styles.queryUnknown)}
    >
      <div className={styles.iconContainer}>
        <StatusUnknownIcon className={styles.icon} />
      </div>
      <span className={styles.text}>{t("queries.unknown")}</span>
    </div>
  );
};

interface ResponseColumnInnerProps {
  status: UserFacingQueryStatementStatus;
}

const StatusCell = (props: ResponseColumnInnerProps) => {
  const { status } = props;

  switch (status) {
    case QueryStatementStatus.success:
      return <SuccessState />;
    case QueryStatementStatus.running:
      return <RunningState />;
    case QueryStatementStatus.suspended:
      return <SuspendedState />;
    case QueryStatementStatus.error:
      return <ErrorState />;
    case QueryStatementStatus.cancelled:
      return <CancelState />;

    case QueryStatementStatus.unknown:
      return <UnknownState />;
    case QueryStatementStatus.restoring:
      return <UnknownState />;
    case QueryStatementStatus.pending:
      return <div />;
    default:
      const _exhaustiveCheck: never = status; // eslint-disable-line no-case-declarations,@typescript-eslint/no-unused-vars
  }
};

export default StatusCell;
