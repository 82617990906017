import { WorkspaceEngineType } from "services/engines/engine.types";

export const nodeTypeOptions = [
  {
    name: "workspace.create_engine.xsmall",
    value: WorkspaceEngineType.XS,
  },
  {
    name: "workspace.create_engine.small",
    value: WorkspaceEngineType.S,
  },
  {
    name: "workspace.create_engine.medium",
    value: WorkspaceEngineType.M,
  },
  {
    name: "workspace.create_engine.large",
    value: WorkspaceEngineType.L,
  },
  {
    name: "workspace.create_engine.xlarge",
    value: WorkspaceEngineType.XL,
  },
];
