import { Dialog } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import Button, { ButtonTemplate } from "components/Button/Button";
import Checkbox from "components/Checkbox";

import Cloud from "./Cloud.svg?react";
import Load from "./Load.svg?react";
import Star from "./Star.svg?react";

import styles from "./WelcomeV2.module.scss";

interface Props {
  onClose: (dontShowAgain: boolean) => void;
  onLoadDataClick: (dontShowAgain: boolean) => void;
}

const WelcomeV2 = (props: Props) => {
  const { onClose, onLoadDataClick } = props;
  const { t } = useTranslation();
  const [dontShowAgain, setDontShowAgain] = React.useState(false);

  const handleClose = () => {
    onClose(dontShowAgain);
  };

  return (
    <Dialog
      open={true}
      classes={{ paper: styles.paper }}
      data-testid="welcome-v2-dialog"
      onClose={handleClose}
    >
      <div className={styles.welcomeBody}>
        <div className={styles.top}>
          <div className={styles.content}>
            <div className={styles.text}>
              <h1
                className={styles.title}
                data-testid="welcome-v2-title"
              >
                {t("onboarding_v2.title")}
              </h1>

              <div className={styles.textContainer}>
                <div className={styles.subTitle}>
                  <div className={styles.description}>
                    {t("onboarding_v2.second_paragraph_header")}
                  </div>
                </div>
              </div>

              <div className={styles.buttons}>
                <div className={styles.action}>
                  <Load />
                  <Button
                    text={t("onboarding_v2.later")}
                    fullWidth={false}
                    dataTestid="welcome-v2-later"
                    template={ButtonTemplate.Ghost}
                    onClick={handleClose}
                  />
                </div>
                <div className={styles.action}>
                  <Cloud />
                  <Button
                    text={t("onboarding_v2.load_data")}
                    fullWidth={false}
                    dataTestid="welcome-v2-load-data"
                    template={ButtonTemplate.Ghost}
                    onClick={() => onLoadDataClick(dontShowAgain)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.testimonial}>
            <div className={styles.author}>
              <div className={styles.author__avatar} />
              <div className={styles.author__info}>
                <div className={styles.author__name}>
                  {t("onboarding_v2.author_name")}
                </div>
                <div className={styles.author__position}>
                  {t("onboarding_v2.author_position")}
                </div>
              </div>
            </div>

            <div className={styles.stars}>
              {new Array(5).fill(0).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Star key={index} />
              ))}
              {t("onboarding_v2.rating")}
            </div>

            <div className={styles.testimonial__title}>
              {t("onboarding_v2.testimonial_title")}
            </div>
            <div className={styles.testimonial__body}>
              {t("onboarding_v2.testimonial_body")}
            </div>
          </div>
        </div>

        <div className={styles.checkboxContainer}>
          <Checkbox
            checked={dontShowAgain}
            testId="welcome-v2-checkbox"
            onChange={() => {
              setDontShowAgain(!dontShowAgain);
            }}
          />
          <div
            className={styles.label}
            data-testid="welcome-v2-checkbox-label"
            onClick={() => {
              setDontShowAgain(!dontShowAgain);
            }}
          >
            {t("onboarding_v2.dont_show_again")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default WelcomeV2;
