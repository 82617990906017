export type Engine = {
  engineName: string;
  region: string;
  spec: string;
  scale: string;
  status: string;
  attachedTo: string;
  version: string;
  url: string;
  warmup: string;
  autoStop: string;
  engineType: string;
  provisioning: string;
};

export enum WorkspaceEngineType {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
  None = "",
}

export enum EngineFamily {
  Storage = "STORAGE_OPTIMIZED",
  SO = "SO",
  Compute = "COMPUTE_OPTIMIZED",
  CO = "CO",
}

export enum WorkspaceEngineStatus {
  RUNNING = "RUNNING",
  STOPPED = "STOPPED",
  STOPPING = "STOPPING",
  STARTING = "STARTING",
  RESIZING = "RESIZING",
  DRAINING = "DRAINING",
}

export type WorkspaceEngine = {
  engineName: string;
  status: WorkspaceEngineStatus;
  type: WorkspaceEngineType;
  family?: EngineFamily;
  clusters: number;
  nodes: number;
  description: string;
  autoStop: string;
  url: string;
  autoStart: boolean;
  initiallyStopped: boolean;
  lastStarted: string;
  engineOwner: string;
  created: string;
  lastAltered: string;
};
