import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Suspense, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useEnableOwnership } from "featureFlags/hooks/useEnableOwnership";
import { ReactQueryKeysAccount } from "services/queryKeys";
import { deleteUser } from "services/users/deleteUser";

import TransferOwnershipBulk from "components/AccountOwnership/TransferOwnershipModalBulk/TransferOwnershipBulk";
import useOwnershipRecords, {
  hasOwnershipRecords,
} from "components/AccountOwnership/useOwnershipRecords";
import {
  ConfirmationModal,
  ObjectType,
} from "components/ConfirmationModal/ConfirmationModal";
import {
  StatusMessagePosition,
  StatusMessageType,
} from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";

const RecordsLoader = (props: {
  userName: string;
  onLoaded: (objectsFound: boolean) => void;
}) => {
  const { userName, onLoaded } = props;

  const { t } = useTranslation();

  const data = useOwnershipRecords({ userName });

  const isPending =
    data.databasesTables.isLoading || data.databaseViews.isLoading;

  useEffect(() => {
    if (!isPending) {
      onLoaded(hasOwnershipRecords(data));
    }
  }, [isPending, data, onLoaded]);

  return (
    <ConfirmationModal
      title={t("workspace.delete_user.title")}
      onClose={() => {}}
      onConfirm={() => {}}
      confirmButtonOptions={{
        text: t("workspace.delete_user.deleting"),
      }}
      cancelButtonOptions={{
        isDisabled: true,
      }}
      message={
        <Trans
          i18nKey="workspace.delete_user.message"
          components={{ br: <br /> }}
        />
      }
      objectName={userName}
      objectType={ObjectType.User}
    />
  );
};

enum OwnershipRecordsState {
  Loading = "Loading",
  ObjectsFound = "ObjectsFound",
}

export const useDeleteUser = (options?: {
  onSuccess?: (userName: string) => void;
}) => {
  const queryClient = useQueryClient();
  const enableOwnership = useEnableOwnership();
  const [deleteUserName, setDeleteUserName] = useState<string | null>(null);
  const { putStatusMessage } = useStatusMessageQueue();
  const { t } = useTranslation();

  const [ownershipRecordsState, setOwnershipRecordsState] =
    useState<OwnershipRecordsState | null>(null);

  const handleClose = () => {
    setDeleteUserName(null);
    setOwnershipRecordsState(null);
  };

  const { mutate, isPending: isRunningMutation } = useMutation({
    mutationFn: async (userName: string) => {
      await deleteUser(userName);
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.users],
      });
    },
    onSuccess: async (_, userName) => {
      handleClose();

      if (options?.onSuccess) {
        options?.onSuccess(userName);
      }

      putStatusMessage({
        type: StatusMessageType.Success,
        message: t("workspace.delete_user.success"),
      });
    },
    onError: async (error: Error) => {
      putStatusMessage({
        type: StatusMessageType.Error,
        message: error.message,
        options: {
          id: "start",
          insertToPosition: StatusMessagePosition.Top,
          autoRemove: true,
        },
      });
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.users],
      });
      handleClose();
    },
  });

  const runDeleteUserMutation = (userName: string) => {
    mutate(userName);
  };

  const handleConfirm = () => {
    if (isRunningMutation || ownershipRecordsState || !deleteUserName) {
      return;
    }

    if (enableOwnership) {
      setOwnershipRecordsState(OwnershipRecordsState.Loading);
    } else {
      runDeleteUserMutation(deleteUserName);
    }
  };

  const getMarkup = () => {
    if (!deleteUserName) {
      return null;
    }

    if (ownershipRecordsState === OwnershipRecordsState.ObjectsFound) {
      return (
        <TransferOwnershipBulk
          userName={deleteUserName}
          isRunningDeleteUserMutation={isRunningMutation}
          onTransferDone={() => {
            runDeleteUserMutation(deleteUserName);
            setOwnershipRecordsState(null);
          }}
          onClose={() => {
            setOwnershipRecordsState(null);
          }}
          deleteFlow={true}
        />
      );
    }

    if (ownershipRecordsState === OwnershipRecordsState.Loading) {
      return (
        <Suspense
          fallback={
            <ConfirmationModal
              title={t("workspace.delete_user.title")}
              onClose={() => {}}
              onConfirm={() => {}}
              confirmButtonOptions={{
                text: t("workspace.delete_user.deleting"),
              }}
              cancelButtonOptions={{
                isDisabled: true,
              }}
              message={
                <Trans
                  i18nKey="workspace.delete_user.message"
                  components={{ br: <br /> }}
                />
              }
              objectName={deleteUserName}
              objectType={ObjectType.User}
            />
          }
        >
          <RecordsLoader
            userName={deleteUserName}
            onLoaded={objectsFound => {
              if (objectsFound) {
                setOwnershipRecordsState(OwnershipRecordsState.ObjectsFound);
                return;
              }

              setOwnershipRecordsState(null);
              runDeleteUserMutation(deleteUserName);
            }}
          />
        </Suspense>
      );
    }

    return (
      <ConfirmationModal
        title={t("workspace.delete_user.title")}
        onClose={() => {
          if (isRunningMutation) {
            return;
          }

          handleClose();
        }}
        onConfirm={() => {
          if (isRunningMutation) {
            return;
          }

          handleConfirm();
        }}
        cancelButtonOptions={{
          isDisabled: isRunningMutation,
        }}
        confirmButtonOptions={{
          text: isRunningMutation
            ? t("workspace.delete_user.deleting")
            : t("workspace.delete_user.confirm"),
        }}
        message={
          <Trans
            i18nKey="workspace.delete_user.message"
            components={{ br: <br /> }}
          />
        }
        objectName={deleteUserName}
        objectType={ObjectType.User}
      />
    );
  };

  const openDeleteUser = (name: string) => {
    setDeleteUserName(name);
  };

  return {
    deleteUserMarkup: getMarkup(),
    openDeleteUser,
    runDeleteUserMutation,
    isDeleteUserMutationRunning: isRunningMutation,
  };
};
