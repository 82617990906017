import { v4 as uuidv4 } from "uuid";

import { EngineMonitoringTimeWindow } from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/constants";
import {
  QueryMetrics,
  UtilMetrics,
} from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/EngineMonitoring/types";
import {
  DEFAULT_EXECUTION_CONTEXT,
  DEFAULT_LAYOUT,
  INITIAL_STATE,
} from "pages/DevelopWorkspace/contexts/DocumentsContext/DocumentsContext";
import { DEFAULT_ENGINE_MONITORING_TIME_WINDOW } from "pages/DevelopWorkspace/workspace.constants";
import {
  DocumentEngineMonitoring,
  DocumentLayout,
  DocumentOutputTab,
  DocumentsState,
  Execution,
  ExecutionContext,
  ExecutionType,
  QueryStatement,
  QueryStatementStatus,
  ScriptType,
  Statistics,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

const normalizeContext = (rawContext: any): ExecutionContext => {
  const engineNameFromRawV1 =
    rawContext?.engineName && typeof rawContext.engineName === "string"
      ? rawContext.engineName
      : "";
  const engineNameFromRawV2 =
    rawContext?.engine?.name && typeof rawContext.engine.name === "string"
      ? rawContext.engine.name
      : "";
  const dbNameFromRawV1 =
    rawContext?.databaseName && typeof rawContext.databaseName === "string"
      ? rawContext.databaseName
      : "";
  const dbNameFromRawV2 =
    rawContext?.database?.name && typeof rawContext.database.name === "string"
      ? rawContext.database.name
      : "";
  const context: ExecutionContext = {
    database: {
      name:
        dbNameFromRawV2 ||
        dbNameFromRawV1 ||
        DEFAULT_EXECUTION_CONTEXT.database.name,
    },
    engine: {
      name:
        engineNameFromRawV2 ||
        engineNameFromRawV1 ||
        DEFAULT_EXECUTION_CONTEXT.engine.name,
    },

    settings:
      rawContext?.settings && typeof rawContext.settings === "object"
        ? rawContext.settings
        : DEFAULT_EXECUTION_CONTEXT.settings,
  };

  return context;
};

const normalizeLayout = (rawLayout: any): DocumentLayout => {
  if (typeof rawLayout !== "object" || rawLayout === null) {
    return DEFAULT_LAYOUT;
  }

  const layout: DocumentLayout = {
    editorHeightPx:
      rawLayout?.editorHeightPx && typeof rawLayout.editorHeightPx === "number"
        ? rawLayout.editorHeightPx
        : DEFAULT_LAYOUT.editorHeightPx,
    activeOutputTab:
      rawLayout?.activeOutputTab &&
      rawLayout?.activeOutputTab in DocumentOutputTab
        ? rawLayout.activeOutputTab
        : DEFAULT_LAYOUT.activeOutputTab,
  };

  return layout;
};

const returnNumber = (input: any) => {
  return input && typeof input === "number" ? input : 0;
};

const normalizeStatistics = (rawStatistics: any): Statistics => {
  const statistics: Statistics = {
    executionTimeSec: returnNumber(rawStatistics?.executionTimeSec),
    rowsRead: returnNumber(rawStatistics?.rowsRead),
    bytesRead: returnNumber(rawStatistics?.bytesRead),
    scannedBytesCache: returnNumber(rawStatistics?.scannedBytesCache),
    scannedBytesStorage: returnNumber(rawStatistics?.scannedBytesStorage),
    timeBeforeExecution: returnNumber(rawStatistics?.timeBeforeExecution),
    timeToExecute: returnNumber(rawStatistics?.timeToExecute),
  };

  return statistics;
};

const normalizeExecution = (rawExecution: any): Execution | null => {
  if (typeof rawExecution !== "object" || rawExecution === null) {
    return null;
  }

  const activeQueryStatementIndex =
    rawExecution?.activeQueryStatementIndex &&
    typeof rawExecution.activeQueryStatementIndex === "number"
      ? rawExecution.activeQueryStatementIndex
      : 0;

  const userSelectedActiveQueryStatementIndexTimestamp =
    rawExecution?.userSelectedActiveQueryStatementIndexTimestamp &&
    typeof rawExecution.userSelectedActiveQueryStatementIndexTimestamp ===
      "number"
      ? rawExecution.userSelectedActiveQueryStatementIndexTimestamp
      : 0;

  const queryStatements: QueryStatement[] =
    rawExecution?.queryStatements && Array.isArray(rawExecution.queryStatements)
      ? rawExecution.queryStatements.map(
          (rawQueryStatement: any): QueryStatement => {
            const queryStatement: QueryStatement = {
              id:
                rawQueryStatement?.id &&
                typeof rawQueryStatement.id === "string"
                  ? (rawQueryStatement.id as string)
                  : "query_statement." + uuidv4(),
              content:
                rawQueryStatement?.content &&
                typeof rawQueryStatement.content === "string"
                  ? (rawQueryStatement.content as string)
                  : "",
              responseStatusCode:
                rawQueryStatement?.responseStatusCode &&
                typeof rawQueryStatement.responseStatusCode === "number"
                  ? (rawQueryStatement.responseStatusCode as number)
                  : null,
              status:
                rawQueryStatement?.status &&
                rawQueryStatement.status in QueryStatementStatus
                  ? (rawQueryStatement.status as QueryStatementStatus)
                  : QueryStatementStatus.cancelled,
              resultRows: rawQueryStatement?.resultRows,
              error: null,
              statistics: normalizeStatistics(rawQueryStatement?.statistics),
              sourceDocLineNumber:
                rawQueryStatement?.sourceDocLineNumber &&
                typeof rawQueryStatement.sourceDocLineNumber === "number"
                  ? (rawQueryStatement.sourceDocLineNumber as number)
                  : 0,
            };
            if (rawQueryStatement.serverQueryId) {
              Object.assign(queryStatement, {
                serverQueryId: rawQueryStatement.serverQueryId,
              });
            }

            return queryStatement;
          }
        )
      : [];

  const execution: Execution = {
    executionType: ExecutionType.Query,
    activeQueryStatementIndex,
    userSelectedActiveQueryStatementIndexTimestamp,
    queryStatements,
    documentExecutionError: null, // always reset the value
  };

  return execution;
};

const normalizeEngineMonitoring = (
  rawEngineMonitoring: any
): DocumentEngineMonitoring => {
  if (
    rawEngineMonitoring &&
    typeof rawEngineMonitoring === "object" &&
    rawEngineMonitoring?.timeWindow &&
    Object.values(EngineMonitoringTimeWindow).includes(
      rawEngineMonitoring.timeWindow
    ) &&
    rawEngineMonitoring?.utilChart?.highlightedKeys &&
    rawEngineMonitoring?.utilChart?.highlightedKeys.every((k: any) =>
      Object.values(UtilMetrics).includes(k)
    ) &&
    rawEngineMonitoring?.queriesChart?.highlightedKeys &&
    rawEngineMonitoring?.queriesChart?.highlightedKeys.every((k: any) =>
      Object.values(QueryMetrics).includes(k)
    )
  ) {
    return {
      timeWindow: rawEngineMonitoring.timeWindow as EngineMonitoringTimeWindow,
      utilChart: {
        highlightedKeys: rawEngineMonitoring.utilChart
          .highlightedKeys as UtilMetrics[],
      },
      queriesChart: {
        highlightedKeys: rawEngineMonitoring.queriesChart
          .highlightedKeys as QueryMetrics[],
      },
    };
  }

  return {
    timeWindow: DEFAULT_ENGINE_MONITORING_TIME_WINDOW,
    utilChart: {
      highlightedKeys: [],
    },
    queriesChart: {
      highlightedKeys: [],
    },
  };
};

const normalizeDocument = (rawDocument: any): WorkspaceDocument => {
  const document: WorkspaceDocument = {
    context: normalizeContext(rawDocument?.context),
    id:
      rawDocument?.id && typeof rawDocument?.id === "string"
        ? (rawDocument.id as string)
        : "",
    script: {
      type: rawDocument?.script?.type || "",
      id: rawDocument?.script?.id || "",
    },
    selection: (rawDocument?.selection &&
    typeof rawDocument?.selection?.[0] === "number" &&
    typeof rawDocument?.selection?.[1] === "number"
      ? [rawDocument.selection[0], rawDocument.selection[1]]
      : [0, 0]) as [number, number],
    layout: normalizeLayout(rawDocument?.layout),
    execution: normalizeExecution(rawDocument?.execution),
    engineMonitoring: normalizeEngineMonitoring(rawDocument?.engineMonitoring),
    createdAt:
      rawDocument?.createdAt && typeof rawDocument.createdAt === "number"
        ? (rawDocument.createdAt as number)
        : Date.now(),
  };

  return document;
};

const isDocumentValid = (document: any): boolean => {
  if (
    document?.id &&
    typeof document.id === "string" &&
    document?.script?.id &&
    typeof document.script.id === "string" &&
    document?.script?.type &&
    (document.script.type === ScriptType.local ||
      document.script.type === ScriptType.remote)
  ) {
    return true;
  }

  return false;
};

const normalizeDocumentsRawState = (rawState: any): DocumentsState => {
  if (typeof rawState !== "object" || rawState === null) {
    return INITIAL_STATE;
  }

  return {
    documents:
      rawState?.documents && Array.isArray(rawState?.documents)
        ? rawState.documents.filter(isDocumentValid).map(normalizeDocument)
        : [],
    activeDocumentId: rawState?.activeDocumentId || null,
  };
};

export default normalizeDocumentsRawState;
