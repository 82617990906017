import { SqlRbacAction } from "services/rbac/action";

import {
  OwnershipObjectType,
  OwnershipRecord,
} from "components/AccountOwnership/types";
import { useAccessManager } from "components/App/accessManager";

export const canModifyOwnershipObject = (
  object: OwnershipRecord,
  accessManager: ReturnType<typeof useAccessManager>
) => {
  const { isAllowedTo } = accessManager;

  // TODO probably this should be changed to explicit check
  // for tables and views
  if (
    object.parentName &&
    (object.type === OwnershipObjectType.table ||
      object.type === OwnershipObjectType.view)
  ) {
    return isAllowedTo(
      OwnershipObjectType.database,
      object.parentName,
      SqlRbacAction.MODIFY,
      {
        owner: object.owner,
      }
    );
  }

  // for databases, engines, roles
  return isAllowedTo(object.type, object.name, SqlRbacAction.MODIFY, {
    owner: object.owner,
  });
};
